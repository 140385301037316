import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectCaseListView from "../../../views/project/case/list";
import {
  addProjectCase,
  deleteProjectCase,
  duplicateProjectCase,
  editProjectCase,
  getProjectCaseDetails,
  getProjectCaseList,
} from "../../../apis/project/case";

export default function ProjectCaseListPage() {
  return (
    <ProjectPagesLayout>
      <ProjectCaseListView
        getProjectCaseList={getProjectCaseList}
        addProjectCase={addProjectCase}
        editProjectCase={editProjectCase}
        deleteProjectCase={deleteProjectCase}
        getProjectCaseDetails={getProjectCaseDetails}
        duplicateProjectCase={duplicateProjectCase}
      />
    </ProjectPagesLayout>
  );
}
