import { makeStyles } from "tss-react/mui";
import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    input: {
      "& textarea": {
        padding: 0,
        paddingBottom: theme.spacing(1),
      },
    },
    tableInput: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      fontSize: 14,
    },
    tableInputField: {
      "& input": {
        fontSize: 14,
        padding: theme.spacing(1.5),
      },
    },
    toggleButton: {
      background: selectedTheme.color.aboveSurface,
      " .Mui-selected": {
        color: `${selectedTheme.color.secondary} !important`,
        background: selectedTheme.color.surface,
        fontWeight: "bold",
      },
    },
  };
});
