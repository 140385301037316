import React from "react";
import Box from "@mui/material/Box";
import _ from "lodash";

import Modal from "../modal";
import TextInput from "../text-input";
import MultiAutocompleteField from "../multi-autocomplete-field";
import AutocompleteField from "../autocomplete-field";
import { ADD_CASE_COMPARISON_FORM_DEFAULT_STATE } from "../../constants";
import {
  IDealComparison,
  SetStateAction,
  ISelectOption,
  IAddDealCaseComparisonForm,
  IAddDealCaseComparisonFormErrors,
} from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IAddDealCaseComparisonForm;
  setForm: SetStateAction<IAddDealCaseComparisonForm>;
  formErrors?: IAddDealCaseComparisonFormErrors;
  setFormErrors: SetStateAction<IAddDealCaseComparisonFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (
    form: IAddDealCaseComparisonForm,
  ) => Promise<IDealComparison | undefined>;
  cases: ISelectOption[];
}

export default function AddDealCaseComparisonFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  cases,
}: IProps): JSX.Element {
  const handleChangeDealComparisonInfo = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | ISelectOption[] | null,
    name: string,
  ) => {
    setFormErrors((prevErrors) => {
      return {
        ...prevErrors,
        [name]: "",
      };
    });
    if (Array.isArray(value)) {
      const v = value?.map((o: ISelectOption) => o.value);
      setForm((prev) => {
        return {
          ...prev,
          [name]: v as Array<string>,
        };
      });
    }
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnAddDealComparison = async () => {
    const deal = await onConfirm(form);
    deal && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm(ADD_CASE_COMPARISON_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="sm"
      fullWidth
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAddDealComparison}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          required
          label="Deal Case Comparison Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleChangeDealComparisonInfo}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
        />

        <AutocompleteField
          label="Select Benchmark Case"
          name="base_case_deal"
          onChange={handleSingleAutoCompleteChange}
          options={cases}
          value={String(form.base_case_deal)}
          helperText={formErrors?.base_case_deal}
          onFocus={() => {
            clearSelectErrorOnFocus("base_case_deal");
          }}
          disabled={loading}
        />

        <MultiAutocompleteField
          label="Select Cases"
          name="deal_uuids"
          onChange={handleAutoCompleteChange}
          options={cases}
          value={form.deal_uuids}
          helperText={formErrors?.deal_uuids}
          onFocus={() => clearSelectErrorOnFocus("deal_uuids")}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
