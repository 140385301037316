import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import CheckboxInput from "../checkbox-input";
import ScenarioSensitivitySelector from "../scenario-sensitivity-selector";
import {
  SetStateAction,
  IAddDealCaseForm,
  IAddDealCaseFormErrors,
  IDealCase,
  ISelectOption,
  IProjectCase,
} from "../../interfaces";
import {
  DEAL_CASE_PROJECT_TAX_CREDIT_TYPE_OPTIONS,
  DEAL_CASE_TYPE,
  DEAL_CASE_TYPE_OPTIONS,
  DEAL_STRUCTURE_TYPE_OPTIONS,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS,
} from "../../constants";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IAddDealCaseForm;
  setForm: SetStateAction<IAddDealCaseForm>;
  formErrors?: IAddDealCaseFormErrors;
  setFormErrors: SetStateAction<IAddDealCaseFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (form: IAddDealCaseForm) => Promise<IDealCase | undefined>;
  projects: string[];
  scenarioOptions: ISelectOption[][];
  projectCases: IProjectCase[][];
  dealCasesOptions: ISelectOption[];
}

export default function AddCaseFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  projects,
  scenarioOptions,
  projectCases,
  dealCasesOptions,
}: IProps): JSX.Element {
  const styles = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnAdd = async () => {
    if (form.type === "SENS") {
      form.is_synced_with_base_case = true;
    }
    const dealCase = await onConfirm(form);
    dealCase && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm({
      name: "",
      type: "",
      description: "",
      is_synced_with_base_case: false,
      deal_structure: "",
      deal_tax_credit_structure: "",
      project_tax_credit_type: "",
      project_cases: [],
      parent_scenario_uuid: null,
    });
    setFormErrors({});
  };

  const onCaseTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    value: keyof typeof DEAL_CASE_TYPE,
  ) => {
    setFormErrors((prev) => ({
      ...prev,
      type: "",
    }));
    if (value !== null) {
      setForm((prev) => {
        return {
          ...prev,
          type: value,
          project_cases: [], // Reset project cases when type changes
        };
      });
    }
  };

  const sensitivityOptions = projectCases.map((projectCase) =>
    projectCase.map((scenario) => ({
      label: scenario.name,
      value: scenario.uuid,
    })),
  );

  const getScenarioForSensitivity = (
    projectCaseIndex: number,
    scenarioOrSensitivityValue: string,
  ) => {
    const projectCase = projectCases[projectCaseIndex];
    const sensitivity = projectCase.find(
      (c) => c.uuid === scenarioOrSensitivityValue && c.type === "SENS",
    );
    if (sensitivity) {
      // If sensitivity found, return its parent scenario's child_project_uuid
      const scenario = projectCase.find(
        (c) => c.uuid === sensitivity.parent_scenario_uuid,
      );
      return scenario?.uuid || "";
    }
    // If no sensitivity found, check if value is a scenario
    const scenario = projectCase.find(
      (c) => c.uuid === scenarioOrSensitivityValue && c.type === "SCEN",
    );
    return scenario?.uuid || "";
  };

  const getSensitivityValue = (
    projectCaseIndex: number,
    sensitivityValue: string,
  ) => {
    const projectCase = projectCases[projectCaseIndex];
    // First check if value is a sensitivity
    const sensitivity = projectCase.find(
      (c) => c.uuid === sensitivityValue && c.type === "SENS",
    );
    if (sensitivity) {
      return sensitivity.uuid;
    }
    // If not a sensitivity, return empty string even if it's a scenario
    return "";
  };

  const handleScenarioSensitivityChange = (
    e: SelectChangeEvent<unknown>,
    i: number,
  ) => {
    setForm((prev) => {
      const newProjectCases = [...prev.project_cases];
      newProjectCases[i] = e.target.value as string;
      return {
        ...prev,
        project_cases: newProjectCases,
      };
    });
  };

  const handleDealScenarioChange = (e: SelectChangeEvent<unknown>) => {
    setForm((prev) => {
      return {
        ...prev,
        parent_scenario_uuid: e.target.value as string,
      };
    });
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAdd}
      resetForm={handleResetForm}
      classes={{
        paper: "!min-w-[70%]",
      }}
    >
      <>
        {form.type === "SENS" && (
          <Alert severity="info" classes={{ root: "mb-4" }}>
            The sensitivity will be synchronized with whatever scenario case it
            is associated with
          </Alert>
        )}
        <Box className="grid md:grid-cols-2 gap-4">
          <Box>
            <TextInput
              required
              label="Case Name"
              name="name"
              value={form.name}
              onFocus={clearErrorOnFocus}
              onChange={handleChange}
              error={Boolean(formErrors?.name)}
              helperText={formErrors?.name}
              disabled={loading}
              autoFocus
            />
            <div className="mb-4">
              <span className="font-bold mr-2">Type</span>
              <ToggleButtonGroup
                exclusive
                value={form.type}
                onChange={onCaseTypeChange}
                size="small"
                classes={{ root: styles.classes.toggleButton }}
              >
                <ToggleButton
                  value="SCEN"
                  size="small"
                  sx={{ textTransform: "none" }}
                >
                  Scenario
                </ToggleButton>
                <ToggleButton
                  value="SENS"
                  size="small"
                  sx={{ textTransform: "none" }}
                >
                  Sensitivity
                </ToggleButton>
              </ToggleButtonGroup>
              <FormHelperText error={Boolean(formErrors?.type)}>
                {formErrors?.type}
              </FormHelperText>
            </div>
            {form.type === "SENS" && (
              <SelectInput
                label="Deal Scenario"
                selected={form.parent_scenario_uuid || ""}
                items={dealCasesOptions}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("parent_scenario_uuid")
                }
                onChange={(e) => handleDealScenarioChange(e)} // TODO
                error={Boolean(formErrors?.parent_scenario_uuid)}
                helperText={formErrors?.parent_scenario_uuid}
                disabled={loading}
              />
            )}
            <TextInput
              classes={{ root: styles.classes.input }}
              multiline
              rows={4}
              label="Description"
              name="description"
              value={form.description}
              onFocus={clearErrorOnFocus}
              onChange={handleChange}
              error={Boolean(formErrors?.description)}
              helperText={formErrors?.description}
              disabled={loading}
            />
            {form.type !== "SENS" && (
              <CheckboxInput
                label="Keep any unaltered case data in sync with the deal and its associated projects."
                name="is_synced_with_base_case"
                checked={form.is_synced_with_base_case}
                onChange={handleCheckboxChange}
                disabled={loading}
              />
            )}
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Case Structure
            </Divider>
            <SelectInput
              label="Deal Structure"
              selected={form.deal_structure}
              items={DEAL_STRUCTURE_TYPE_OPTIONS}
              onFocus={() => clearSelectErrorOnFocus("structure")}
              onChange={(e) => handleSelectInputChange(e, "deal_structure")}
              error={Boolean(formErrors?.deal_structure)}
              helperText={formErrors?.deal_structure}
              disabled={loading}
            />
            <SelectInput
              label="Deal Tax Credit Structure"
              selected={form.deal_tax_credit_structure}
              items={DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS}
              onFocus={() => clearSelectErrorOnFocus("tax_credit_structure")}
              onChange={(e) =>
                handleSelectInputChange(e, "deal_tax_credit_structure")
              }
              error={Boolean(formErrors?.deal_tax_credit_structure)}
              helperText={formErrors?.deal_tax_credit_structure}
              disabled={loading}
            />
            <SelectInput
              label="Tax Credit Type for Project"
              selected={form.project_tax_credit_type}
              items={DEAL_CASE_PROJECT_TAX_CREDIT_TYPE_OPTIONS}
              onFocus={() => clearNonTextFieldErrorOnFocus("credit_type")}
              onChange={(e) =>
                handleSelectInputChange(e, "project_tax_credit_type")
              }
              error={Boolean(formErrors?.project_tax_credit_type)}
              helperText={formErrors?.project_tax_credit_type}
              disabled={loading}
            />
          </Box>
          {form.type === "SCEN" && (
            <Box className="border border-slate-500 rounded p-4 flex flex-col gap-4">
              <Typography fontWeight="bold">Project Case Selection</Typography>
              {projects.map((p, i) => {
                return (
                  <div key={p} className="grid grid-cols-3 items-center gap-4">
                    <span>{p}</span>
                    <div className="col-span-2">
                      <SelectInput
                        label="Scenario"
                        selected={form.project_cases[i] || ""}
                        items={scenarioOptions[i]}
                        onFocus={() =>
                          clearNonTextFieldErrorOnFocus("project_cases")
                        }
                        onChange={(e) => handleScenarioSensitivityChange(e, i)}
                        error={Boolean(formErrors?.project_cases)}
                        helperText={formErrors?.project_cases}
                        disabled={loading}
                      />
                    </div>
                  </div>
                );
              })}
            </Box>
          )}
          {form.type === "SENS" && (
            <Box className="border border-slate-500 rounded p-4 flex flex-col gap-4">
              <Typography fontWeight="bold">Project Case Selection</Typography>
              {projects.map((p, i) => {
                const scenarioValue = getScenarioForSensitivity(
                  i,
                  form.project_cases[i] || "",
                );
                const sensitivityValue = getSensitivityValue(
                  i,
                  form.project_cases[i] || "",
                );
                return (
                  <div key={p} className="grid grid-cols-3 items-center gap-4">
                    <span>{p}</span>
                    <div className="col-span-2">
                      <SelectInput
                        label="Sensitivities"
                        selected={form.project_cases[i] || ""}
                        items={sensitivityOptions[i]}
                        onFocus={() =>
                          clearNonTextFieldErrorOnFocus("project_cases")
                        }
                        onChange={(e) => handleScenarioSensitivityChange(e, i)}
                        error={Boolean(formErrors?.project_cases)}
                        helperText={formErrors?.project_cases}
                        disabled={loading}
                      />
                    </div>
                  </div>
                );
              })}
            </Box>
          )}
        </Box>
      </>
    </Modal>
  );
}
