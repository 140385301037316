import React from "react";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import useStyles from "./styles";

interface IProps {
  searchString: string;
  onSearchStringChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearSearchString: () => void;
}

export default function Searchbar({
  searchString,
  onSearchStringChange,
  handleClearSearchString,
  placeholder = "Search...",
  ...inputBaseProps
}: IProps & InputBaseProps): JSX.Element {
  const styles = useStyles();

  return (
    <InputBase
      {...inputBaseProps}
      value={searchString}
      onChange={onSearchStringChange}
      placeholder={placeholder}
      className={styles.classes.input}
      startAdornment={<SearchIcon />}
      endAdornment={
        searchString && (
          <IconButton size="small" onClick={handleClearSearchString}>
            <ClearIcon />
          </IconButton>
        )
      }
    />
  );
}
