import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import ConstructionLoanModal from "../../../../components/construction-loan-form-modal";
import ConditionalProtect from "../../../../components/conditional-protect";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";
import {
  setCurrentDealAction,
  setDeleteModalPropsAction,
} from "../../../../utils/redux/slices";
import {
  IConstructionLoanForm,
  IConstructionLoanFormErrors,
  IConstructionLoanResponse,
} from "../../../../interfaces";
import {
  CONSTRUCTION_DEBT_BASE_RATE_TYPE,
  CONSTRUCTION_LOAN_FORM_DEFAULT_STATE,
  CONSTRUCTION_LOAN_SIZING_TYPE,
  YEAR_FRAC_CONVENTION_TYPE,
} from "../../../../constants";
import {
  addConstructionLoan,
  deleteConstructionLoan,
  getConstructionLoan,
  updateConstructionLoan,
} from "../../../../apis/deal/construction-debt";

export default function ConstructionLoanDebt(): JSX.Element {
  const { dealUuid, caseDealUuid } = useParams();

  const dispatch = useDispatch();
  const { currentDeal } = useAppSelector((s) => s.deal);

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const [constructionLoan, setConstructionLoan] =
    React.useState<IConstructionLoanResponse | null>(null);
  const [addConstructionLoanModalOpen, setAddConstructionLoanModalOpen] =
    React.useState<boolean>(false);
  const [editConstructionLoanModalOpen, setEditConstructionLoanModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IConstructionLoanForm>(
    CONSTRUCTION_LOAN_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getConstructionLoanCallAPI(dealIdToUse).then((response) => {
      if (Array.isArray(response)) {
        setConstructionLoan(response[0]);
      }
    });
  }, [dealIdToUse]);

  const {
    callAPI: getConstructionLoanCallAPI,
    errored: getConstructionLoanErrored,
    loading: loadingGetConstructionLoan,
  } = useAPI((dealUuid: string) => getConstructionLoan(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: addConstructionLoanCallAPI,
    fieldErrors: addConstructionLoanFieldErrors,
    setFieldErrors: setAddConstructionLoanFieldErrors,
    loading: addConstructionLoanLoading,
  } = useAPI<IConstructionLoanResponse, IConstructionLoanFormErrors>(
    (dealUuid: string, form: IConstructionLoanForm) =>
      addConstructionLoan(dealUuid, form),
  );

  const {
    callAPI: updateConstructionLoanCallAPI,
    fieldErrors: updateConstructionLoanFormErrors,
    setFieldErrors: setUpdateConstructionLoanFormErrors,
    loading: updateConstructionLoanLoading,
  } = useAPI<IConstructionLoanResponse, IConstructionLoanFormErrors>(
    (dealUuid: string, loanId: number, form: IConstructionLoanForm) =>
      updateConstructionLoan(dealUuid, loanId, form),
  );

  const handleOnAddConstructionLoan = async (form: IConstructionLoanForm) => {
    const loan = await addConstructionLoanCallAPI(caseDealUuid, form);

    if (loan && currentDeal) {
      dispatch(
        setCurrentDealAction({
          ...currentDeal,
          has_construction_loan: true,
        }),
      );
      setConstructionLoan(loan);
    }

    return loan;
  };

  const handleOnEditConstructionLoan = async (form: IConstructionLoanForm) => {
    const updatedLoan = await updateConstructionLoanCallAPI(
      caseDealUuid,
      constructionLoan?.id,
      form,
    );
    updatedLoan && setConstructionLoan(updatedLoan);

    return updatedLoan;
  };

  const handleOpenAddConstructionLoanModal = async () => {
    setAddConstructionLoanModalOpen(true);
  };

  const handleCloseAddConstructionLoanModal = () => {
    setAddConstructionLoanModalOpen(false);
  };

  const handleOpenEditConstructionLoanModal = async () => {
    const {
      base_rate_type,
      base_rate,
      spread,
      hedge,
      swap_rate,
      swap_credit_spread,
      commitment_fee,
      yearfrac_convention,
      sizing_type,
      upfront_fee,
      percent_of_project_costs,
    } = constructionLoan!;
    setForm({
      base_rate_type,
      base_rate,
      spread,
      hedge,
      swap_rate,
      swap_credit_spread,
      commitment_fee,
      yearfrac_convention,
      sizing_type,
      upfront_fee,
      percent_of_project_costs,
    });
    setEditConstructionLoanModalOpen(true);
  };

  const handleCloseEditConstructionLoanModal = () => {
    setEditConstructionLoanModalOpen(false);
  };

  const { callAPI: deleteConstructionLoanCallAPI } = useAPI(
    (dealUuid: string, loanId: number) =>
      deleteConstructionLoan(dealUuid, loanId),
    { setConfirmModalLoading: true },
  );

  const handleDeleteConstructionLoan = async () => {
    const deleted = await deleteConstructionLoanCallAPI(
      caseDealUuid,
      constructionLoan?.id,
    );

    if (deleted && currentDeal) {
      dispatch(
        setCurrentDealAction({
          ...currentDeal,
          has_construction_loan: false,
        }),
      );
      setConstructionLoan(null);
    }
  };

  const handleOnDelete = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Construction Loan",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteConstructionLoan(),
      }),
    );
  };

  const constructionLoanCardData = React.useMemo(() => {
    if (!constructionLoan) return [];
    return [
      {
        label: "Type",
        value: { text: "Construction" },
      },
      {
        label: "Sizing Type",
        value: {
          text: CONSTRUCTION_LOAN_SIZING_TYPE[
            constructionLoan.sizing_type as keyof typeof CONSTRUCTION_LOAN_SIZING_TYPE
          ],
        },
      },
      ...(constructionLoan.sizing_type === "PPC"
        ? [
            {
              label: "Percentage of Project Costs",
              value: { text: constructionLoan.percent_of_project_costs + "%" },
            },
          ]
        : []),
      {
        label: "Base Rate Type",
        value: {
          text: CONSTRUCTION_DEBT_BASE_RATE_TYPE[
            constructionLoan.base_rate_type
          ],
        },
      },
      ...(constructionLoan.base_rate_type === "FR"
        ? [
            {
              label: "Base Rate",
              value: { text: constructionLoan.base_rate.toString() + "%" },
            },
          ]
        : []),
      {
        label: "Spread",
        value: { text: constructionLoan.spread.toString() + "%" },
      },
      {
        label: "Hedge %",
        value: { text: constructionLoan.hedge.toString() + "%" },
      },
      {
        label: "Swap Rate",
        value: { text: constructionLoan.swap_rate.toString() + "%" },
      },
      {
        label: "Swap Credit Spread",
        value: { text: constructionLoan.swap_credit_spread.toString() + "%" },
      },
      {
        label: "Upfront Fee",
        value: { text: constructionLoan.upfront_fee.toString() + "%" },
      },
      {
        label: "Commitment Fee",
        value: { text: constructionLoan.commitment_fee.toString() + "%" },
      },
      {
        label: "Yearfrac Convention",
        value: {
          text: YEAR_FRAC_CONVENTION_TYPE[constructionLoan.yearfrac_convention],
        },
      },
    ];
  }, [constructionLoan]);

  return (
    <ViewWrapper
      loading={loadingGetConstructionLoan}
      error={getConstructionLoanErrored}
    >
      {!constructionLoan && (
        <Paper sx={{ padding: 1 }}>
          <Typography fontWeight="bold">Construction Loan</Typography>
          <Box className={cn("min-h-96 flex justify-center items-center")}>
            <Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add Construction Loan"
                  onClick={handleOpenAddConstructionLoanModal}
                  btnType="primary"
                  dataPw="add-construction-loan-button"
                />
              </ConditionalProtect>

              {currentDeal?.status === "ARCH" && (
                <Typography variant="body1">This deal is archived!</Typography>
              )}
            </Box>
          </Box>
        </Paper>
      )}

      {constructionLoan && (
        <>
          <DetailsCard
            dataPw="construction-loan-details-card"
            heading="Construction Loan"
            sections={[
              {
                fields: constructionLoanCardData,
              },
            ]}
            onEditClick={handleOpenEditConstructionLoanModal}
            onDeleteClick={handleOnDelete}
            autoHeight
          />
        </>
      )}

      <ConstructionLoanModal
        open={addConstructionLoanModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Add Construction Loan"
        loading={addConstructionLoanLoading}
        onClose={handleCloseAddConstructionLoanModal}
        onConfirm={handleOnAddConstructionLoan}
        setFormErrors={setAddConstructionLoanFieldErrors}
        formErrors={addConstructionLoanFieldErrors}
      />

      <ConstructionLoanModal
        open={editConstructionLoanModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Edit Construction Loan"
        loading={updateConstructionLoanLoading}
        onClose={handleCloseEditConstructionLoanModal}
        onConfirm={handleOnEditConstructionLoan}
        setFormErrors={setUpdateConstructionLoanFormErrors}
        formErrors={updateConstructionLoanFormErrors}
      />
    </ViewWrapper>
  );
}
