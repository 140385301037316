import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CustomChip from "../general/Chip";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";
import { Link, useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import Layout from "../layout";
import ActionButton from "../action-button";
import DownloadModelFormModal from "../download-model-form-modal";
import { getProjectValuation } from "../../apis/project/valuation";
import { useAPI, useAppSelector, useLocalStorage } from "../../utils/hooks";
import {
  DEAL_STATUS,
  PROJECT_TAX_CREDIT_TYPE,
  PERIODICITY,
} from "../../constants";
import {
  downloadProjectModel,
  getProjectDetails,
} from "../../apis/project/base";
import {
  getStatusChipFillColor,
  navigateAndScroll,
  trimString,
} from "../../utils/helpers";
import {
  setCurrentProjectAction,
  setCurrentProjectStatusAction,
  setValuationExistAction,
} from "../../utils/redux/slices";

interface IProps {
  children: JSX.Element;
}

export default function PersonalAccountProjectPagesLayout({
  children,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { organization } = useOrganization();

  const { currentProject } = useAppSelector((s) => s.project);
  const { contentLoaded } = useAppSelector((s) => s.common);

  const styles = useStyles({ contentLoaded });

  const { projectUuid } = useParams();

  const [downloadModelPeriodicity, setDownloadModelPeriodicity] =
    React.useState<keyof typeof PERIODICITY | "">("");
  const [downloadModelModalOpen, setDownloadModelModalOpen] =
    React.useState(false);

  const {
    callAPI: downloadProjectModelCallAPI,
    loading: downloadProjectModelLoading,
  } = useAPI((projectUuid: string, periodicity: keyof typeof PERIODICITY) =>
    downloadProjectModel(projectUuid, periodicity),
  );

  const {
    callAPI: getProjectDetailsCallAPI,
    loading: loadingProjectDetails,
    errored: getProjectDetailsFiled,
    response: project,
  } = useAPI((projectUuid) => getProjectDetails(projectUuid));

  const { callAPI: getProjectValuationCallAPI } = useAPI((projectUuid) =>
    getProjectValuation(projectUuid),
  );

  React.useEffect(() => {
    getProjectDetailsCallAPI(projectUuid).then((res) => {
      dispatch(setCurrentProjectAction(res));
    });
    getProjectValuationCallAPI(projectUuid).then((res) => {
      res && dispatch(setValuationExistAction(Boolean(res[0])));
    });
  }, [projectUuid]);

  React.useEffect(() => {
    dispatch(
      setCurrentProjectStatusAction({
        loading: loadingProjectDetails,
        errored: getProjectDetailsFiled,
      }),
    );
  }, [loadingProjectDetails, getProjectDetailsFiled]);

  const handleDownloadModelClick = () => {
    setDownloadModelPeriodicity("MO");
    setDownloadModelModalOpen(true);
  };

  const handleDownloadModelClose = () => {
    setDownloadModelModalOpen(false);
  };

  const handleDownloadModelConfirm = async (
    periodicity: keyof typeof PERIODICITY | "",
  ) => {
    await downloadProjectModelCallAPI(projectUuid, periodicity);
  };

  return (
    <>
      <Layout title={currentProject?.name}>
        <>
          {currentProject && (
            <Box className={styles.classes.stickyHeader}>
              <Box className={styles.classes.infoSection}>
                {organization ? (
                  <Link
                    to="/project-list"
                    className={styles.classes.backButton}
                  >
                    <ArrowBackIcon />
                    <Typography>Back to Projects List</Typography>
                  </Link>
                ) : null}
                <Box className="flex items-center justify-start">
                  <Typography variant="h5" component="h5" marginRight={3}>
                    {trimString(currentProject?.name, 45)}
                  </Typography>
                  <CustomChip
                    className="!mb-1"
                    label={DEAL_STATUS[currentProject.status]}
                    color={getStatusChipFillColor(currentProject.status).color}
                    filledBackgroundColor={
                      getStatusChipFillColor(currentProject.status)
                        .backgroundColor
                    }
                    variant="filled"
                  />
                </Box>
                <Box className="flex space-between items-start mt-2">
                  <Box className="flex flex-wrap gap-2 max-w-[80%]">
                    <Chip
                      label={trimString(currentProject?.sponsor_name, 40)}
                    />
                    <Chip label={currentProject?.capacity_ac + " MW (AC)"} />
                    <Chip label={currentProject?.capacity_dc + " MW (DC)"} />
                    <Chip label={"BOL: " + currentProject?.bol} />
                    <Chip label={"NTP: " + currentProject?.ntp} />
                    <Chip label={"COD: " + currentProject?.cod} />
                    <Chip
                      label={
                        "Tax Credit Type: " +
                        PROJECT_TAX_CREDIT_TYPE[currentProject?.tax_credit_type]
                      }
                    />
                  </Box>
                  <Box className="ml-auto">
                    <ActionButton
                      size="medium"
                      onClick={handleDownloadModelClick}
                      actionType="edit"
                      classes={{
                        root: "whitespace-nowrap flex items-center gap-1 ml-auto",
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                      Download Model
                    </ActionButton>
                  </Box>
                </Box>
                <Divider className={styles.classes.divider} />
              </Box>
            </Box>
          )}
          <Box className={styles.classes.children}>{children}</Box>
        </>
      </Layout>

      <DownloadModelFormModal
        headerLabel="Download Model"
        open={downloadModelModalOpen}
        loading={downloadProjectModelLoading}
        periodicity={downloadModelPeriodicity}
        setPeriodicity={setDownloadModelPeriodicity}
        onClose={handleDownloadModelClose}
        onConfirm={handleDownloadModelConfirm}
      />
    </>
  );
}
