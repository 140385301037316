import ProjectPagesLayout from "../../components/project-pages-layout";
import ProjectGeneralView from "../../views/project/general";
import { addDeal } from "../../apis/deal/base";
import { getProjectTiming } from "../../apis/project/timing";
import { getProjectProforma } from "../../apis/project/proforma";
import {
  editProjectSensitivity,
  getProjectSensitivity,
} from "../../apis/project/case";
import {
  addProject,
  getProjects,
  updateProject,
  duplicateProject,
  getProjectGeneralSummary,
  getLocations,
} from "../../apis/project/base";

export default function General(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <ProjectGeneralView
        getProjectGeneralSummary={getProjectGeneralSummary}
        getProjectTiming={getProjectTiming}
        duplicateProject={duplicateProject}
        updateProject={updateProject}
        addDeal={addDeal}
        addProject={addProject}
        getProjects={getProjects}
        getProjectProforma={getProjectProforma}
        getProjectLocations={getLocations}
        getProjectSensitivity={getProjectSensitivity}
        editProjectSensitivity={editProjectSensitivity}
      />
    </ProjectPagesLayout>
  );
}
