import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Tabs from "../tabs";
import { ITab } from "../../interfaces";
import { useOrganization } from "@clerk/clerk-react";
import { replacePagePathForPersonalAccount } from "../../utils/helpers";

interface IProps {
  children: JSX.Element;
}

export default function ProjectRevenueLayout({
  children,
}: IProps): JSX.Element {
  const navigate = useNavigate();

  const { projectUuid, caseProjectUuid } = useParams();
  const { pathname } = useLocation();
  const { organization } = useOrganization();

  const onTabChange = (tab: ITab) => {
    if (tab.path && projectUuid) {
      let updatedPath = tab.path
        .replace(":projectUuid", projectUuid || "")
        .replace(":caseProjectUuid", caseProjectUuid || "");

      if (!organization) {
        updatedPath = replacePagePathForPersonalAccount(
          updatedPath,
          "project",
        ) as string;
      }

      navigate(updatedPath);
    }
  };

  const selected = React.useMemo(() => {
    if (pathname.includes("merchant")) {
      return "merchant";
    }
    return "revenue";
  }, []);

  return (
    <>
      <Tabs
        tabs={[
          {
            label: "Revenue Contract",
            value: "revenue",
            path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/revenue",
          },
          {
            label: "Merchant Revenue",
            value: "merchant",
            path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/revenue/merchant",
          },
        ]}
        onClick={onTabChange}
        selectedTab={selected}
      />
      {children}
    </>
  );
}
