import React from "react";
import TextField from "@mui/material/TextField";

import Modal from "../modal";
import useStyles from "./styles";
import {
  SetStateAction,
  IProjectCaseSensitivityUpdateForm,
  IProjectCaseSensitivity,
  IProjectCaseSensitivityUpdateFormErrors,
} from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IProjectCaseSensitivityUpdateForm;
  setForm: SetStateAction<IProjectCaseSensitivityUpdateForm>;
  formErrors?: IProjectCaseSensitivityUpdateFormErrors;
  setFormErrors: SetStateAction<
    IProjectCaseSensitivityUpdateFormErrors | undefined
  >;
  onClose: () => void;
  onConfirm: () => Promise<{ data: IProjectCaseSensitivity } | undefined>;
}

export default function ProjectCaseSensitivityUpdateFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm({
      merchant_energy_price_multiplier: null,
      merchant_rec_price_multiplier: null,
      merchant_capacity_price_multiplier: null,
      cost_multiplier: null,
      expense_multiplier: null,
    });
    setFormErrors({});
  };

  const handleOnConfirm = async () => {
    const sensitivity = await onConfirm();
    sensitivity && handleOnClose();
    return sensitivity;
  };

  const handleSensitivityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: "",
    }));
  };

  return (
    <Modal
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{ paper: "!min-w-[40%]" }}
    >
      <div>
        <div className={styles.classes.tableInput}>
          <span>Merchant Energy Price Multiplier</span>
          <TextField
            type="number"
            classes={{ root: styles.classes.tableInputField }}
            name="merchant_energy_price_multiplier"
            value={form?.merchant_energy_price_multiplier}
            onChange={handleSensitivityChange}
            disabled={loading}
            InputProps={{
              endAdornment: <>X</>,
            }}
            error={!!formErrors?.merchant_energy_price_multiplier}
            helperText={formErrors?.merchant_energy_price_multiplier}
            onFocus={clearErrorOnFocus}
          />
        </div>
        <div className={styles.classes.tableInput}>
          <span>Merchant Capacity Price Multiplier</span>
          <TextField
            type="number"
            classes={{ root: styles.classes.tableInputField }}
            name="merchant_capacity_price_multiplier"
            value={form?.merchant_capacity_price_multiplier}
            onChange={handleSensitivityChange}
            disabled={loading}
            InputProps={{
              endAdornment: <>X</>,
            }}
            error={!!formErrors?.merchant_capacity_price_multiplier}
            helperText={formErrors?.merchant_capacity_price_multiplier}
            onFocus={clearErrorOnFocus}
          />
        </div>
        <div className={styles.classes.tableInput}>
          <span>Merchant REC Price Multiplier</span>
          <TextField
            type="number"
            classes={{ root: styles.classes.tableInputField }}
            name="merchant_rec_price_multiplier"
            value={form?.merchant_rec_price_multiplier}
            onChange={handleSensitivityChange}
            disabled={loading}
            InputProps={{
              endAdornment: <>X</>,
            }}
            error={!!formErrors?.merchant_rec_price_multiplier}
            helperText={formErrors?.merchant_rec_price_multiplier}
            onFocus={clearErrorOnFocus}
          />
        </div>
        <div className={styles.classes.tableInput}>
          <span>Cost Multiplier</span>
          <TextField
            type="number"
            classes={{ root: styles.classes.tableInputField }}
            name="cost_multiplier"
            value={form?.cost_multiplier}
            onChange={handleSensitivityChange}
            disabled={loading}
            InputProps={{
              endAdornment: <>X</>,
            }}
            error={!!formErrors?.cost_multiplier}
            helperText={formErrors?.cost_multiplier}
            onFocus={clearErrorOnFocus}
          />
        </div>
        <div className={styles.classes.tableInput}>
          <span>Expense Multiplier</span>
          <TextField
            type="number"
            classes={{ root: styles.classes.tableInputField }}
            name="expense_multiplier"
            value={form?.expense_multiplier}
            onChange={handleSensitivityChange}
            disabled={loading}
            InputProps={{
              endAdornment: <>X</>,
            }}
            error={!!formErrors?.expense_multiplier}
            helperText={formErrors?.expense_multiplier}
            onFocus={clearErrorOnFocus}
          />
        </div>
      </div>
    </Modal>
  );
}
