import React from "react";
import AddIcon from "@mui/icons-material/Add";
import MuiButton from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

import useStyles from "./styles";
import IconButton from "../../../components/icon-button";
import ViewWrapper from "../../../components/view-wrapper";
import Button from "../../../components/button";
import Searchbar from "../../../components/search-bar";
import PopoverMenu from "../../../components/popover-menu";
import StickyTableCell from "../../../components/sticky-table-cell";
import ProjectCaseFormModal from "../../../components/project-case-form-modal";
import ConditionalProtect from "../../../components/conditional-protect";
import ProjectCaseDuplicateFormModal from "../../../components/project-case-duplicate-form-modal";
import {
  setDeleteModalPropsAction,
  setProjectCasesAction,
} from "../../../utils/redux/slices";
import { useAPI, useLocalStorage, useSearchBar } from "../../../utils/hooks";
import { sortArrayOfObjects, trimString } from "../../../utils/helpers";
import { PROEJECT_CASE_TYPE } from "../../../constants";
import {
  ISelectOption,
  ITableColumn,
  ITableSort,
  IProjectCase,
  IProjectCaseForm,
  IProjectCaseFormErrors,
  IDuplicateProjectCaseForm,
  IDuplicateProjectCaseFormErrors,
} from "../../../interfaces";
import { Box } from "@mui/material";

interface IProps {
  getProjectCaseList: (
    projectUuid: string,
  ) => Promise<{ data: IProjectCase[] }>;
  addProjectCase: (
    projectUuid: string,
    form: IProjectCaseForm,
  ) => Promise<IProjectCase>;
  editProjectCase: (
    projectUuid: string,
    caseUuid: string,
    form: IProjectCaseForm,
  ) => Promise<IProjectCase>;
  getProjectCaseDetails: (
    projectUuid: string,
    caseUuid: string,
  ) => Promise<{ data: IProjectCase }>;
  deleteProjectCase: (
    projectUuid: string,
    caseUuid: string,
  ) => Promise<boolean>;
  duplicateProjectCase: (
    dealUuid: string,
    caseId: string,
    form: IDuplicateProjectCaseForm,
  ) => Promise<IProjectCase>;
}

const columns: ITableColumn[] = [
  { align: "left", id: "name", label: "Name", minWidth: 200 },
  { align: "left", id: "type", label: "Type", minWidth: 200 },
  { align: "left", id: "modified", label: "Modified", minWidth: 100 },
  { align: "right", id: "action", label: "Action", minWidth: 100 },
];

export default function ProjectCaseListView({
  getProjectCaseList,
  addProjectCase,
  editProjectCase,
  getProjectCaseDetails,
  deleteProjectCase,
  duplicateProjectCase,
}: IProps): JSX.Element {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { projectUuid } = useParams();

  const { searchString, onSearchStringChange, handleClearSearchString } =
    useSearchBar();

  const [caseForm, setCaseForm] = React.useState<IProjectCaseForm>({
    name: "",
    description: "",
    type: "SCEN",
    is_synced_with_base_case: true,
    parent_scenario_uuid: null,
    sensitivities: {
      merchant_energy_price_multiplier: null,
      merchant_rec_price_multiplier: null,
      merchant_capacity_price_multiplier: null,
      cost_multiplier: null,
      expense_multiplier: null,
    },
  });
  const [openAddFormModal, setOpenAddFormModal] =
    React.useState<boolean>(false);
  const [openDuplicateFormModal, setOpenDuplicateFormModal] =
    React.useState<boolean>(false);
  const [duplicateCaseForm, setDuplicateCaseForm] =
    React.useState<IDuplicateProjectCaseForm>({
      uuid: "",
      description: "",
      name: "",
    });
  React.useState<boolean>(false);
  const [openEditFormModal, setOpenEditFormModal] =
    React.useState<boolean>(false);
  const [projectCaseList, setProjectCaseList] = React.useState<IProjectCase[]>(
    [],
  );
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [selectedCase, setSelectedCase] = React.useState<string | null>(null);
  const [projectCaseData, setProjectCaseData] = useLocalStorage(
    String(projectUuid),
    "",
  );
  const [expandedRows, setExpandedRows] = React.useState<{
    [key: string]: boolean;
  }>({});

  const handleExpandRow = (uuid: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [uuid]: !prev[uuid],
    }));
  };

  const {
    callAPI: getProjectCaseListCallAPI,
    loading: projectCaseListLoading,
    errored: projectCaseListErrored,
  } = useAPI(getProjectCaseList, {
    initialLoading: true,
  });

  const {
    callAPI: duplicateCaseCallAPI,
    fieldErrors: duplicateCaseFormErrors,
    setFieldErrors: setDuplicateCaseFormErrors,
    loading: duplicateCaseLoading,
  } = useAPI<IProjectCase, IDuplicateProjectCaseFormErrors>(
    (caseId: string, form: IDuplicateProjectCaseForm) =>
      duplicateProjectCase(String(projectUuid), caseId, form),
  );

  const { callAPI: getProjectCaseDetailsCallAPI } = useAPI(
    getProjectCaseDetails,
  );

  const {
    callAPI: addProjectCaseCallAPI,
    loading: addProjectCaseLoading,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
  } = useAPI<IProjectCase, IProjectCaseFormErrors>(addProjectCase);

  const {
    callAPI: editProjectCaseCallAPI,
    loading: editProjectCaseLoading,
    fieldErrors: editProjectFormErrors,
    setFieldErrors: setEditProjectFormErrors,
  } = useAPI<IProjectCase, IProjectCaseFormErrors>(editProjectCase);

  const { callAPI: deleteProjectCaseCallAPI } = useAPI(
    (projectUuid: string, caseUuid: string) =>
      deleteProjectCase(projectUuid, caseUuid),
  );

  const handleDeleteProjectCase = async (uuid: string) => {
    const deleted = await deleteProjectCaseCallAPI(projectUuid, uuid);
    deleted && projectUuid && fetchProjectCaseList(projectUuid);
  };

  const onDeleteCaseClick = async (uuid: string) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete case",
        description: "Are you sure you want to delete this case?",
        onConfirm: () => handleDeleteProjectCase(uuid),
      }),
    );
  };

  React.useEffect(() => {
    if (projectUuid) fetchProjectCaseList(projectUuid);
  }, [projectUuid]);

  const fetchProjectCaseList = (projectUuid: string) => {
    getProjectCaseListCallAPI(projectUuid).then((res) => {
      if (res?.data) {
        setProjectCaseList(res?.data);
        dispatch(setProjectCasesAction(res.data));
        const obj = res?.data.reduce(
          (acc, c) => {
            acc[c.child_project_uuid] = c.type;
            return acc;
          },
          {} as Record<string, string>,
        );
        setProjectCaseData(JSON.stringify(obj));
      }
    });
  };

  const handleOpenAddCaseFormModal = (parent_scenario_uuid?: string) => {
    setCaseForm({
      name: "",
      description: "",
      type: parent_scenario_uuid ? "SENS" : "SCEN",
      is_synced_with_base_case: Boolean(parent_scenario_uuid),
      parent_scenario_uuid: parent_scenario_uuid || null,
      sensitivities: {
        merchant_energy_price_multiplier: null,
        merchant_rec_price_multiplier: null,
        merchant_capacity_price_multiplier: null,
        cost_multiplier: null,
        expense_multiplier: null,
      },
    });
    setOpenAddFormModal(true);
  };

  const handleCloseAddCaseFormModal = () => {
    setOpenAddFormModal(false);
  };

  const handleOpenDuplicateModal = async (uuid: string) => {
    setDuplicateCaseForm((prev) => ({ ...prev, uuid }));
    setOpenDuplicateFormModal(true);
  };

  const handleCloseDuplicateCaseFormModal = () => {
    setOpenDuplicateFormModal(false);
  };

  const handleDuplicateCase = async (form: IDuplicateProjectCaseForm) => {
    const { uuid: caseId } = form;
    const projectCase = await duplicateCaseCallAPI(caseId, form);
    projectCase && projectUuid && fetchProjectCaseList(projectUuid);
    return projectCase;
  };

  const handleOpenEditModal = async (uuid: string) => {
    const pCase = await getProjectCaseDetailsCallAPI(projectUuid, uuid);
    pCase && setSelectedCase(pCase.data.uuid);
    if (pCase) {
      setCaseForm({
        name: pCase.data.name,
        type: pCase.data.type,
        description: pCase.data.description,
        parent_scenario_uuid:
          pCase.data.parent_scenario_uuid ?? String(projectUuid),
        sensitivities: null,
        is_synced_with_base_case: !!pCase?.data.is_synced_with_base_case,
      });
    }
    setOpenEditFormModal(true);
  };

  const handleCloseEditCaseFormModal = () => {
    setOpenEditFormModal(false);
    setSelectedCase(null);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = React.useMemo(() => {
    return projectCaseList
      .filter((c) => c.type === "SCEN")
      .filter((c) => c.name.toLowerCase().includes(searchString.toLowerCase()));
  }, [projectCaseList, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleOnAddCase = async () => {
    const updatedForm = _.cloneDeep(caseForm);
    if (updatedForm.parent_scenario_uuid === projectUuid) {
      updatedForm.parent_scenario_uuid = null;
    }
    if (updatedForm.type === "SCEN") {
      updatedForm.sensitivities = null;
    }
    const projectCase = await addProjectCaseCallAPI(projectUuid, updatedForm);
    projectCase && projectUuid && fetchProjectCaseList(projectUuid);
    return projectCase;
  };

  const handleOnEditCase = async () => {
    const projectCase = await editProjectCaseCallAPI(
      projectUuid,
      selectedCase,
      caseForm,
    );
    projectCase && projectUuid && fetchProjectCaseList(projectUuid);
    return projectCase;
  };

  const scenarioOptions: ISelectOption[] = React.useMemo(() => {
    return projectCaseList
      .filter((c) => c.type === "SCEN")
      .map((c) => {
        return {
          label: c.name,
          value: c.uuid,
        };
      });
  }, [projectCaseList]);

  const renderItsSensitivities = (pCase: IProjectCase) => {
    if (!expandedRows[pCase.uuid]) return null;

    return (
      <React.Fragment>
        {projectCaseList
          .filter((c) => c.parent_scenario_uuid === pCase.uuid)
          .map((c, idx) => (
            <TableRow
              hover
              key={idx}
              tabIndex={-1}
              onClick={() => gotoProject(c.child_project_uuid)}
              className={styles.classes.dataRow}
            >
              <TableCell className="!pl-11">{trimString(c.name, 40)}</TableCell>
              <TableCell>{PROEJECT_CASE_TYPE[c.type]}</TableCell>
              <TableCell>
                {formatDistanceToNow(new Date(c.modified), {
                  addSuffix: true,
                })}
              </TableCell>

              <ConditionalProtect type="project">
                <StickyTableCell
                  direction="right"
                  fixedColumnWidth={100}
                  align="center"
                >
                  <PopoverMenu
                    uniqueId={idx}
                    canOpenUpgrade
                    items={[
                      {
                        label: "Duplicate",
                        onClick: () => handleOpenDuplicateModal(c.uuid),
                      },
                      {
                        label: "Edit",
                        onClick: () => handleOpenEditModal(c.uuid),
                        disabled: Boolean(c.is_base_case_scenario),
                      },
                      {
                        label: "Delete",
                        onClick: () => onDeleteCaseClick(c.uuid),
                        disabled: Boolean(c.is_base_case_scenario),
                      },
                    ]}
                  />
                </StickyTableCell>
              </ConditionalProtect>
            </TableRow>
          ))}
        <TableRow>
          <ConditionalProtect type="deal">
            <MuiButton
              className="!ml-11 !my-2 !text-secondary"
              size="small"
              variant="text"
              onClick={() => handleOpenAddCaseFormModal(pCase.uuid)}
            >
              <AddIcon fontSize="small" /> Add Sensitivity
            </MuiButton>
          </ConditionalProtect>
        </TableRow>
      </React.Fragment>
    );
  };

  const gotoProject = (child_project_uuid: string) => {
    navigate(
      `/project/${projectUuid}/case-project/${child_project_uuid}/general`,
    );
  };

  return (
    <>
      <ViewWrapper
        loading={projectCaseListLoading}
        error={projectCaseListErrored}
      >
        {projectCaseList?.length > 0 ? (
          <Box>
            <Box className={styles.classes.topSection}>
              <Box className={styles.classes.searchAndFilterContainer}>
                <Searchbar
                  placeholder="Case Name"
                  searchString={searchString}
                  onSearchStringChange={onSearchStringChange}
                  handleClearSearchString={handleClearSearchString}
                />
              </Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Create Case"
                  onClick={() => handleOpenAddCaseFormModal()}
                />
              </ConditionalProtect>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden", height: "auto" }}>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  data-pw="project-cases-table"
                >
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {columns.map((column, idx) => {
                        if (column.id === "action") {
                          return (
                            <StickyTableCell
                              key={idx}
                              direction="right"
                              fixedColumnWidth={100}
                              align="center"
                              highZIndex
                            >
                              {column.label}
                            </StickyTableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={idx}
                            align={column.align as "left"}
                            style={{ maxWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={sortTable.orderBy === column.id}
                              direction={
                                sortTable.orderBy === column.id
                                  ? sortTable.order
                                  : "asc"
                              }
                              onClick={() => sortRows(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectCaseList.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={columns.length} align="center">
                          No Cases.
                        </TableCell>
                      </TableRow>
                    )}
                    {visibleRows.map((c, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <TableRow
                            hover
                            tabIndex={-1}
                            className={styles.classes.dataRow}
                            onClick={() => {
                              if (c.is_base_case_scenario)
                                gotoProject(String(projectUuid));
                              else gotoProject(c.child_project_uuid);
                            }}
                          >
                            <TableCell>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleExpandRow(c.uuid);
                                }}
                                classes={{ root: "!mr-2" }}
                              >
                                {expandedRows[c.uuid] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>{" "}
                              {trimString(c.name, 40)}
                            </TableCell>
                            <TableCell>{PROEJECT_CASE_TYPE[c.type]}</TableCell>
                            <TableCell>
                              {formatDistanceToNow(new Date(c.modified), {
                                addSuffix: true,
                              })}
                            </TableCell>

                            <ConditionalProtect type="project">
                              <StickyTableCell
                                direction="right"
                                fixedColumnWidth={100}
                                align="center"
                              >
                                <PopoverMenu
                                  uniqueId={idx}
                                  canOpenUpgrade
                                  items={[
                                    {
                                      label: "Duplicate",
                                      onClick: () =>
                                        handleOpenDuplicateModal(c.uuid),
                                    },
                                    {
                                      label: "Edit",
                                      onClick: () =>
                                        handleOpenEditModal(c.uuid),
                                      disabled: c.is_base_case_scenario,
                                    },
                                    {
                                      label: "Delete",
                                      onClick: () => onDeleteCaseClick(c.uuid),
                                      disabled: c.is_base_case_scenario,
                                    },
                                  ]}
                                />
                              </StickyTableCell>
                            </ConditionalProtect>
                          </TableRow>
                          {renderItsSensitivities(c)}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredRows?.length}
                className={styles.classes.paginationRow}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <Box className={styles.classes.emptyContainer}>
            <Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Create Case"
                  onClick={() => handleOpenAddCaseFormModal()}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>

              <Typography variant="body1" className={styles.classes.createInfo}>
                It looks like you do not have any cases yet.
              </Typography>
            </Box>
          </Box>
        )}
      </ViewWrapper>

      <ProjectCaseFormModal
        type="add"
        open={openAddFormModal}
        headerLabel="Create Case"
        form={caseForm}
        setForm={setCaseForm}
        loading={addProjectCaseLoading}
        onClose={handleCloseAddCaseFormModal}
        onConfirm={handleOnAddCase}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        scenarioOptions={scenarioOptions}
      />

      <ProjectCaseFormModal
        type="edit"
        open={openEditFormModal}
        headerLabel="Edit Case"
        form={caseForm}
        setForm={setCaseForm}
        loading={editProjectCaseLoading}
        onClose={handleCloseEditCaseFormModal}
        onConfirm={handleOnEditCase}
        formErrors={editProjectFormErrors}
        setFormErrors={setEditProjectFormErrors}
        scenarioOptions={scenarioOptions}
      />

      <ProjectCaseDuplicateFormModal
        headerLabel="Duplicate Case"
        open={openDuplicateFormModal}
        loading={duplicateCaseLoading}
        form={duplicateCaseForm}
        setForm={setDuplicateCaseForm}
        formErrors={duplicateCaseFormErrors}
        setFormErrors={setDuplicateCaseFormErrors}
        onClose={handleCloseDuplicateCaseFormModal}
        onConfirm={handleDuplicateCase}
      />
    </>
  );
}
