import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import LogsWrapper from "../../../../components/logs-wrapper";
import ViewWrapper from "../../../../components/view-wrapper";
import LogsButton from "../../../../components/logs-button";
import DetailsCard from "../../../../components/details-card";
import ConditionalProtect from "../../../../components/conditional-protect";
import DealTermDebtFormModal from "../../../../components/deal-debt-form-modal";
import ToggleSizingOutputButton from "../../../../components/toggle-sizing-output-button";
import { cn } from "../../../../utils/helpers";
import {
  setCurrentDealTermDebtAction,
  setCurrentOrgCurvesAction,
  setDeleteModalPropsAction,
} from "../../../../utils/redux/slices";
import {
  useAPI,
  useAppSelector,
  useDrawer,
  useLogs,
} from "../../../../utils/hooks";
import {
  DEAL_TERM_DEBT_BASE_INPUT_TYPES,
  DEAL_TERM_DEBT_FORM_DEFAULT_STATE,
  PERIODICITY,
  DEAL_TERM_DEBT_SIZING_TIMING,
  DEAL_TERM_DEBT_SOLVE_FORS,
  DEAL_TERM_TERM_DEBT_TYPES,
} from "../../../../constants";
import {
  IDealDebt,
  IDealTermDebtForm,
  IDealTermDebtFormErrors,
  IGetCurvesParams,
  ILogsConfiguration,
  IOrganizationCurve,
  ISelectOption,
  ServerPaginatedResponse,
} from "../../../../interfaces";
import ReportTable from "../../../../components/report-table";

interface IProps {
  deleteDealDebt: (dealUuid: string, taxEquityId: number) => Promise<boolean>;
  addDealDebt: (
    dealUuid: string,
    form: IDealTermDebtForm,
  ) => Promise<IDealDebt>;
  updateDealDebt: (
    dealUuid: string,
    taxEquityId: number,
    form: IDealTermDebtForm,
  ) => Promise<IDealDebt>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
}

export default function DealSizingTermDebtView({
  deleteDealDebt,
  addDealDebt,
  updateDealDebt,
  getCurves,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { dealUuid, caseDealUuid } = useParams();

  const dispatch = useDispatch();
  const {
    currentDeal,
    currentDealTermDebt: dealTermDebt,
    currentDealTermDebtStatus: debtStatus,
  } = useAppSelector((s) => s.deal);
  const { currentOrgCurves } = useAppSelector((s) => s.org);

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [addDealDebtModalOpen, setAddDealDebtModalOpen] =
    React.useState<boolean>(false);
  const [editDealDebtModalOpen, setEditDealDebtModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IDealTermDebtForm>(
    DEAL_TERM_DEBT_FORM_DEFAULT_STATE,
  );

  const {
    callAPI: addDealDebtCallAPI,
    fieldErrors: addDealTermDebtFormErrors,
    setFieldErrors: setAddDealTermDebtFormErrors,
    loading: addDealDebtLoading,
  } = useAPI<IDealDebt, IDealTermDebtFormErrors>(
    (dealUuid: string, form: IDealTermDebtForm) => addDealDebt(dealUuid, form),
  );

  const {
    callAPI: updateDealDebtCallAPI,
    fieldErrors: updateDealTermDebtFormErrors,
    setFieldErrors: setUpdateDealTermDebtFormErrors,
    loading: updateDealDebtLoading,
  } = useAPI<IDealDebt, IDealTermDebtFormErrors>(
    (dealUuid: string, dealTermDebtId: number, form: IDealTermDebtForm) =>
      updateDealDebt(dealUuid, dealTermDebtId, form),
  );

  const { callAPI: deleteDealDebtCallAPI } = useAPI(
    (dealUuid: string, debtId: number) => deleteDealDebt(dealUuid, debtId),
    { setConfirmModalLoading: true },
  );

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: ["IRC"] }).catch(() => null);
    curves && dispatch(setCurrentOrgCurvesAction(curves.results));
  };

  const ircCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves.map((c) => ({
      label: c.name,
      value: c.uuid,
    }));
  }, [currentOrgCurves]);

  const handleOnDeleteDealDebt = async () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Deal Term Debt",
        description: "Are you sure you want to delete this Deal Debt?",
        onConfirm: handleDeleteDealDebt,
      }),
    );
  };

  const handleDeleteDealDebt = async () => {
    if (caseDealUuid && dealTermDebt) {
      const response = await deleteDealDebtCallAPI(
        caseDealUuid,
        dealTermDebt.id,
      );
      if (response) {
        dispatch(setCurrentDealTermDebtAction(undefined));
      }
    }
  };

  const handleOnAddDealDebt = async (form: IDealTermDebtForm) => {
    const res = await addDealDebtCallAPI(caseDealUuid, form);
    res && dispatch(setCurrentDealTermDebtAction(res));

    return res;
  };

  const handleOnEditDealDebt = async (form: IDealTermDebtForm) => {
    const updatedDealDebt = await updateDealDebtCallAPI(
      caseDealUuid,
      Number(dealTermDebt?.id),
      form,
    );
    updatedDealDebt && dispatch(setCurrentDealTermDebtAction(updatedDealDebt));

    return updatedDealDebt;
  };

  const handleOpenAddDealDebtModal = async () => {
    await handleGetCurves();
    setAddDealDebtModalOpen(true);
  };

  const handleCloseAddDealDebtModal = () => {
    setAddDealDebtModalOpen(false);
  };

  const handleOpenEditDealDebtModal = async () => {
    if (dealTermDebt) {
      await handleGetCurves();
      const { id, created, modified, deal, ...formDetails } = dealTermDebt;
      setForm({ ...formDetails });
      setEditDealDebtModalOpen(true);
    }
  };

  const handleCloseEditDealDebtModal = () => {
    setEditDealDebtModalOpen(false);
  };

  const gotoCurveDetailPage = (url: string) => {
    window.open(url);
  };

  const dealSizingDebtLogConfiguration: ILogsConfiguration = {
    id: Number(dealTermDebt?.id),
    type: "dealtermdebt",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      dealSizingDebtLogConfiguration.type,
      dealSizingDebtLogConfiguration.id,
    );
  };

  const debtFundingOptions = React.useMemo(() => {
    return {
      first_cod: currentDeal?.first_cod || "",
      last_cod: currentDeal?.last_cod || "",
    };
  }, [currentDeal]);

  return (
    <>
      <ViewWrapper loading={debtStatus.loading} error={debtStatus.errored}>
        <Box>
          {!dealTermDebt && (
            <Box className={styles.classes.emptyContainer}>
              <Box>
                <ConditionalProtect type="deal">
                  <Button
                    canOpenUpgrade
                    startIcon={<AddIcon />}
                    label="Add Deal Term Debt"
                    onClick={handleOpenAddDealDebtModal}
                    btnType="primary"
                    className={styles.classes.createBtn}
                  />
                </ConditionalProtect>
                <Typography
                  variant="body1"
                  className={styles.classes.createInfo}
                >
                  Debt on this Deal does not exist. Please click "Add Debt" to
                  add one
                </Typography>
              </Box>
            </Box>
          )}

          {dealTermDebt && (
            <Box>
              <Box className={cn("flex justify-end items-center gap-2")}>
                <ToggleSizingOutputButton />
                <LogsButton onClick={handleOnOpenLogs} />

                <ConditionalProtect type="deal">
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleOpenEditDealDebtModal}
                  />
                  <Button
                    canOpenUpgrade
                    label="Delete"
                    btnType="danger"
                    onClick={handleOnDeleteDealDebt}
                  />
                </ConditionalProtect>
              </Box>
              <Box className={styles.classes.content}>
                <Box className="grid md:grid-cols-2 gap-4">
                  <Box>
                    <Box>
                      <Typography fontWeight="bold" marginBottom={1}>
                        Key Parameters
                      </Typography>
                      <Box className="mb-4">
                        <DetailsCard
                          heading="Structure"
                          autoHeight
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Type",
                                  value: {
                                    text: DEAL_TERM_TERM_DEBT_TYPES[
                                      dealTermDebt.type
                                    ],
                                  },
                                },
                                {
                                  label: "Solve for",
                                  value: {
                                    text: DEAL_TERM_DEBT_SOLVE_FORS[
                                      dealTermDebt.solve_for
                                    ],
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                      </Box>
                      <Box className="mb-4">
                        <DetailsCard
                          heading="Timing"
                          autoHeight
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Sizing Timing",
                                  value: {
                                    text: DEAL_TERM_DEBT_SIZING_TIMING[
                                      dealTermDebt.sizing_timing as keyof typeof DEAL_TERM_DEBT_SIZING_TIMING
                                    ],
                                  },
                                },
                                {
                                  label: "Debt Funding Date",
                                  value: { text: dealTermDebt.funding_date },
                                },
                                {
                                  label: "Amortization Tenor",
                                  value: {
                                    text:
                                      (dealTermDebt.debt_term || 0) + " Yrs",
                                  },
                                },
                                {
                                  label: "Payment Periodicity",
                                  value: {
                                    text: PERIODICITY[
                                      dealTermDebt.payment_periodicity as keyof typeof PERIODICITY
                                    ],
                                  },
                                },

                                // hide these for now

                                // all fees hidden for now.
                                // {
                                //   label: "Upfront Fee",
                                //   value: {
                                //     text:
                                //       (dealTermDebt.upfront_fees_percentage || 0) +
                                //       "%",
                                //   },
                                // },
                                // {
                                //   label: "LC Fee",
                                //   value: {
                                //     text:
                                //       (dealTermDebt.lc_fees_percentage || 0) + "%",
                                //   },
                                // },
                                // {
                                //   label: "Admin Fee",
                                //   value: {
                                //     text: numberToUSD.format(
                                //       dealTermDebt.admin_fees || 0,
                                //     ),
                                //   },
                                // },
                              ],
                            },
                          ]}
                        />
                      </Box>
                      <DetailsCard
                        heading="Sizing"
                        autoHeight
                        sections={[
                          {
                            fields: [
                              {
                                label: "Contracted Cash DSCR",
                                value: {
                                  text:
                                    (dealTermDebt?.contracted_cash_dscr || 0) +
                                    "x",
                                },
                              },
                              {
                                label: "Uncontracted Cash DSCR",
                                value: {
                                  text:
                                    (dealTermDebt?.uncontracted_cash_dscr ||
                                      0) + "x",
                                },
                              },
                              {
                                label: "Special Cash DSCR",
                                value: {
                                  text:
                                    (dealTermDebt?.special_cash_dscr || 0) +
                                    "x",
                                },
                              },
                              {
                                label: "Transfer Cash DSCR",
                                value: {
                                  text:
                                    (dealTermDebt?.transfer_cash_dscr || 0) +
                                    "x",
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography fontWeight="bold" marginBottom={1}>
                      Interest and Fees
                    </Typography>
                    <Box className="mb-4">
                      <DetailsCard
                        heading="Interest"
                        autoHeight
                        sections={[
                          {
                            fields: [
                              {
                                label: "Base Rate Type",
                                value: {
                                  text: DEAL_TERM_DEBT_BASE_INPUT_TYPES[
                                    dealTermDebt.base_input_type as keyof typeof DEAL_TERM_DEBT_BASE_INPUT_TYPES
                                  ],
                                },
                              },
                              ...(dealTermDebt.base_input_type === "RATE"
                                ? [
                                    {
                                      label: "Base Rate",
                                      value: {
                                        text:
                                          (dealTermDebt.base_rate || 0) + "%",
                                      },
                                    },
                                  ]
                                : []),
                              ...(dealTermDebt.base_input_type === "OC"
                                ? [
                                    {
                                      label: "SOFR (Organization Curve)",
                                      value: {
                                        text: (
                                          <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() =>
                                              gotoCurveDetailPage(
                                                `/configuration/data/curves/${dealTermDebt.sofr_org_curve_detail?.curve_group}/${dealTermDebt.sofr_org_curve_detail?.uuid}`,
                                              )
                                            }
                                          >
                                            {
                                              dealTermDebt
                                                ?.sofr_org_curve_detail?.name
                                            }
                                            <OpenInNewIcon fontSize="small" />
                                          </Link>
                                        ),
                                      },
                                    },
                                  ]
                                : []),
                              {
                                label: "Spread",
                                value: {
                                  text: (dealTermDebt.spread || 0) + "%",
                                },
                              },
                              {
                                label: "Step Up",
                                value: {
                                  text:
                                    (dealTermDebt.spread_step_up || 0) + "%",
                                },
                              },
                              {
                                label: "Step Up Frequency (Yrs)",
                                value: {
                                  text:
                                    (dealTermDebt.spread_step_up_frequency ||
                                      0) + " Yrs",
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </Box>
                    <DetailsCard
                      heading="Interest Rate Swap"
                      autoHeight
                      sections={[
                        {
                          fields: [
                            {
                              label: "Hedged Percentage",
                              value: {
                                text:
                                  (dealTermDebt.hedged_percentage || 0) + "%",
                              },
                            },
                            {
                              label: "Swap Rate",
                              value: {
                                text: (dealTermDebt.swap_rate || 0) + "%",
                              },
                            },
                            {
                              label: "Swap Rate Credit Spread",
                              value: {
                                text:
                                  (dealTermDebt.swap_rate_credit_spread || 0) +
                                  "%",
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <DealTermDebtFormModal
        open={addDealDebtModalOpen}
        onClose={handleCloseAddDealDebtModal}
        formErrors={addDealTermDebtFormErrors}
        loading={addDealDebtLoading}
        setFormErrors={setAddDealTermDebtFormErrors}
        form={form}
        setForm={setForm}
        headerLabel="Add Deal Term Debt"
        onConfirm={handleOnAddDealDebt}
        ircCurves={ircCurvesOptions}
        debtFundingDate={debtFundingOptions}
      />

      <DealTermDebtFormModal
        open={editDealDebtModalOpen}
        onClose={handleCloseEditDealDebtModal}
        form={form}
        loading={updateDealDebtLoading}
        formErrors={updateDealTermDebtFormErrors}
        setFormErrors={setUpdateDealTermDebtFormErrors}
        setForm={setForm}
        headerLabel="Edit Deal Term Debt"
        onConfirm={handleOnEditDealDebt}
        ircCurves={ircCurvesOptions}
        debtFundingDate={debtFundingOptions}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealSizingDebtLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination?.totalItems}
          id={dealSizingDebtLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
