import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { trimString } from "../../utils/helpers";

interface IOption {
  label: string;
  value: string;
  children?: IOption[];
}

interface IProps {
  groupedOptions: IOption[];
  selectedScenario?: string;
  selectedSensitivity?: string;
  onChange: (scenarioValue: string, sensitivityValue?: string) => void;
  className: string;
  disabled?: boolean;
}

const ScenarioSensitivitySelector = ({
  groupedOptions,
  selectedScenario,
  selectedSensitivity,
  onChange,
  className,
  disabled = false,
}: IProps) => {
  const [open, setOpen] = React.useState(false);

  const handleScenarioSelection = React.useCallback(
    (scenarioValue: string) => {
      // Selecting a scenario alone clears the sensitivity
      onChange(scenarioValue, undefined);
      setOpen(false);
    },
    [onChange],
  );

  const handleSensitivitySelection = React.useCallback(
    (scenarioValue: string, sensitivityValue: string) => {
      // Selecting sensitivity automatically implies the parent scenario is selected
      onChange(scenarioValue, sensitivityValue);
      setOpen(false);
    },
    [onChange],
  );

  const findDisplayLabel = React.useCallback(() => {
    if (!selectedScenario) return "Select Scenario / Sensitivity";

    const scenario = groupedOptions.find((s) => s.value === selectedScenario);
    if (!scenario) return "Select Scenario / Sensitivity";

    if (!selectedSensitivity) return scenario.label;

    const sensitivity = scenario.children?.find(
      (child) => child.value === selectedSensitivity,
    );
    if (!sensitivity) return scenario.label;

    return `${scenario.label} - ${sensitivity.label}`;
  }, [selectedScenario, selectedSensitivity, groupedOptions]);

  const isScenarioSelected = React.useCallback(
    (scenarioValue: string) => {
      if (selectedScenario === scenarioValue) {
        // Scenario is selected if:
        // - Scenario itself is directly selected
        // - OR any of its children (sensitivities) are selected
        const scenario = groupedOptions.find((s) => s.value === scenarioValue);
        if (
          scenario?.children?.some(
            (child) => child.value === selectedSensitivity,
          )
        ) {
          return true;
        }
        return selectedSensitivity === undefined;
      }
      return false;
    },
    [selectedScenario, selectedSensitivity, groupedOptions],
  );

  const isSensitivitySelected = React.useCallback(
    (scenarioValue: string, sensitivityValue: string) => {
      return (
        selectedScenario === scenarioValue &&
        selectedSensitivity === sensitivityValue
      );
    },
    [selectedScenario, selectedSensitivity],
  );

  return (
    <Select
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={
        selectedScenario
          ? `${selectedScenario}${selectedSensitivity ? `::${selectedSensitivity}` : ""}`
          : ""
      }
      displayEmpty
      renderValue={findDisplayLabel}
      className={className}
      disabled={disabled}
    >
      {groupedOptions.map((scenario) => (
        <React.Fragment key={scenario.value}>
          <MenuItem
            classes={{
              root: `${isScenarioSelected(scenario.value) ? "!text-gray-500" : ""}`,
            }}
            onClick={() => handleScenarioSelection(scenario.value)}
          >
            {trimString(scenario.label, 30)}
          </MenuItem>

          {scenario.children?.map((sensitivity) => (
            <MenuItem
              key={sensitivity.value}
              onClick={() =>
                handleSensitivitySelection(scenario.value, sensitivity.value)
              }
              classes={{
                root: `!pl-8 !flex !items-center ${
                  isSensitivitySelected(scenario.value, sensitivity.value)
                    ? "!text-gray-500"
                    : ""
                }`,
              }}
            >
              {trimString(sensitivity.label, 30)}
            </MenuItem>
          ))}
        </React.Fragment>
      ))}
    </Select>
  );
};

export default React.memo(
  ScenarioSensitivitySelector,
  (prevProps, nextProps) => {
    const stringifiedPrevProps = JSON.stringify(prevProps);
    const stringifiedNextProps = JSON.stringify(nextProps);
    return stringifiedPrevProps === stringifiedNextProps;
  },
);
