import { PROJECT_API_ROUTES } from "../../constants";
import {
  IDuplicateProjectCaseForm,
  IProjectCase,
  IProjectCaseForm,
  IProjectCaseSensitivity,
  IProjectCaseSensitivityUpdateForm,
} from "../../interfaces/project/case.interfaces";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getProjectCaseList = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.list, { projectUuid });
  return await AxiosHelper.get<{ data: IProjectCase[] }>(url);
};

export const addProjectCase = async (
  projectUuid: string,
  form: IProjectCaseForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.add, { projectUuid });
  return await AxiosHelper.post<IProjectCaseForm, IProjectCase>(url, form);
};

export const editProjectCase = async (
  projectUuid: string,
  caseUuid: string,
  form: IProjectCaseForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.update, {
    projectUuid,
    caseUuid,
  });
  return await AxiosHelper.put<IProjectCaseForm, IProjectCase>(url, form);
};

export const getProjectCaseDetails = async (
  projectUuid: string,
  caseUuid: string,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.detais, {
    projectUuid,
    caseUuid,
  });
  return await AxiosHelper.get<{ data: IProjectCase }>(url);
};

export const deleteProjectCase = async (
  projectUuid: string,
  caseUuid: string,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.delete, {
    projectUuid,
    caseUuid,
  });
  return await AxiosHelper.delete(url);
};

export const getProjectSensitivity = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.getCaseSensitivity, {
    projectUuid,
  });
  return await AxiosHelper.get<IProjectCaseSensitivity>(url);
};

export const editProjectSensitivity = async (
  projectUuid: string,
  sensitivityId: number,
  form: IProjectCaseSensitivityUpdateForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.editCaseSensitivity, {
    projectUuid,
    sensitivityId,
  });
  return await AxiosHelper.put<
    IProjectCaseSensitivityUpdateForm,
    { data: IProjectCaseSensitivity }
  >(url, form);
};

export const duplicateProjectCase = async (
  projectUuid: string,
  caseId: string,
  form: IDuplicateProjectCaseForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.case.duplicate, {
    projectUuid,
    caseId,
  });
  return await AxiosHelper.post<IDuplicateProjectCaseForm, IProjectCase>(
    url,
    form,
  );
};
