import { useOrganization } from "@clerk/clerk-react";

import PrimaryProjectPagesLayout from "./primary";
import PersonalAccountProjectPagesLayout from "./personal-account";

interface IProps {
  children: JSX.Element;
}

export default function ProjectPagesLayout({ children }: IProps): JSX.Element {
  const { organization } = useOrganization();

  const Layout = organization
    ? PrimaryProjectPagesLayout
    : PersonalAccountProjectPagesLayout;

  return <Layout>{children}</Layout>;
}
