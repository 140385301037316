import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import ProjectCostChart from "./chart";
import Logs from "../../../../components/logs";
import LogsButton from "../../../../components/logs-button";
import LogsWrapper from "../../../../components/logs-wrapper";
import ViewWrapper from "../../../../components/view-wrapper";
import ChartWrapper from "../../../../components/chart-wrapper";
import ReportTableV2 from "../../../../components/report-table-v2";
import ProjectCostFormModal from "../../../../components/project-cost-form-modal";
import CostReportChart from "../../../../components/charts/cost-report-chart";
import CollapsibleWrapper from "../../../../components/collapsible-wrapper";
import { useAPI } from "../../../../utils/hooks";
import { useLogs } from "../../../../utils/hooks/logs.hooks";
import { useDrawer } from "../../../../utils/hooks/drawer.hooks";
import { downloadProjectCostScheduleDetail } from "../../../../apis/project/cost";
import {
  cn,
  formatCurrency,
  formatNumberWithDollarAndDecimals,
  replacePagePathForPersonalAccount,
} from "../../../../utils/helpers";
import {
  PROJECT_COST_SCHEDULE_REPORT_TYPE,
  PROJECT_COST_TYPE,
} from "../../../../constants";
import {
  ITableColumn,
  IProjectCostSummaryResponse,
  IProjectCostForm,
  IProjectCostFormErrors,
  ILogsConfiguration,
  IReportTable,
} from "../../../../interfaces";

export const PROJECT_COST_TABLE_COLUMNS: ITableColumn[] = [
  { id: "category", label: "", minWidth: 80, align: "left" },
  { id: "cost", label: "Cost ($)", minWidth: 80, align: "right" },
  {
    id: "cost_per_capacity",
    label: "Cost ($/Wdc)",
    minWidth: 80,
    align: "right",
  },
  { id: "cost_percentage", label: "Cost (%)", minWidth: 80, align: "right" },
];

interface IProps {
  getProjectCostSummary: (
    projectUuid: string,
  ) => Promise<IProjectCostSummaryResponse>;
  updateProjectCosts: (
    form: { profit_percent: number },
    projectUuid: string,
    costId: number,
  ) => Promise<unknown>;
  getProjectCostsScheduleDetail: (
    projectUuid: string,
    cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
  ) => Promise<IReportTable>;
}

export default function ProjectCostView({
  getProjectCostSummary,
  updateProjectCosts,
  getProjectCostsScheduleDetail,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { projectUuid, caseProjectUuid } = useParams();
  const { organization } = useOrganization();
  const projectIdToUse = organization ? caseProjectUuid : projectUuid;

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const navigate = useNavigate();

  const [projectCostScheduleDetail, setProjectCostScheduleDetail] =
    React.useState<IReportTable>();
  const [projectCostSummary, setProjectCostSummary] =
    React.useState<IProjectCostSummaryResponse>();
  const [projectCostForm, setProjectCostForm] =
    React.useState<IProjectCostForm>({
      fmv_step_up_input_type: "",
      profit_percent: "",
      fmv_step_up: "",
    });
  const [updateProjectCostModalOpen, setUpdateProjectCostModalOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getProjectCostSummaryData(String(projectIdToUse));
    getProjectCostScheduleDetailCallAPI(projectIdToUse, "cost").then((res) => {
      res && setProjectCostScheduleDetail(res);
    });
  }, [projectIdToUse]);

  const getProjectCostSummaryData = (projectUuid: string) => {
    getProjectCostSummaryCallAPI(projectUuid).then((response) => {
      response && setProjectCostSummary(response);
    });
  };

  const {
    callAPI: getProjectCostSummaryCallAPI,
    errored: getCostSummaryFailed,
    loading: loadingCostSummary,
  } = useAPI((projectUuid: string) => getProjectCostSummary(projectUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectCostScheduleDetailCallAPI,
    errored: getCostScheduleDetailFailed,
    loading: loadingCostScheduleDetail,
  } = useAPI(
    (
      projectUuid: string,
      cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
    ) => getProjectCostsScheduleDetail(projectUuid, cost_type),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateProjecCostCallAPI,
    loading: loadingUpdateProjecCost,
    fieldErrors: updateProjecCostFormErrors,
    setFieldErrors: setUpdateProjecCostFormErrors,
  } = useAPI<unknown, IProjectCostFormErrors>(
    (form, projectUuid: string, costId: number) =>
      updateProjectCosts(form, projectUuid, costId),
  );

  const handleCloseUpdateCostModalOpen = () => {
    setUpdateProjectCostModalOpen(false);
  };

  const onEditProjectCost = async (form: IProjectCostForm) => {
    const res = await updateProjecCostCallAPI(
      form,
      projectIdToUse,
      projectCostSummary?.data.project_cost_id,
    );
    res && getProjectCostSummaryData(String(projectIdToUse));
    return res;
  };

  const downloadCostReport = async () => {
    await downloadProjectCostScheduleDetail(
      String(projectIdToUse),
      "cost",
    ).catch(() => null);
  };

  const projectCostLogConfiguration: ILogsConfiguration = {
    id: Number(projectCostSummary?.data?.project_cost_id),
    type: "projectcost",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectCostLogConfiguration.type,
      projectCostLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingCostSummary} error={getCostSummaryFailed}>
        <>
          {projectCostSummary && (
            <Box>
              <Box className={styles.classes.header}>
                <Box className={cn("flex items-center")}>
                  <LogsButton onClick={handleOnOpenLogs} />
                </Box>
              </Box>
              <Box className={styles.classes.content}>
                <Box className={cn("flex flex-col gap-4")}>
                  <ViewWrapper
                    loading={loadingCostScheduleDetail}
                    error={getCostScheduleDetailFailed}
                  >
                    {projectCostScheduleDetail && (
                      <ReportTableV2
                        data={projectCostScheduleDetail}
                        download={downloadCostReport}
                        toggleDatesVisibility
                        styledTitle
                      />
                    )}
                  </ViewWrapper>
                  <ViewWrapper
                    loading={loadingCostScheduleDetail}
                    error={getCostScheduleDetailFailed}
                  >
                    {projectCostScheduleDetail && (
                      <CollapsibleWrapper title="" collapseButtonLabel="Chart">
                        <ChartWrapper className={cn("!shadow-none")}>
                          <CostReportChart data={projectCostScheduleDetail} />
                        </ChartWrapper>
                      </CollapsibleWrapper>
                    )}
                  </ViewWrapper>
                </Box>
              </Box>
            </Box>
          )}
        </>
      </ViewWrapper>

      <ProjectCostFormModal
        form={projectCostForm}
        setForm={setProjectCostForm}
        headerLabel="Edit FMV Step Up"
        loading={loadingUpdateProjecCost}
        onClose={handleCloseUpdateCostModalOpen}
        onConfirm={onEditProjectCost}
        open={updateProjectCostModalOpen}
        setFormErrors={setUpdateProjecCostFormErrors}
        formErrors={updateProjecCostFormErrors}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectCostLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectCostLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
