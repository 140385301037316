import React from "react";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import IconButton from "../../../../../components/icon-button";
import CostItemFormModal from "../../../../../components/cost-item-form-modal";
import ViewWrapper from "../../../../../components/view-wrapper";
import CurveFieldLabel from "../../../../../components/curve-field-label";
import DateSchedule from "../../../../../components/date-schedule";
import Button from "../../../../../components/button";
import DetailsCard from "../../../../../components/details-card";
import Logs from "../../../../../components/logs";
import LogsButton from "../../../../../components/logs-button";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ConditionalProtect from "../../../../../components/conditional-protect";
import ReportTableV2 from "../../../../../components/report-table-v2";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import { useAPI, useDrawer, useLogs } from "../../../../../utils/hooks";
import { getProjectTimingDateSchedule } from "../../../../../apis/project/timing";
import {
  cn,
  formatCurrency,
  numberWithDecimalsAndCommas,
  trimString,
} from "../../../../../utils/helpers";
import {
  COST_ITEM_FORM_DEFAULT_STATE,
  COST_METHOD_TYPES,
  COST_START_DATE_TYPES,
  COST_MILESTONE_DATE_TYPES,
  PROJECT_COST_INPUT_TYPE,
  PROJECT_COST_TYPE,
} from "../../../../../constants";
import {
  ICostItemForm,
  ICostItemFormErrors,
  ICostItemResponse,
  ILogsConfiguration,
  IProjectCostItemDataResponse,
  IProjectTimingDateScheduleResults,
  IReportTable,
} from "../../../../../interfaces";
import { useOrganization } from "@clerk/clerk-react";

interface IProps {
  editProjectCostItem: (
    form: ICostItemForm,
    projectUuid: string,
    costId: number,
    itemId: number,
  ) => Promise<ICostItemResponse>;
  deleteProjectCostItem: (
    projectUuid: string,
    costId: number,
    itemId: number,
  ) => Promise<boolean>;
  getProjectCostItem: (
    projectUuid: string,
    costId: number,
    itemId: number,
  ) => Promise<IProjectCostItemDataResponse>;
  getProjectCostItemComponents: (
    projectUuid: string,
    projectCostId: number,
    projectCostItemId: number,
  ) => Promise<IReportTable>;
  downloadProjectCostItemComponents: (
    projectUuid: string,
    projectCostId: number,
    projectCostItemId: number,
  ) => Promise<void>;
}

export default function ProjectCostInputDetailView({
  editProjectCostItem,
  getProjectCostItem,
  deleteProjectCostItem,
  getProjectCostItemComponents,
  downloadProjectCostItemComponents,
}: IProps) {
  const styles = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { projectUuid, costItemId, costId, caseProjectUuid } = useParams();
  const { organization } = useOrganization();
  const projectIdToUse = organization ? caseProjectUuid : projectUuid;

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleOpenDrawer, handleCloseDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [costItem, setCostItem] =
    React.useState<IProjectCostItemDataResponse>();
  const [timingDateSchedule, setTimingDateSchedule] =
    React.useState<string[]>();
  const [projectCostDetailSchedule, setProjectCostDetailSchedule] =
    React.useState<IReportTable>();
  const [openEditCostModal, setOpenEditCostModal] = React.useState(false);
  const [editCostForm, setEditCostForm] = React.useState<ICostItemForm>(
    COST_ITEM_FORM_DEFAULT_STATE,
  );
  const [
    milestoneDatePercentageInputValues,
    setMilestoneDatePercentageInputValues,
  ] = React.useState({});
  const [costPercentageCurveToggle, setCostPercentageCurveToggle] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getCostData(String(projectIdToUse), Number(costId), Number(costItemId));
    getProjectCostDetailScheduleCallAPI(
      String(projectIdToUse),
      Number(costId),
      Number(costItemId),
    ).then((res) => {
      setProjectCostDetailSchedule(res);
    });
  }, [projectIdToUse, costId, costItemId]);

  const getCostData = async (
    projectUuid: string,
    costId: number,
    costItemId: number,
  ) => {
    await Promise.all([
      getProjectCostItemCallAPI(projectUuid, costId, costItemId),
      getProjectTimingDateScheduleCallAPI(projectUuid),
    ]).then((res) => {
      const [cost, timing] = res;
      setCostItem(cost);
      setTimingDateSchedule(timing?.data.date_schedule);
    });
  };

  const { callAPI: getProjectTimingDateScheduleCallAPI } = useAPI<
    IProjectTimingDateScheduleResults,
    ICostItemFormErrors
  >((projectUuid) =>
    getProjectTimingDateSchedule({ period_type: "PRE_COD" }, projectUuid),
  );

  const {
    callAPI: getProjectCostItemCallAPI,
    loading: loadingGetProjectCostItem,
    errored: getProjectCostItemFailed,
  } = useAPI(
    (projectUuid, costId, itemId) =>
      getProjectCostItem(projectUuid, costId, itemId),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: getProjectCostDetailScheduleCallAPI,
    loading: loadingGetProjectCostDetailSchedule,
    errored: getProjectCostDetailScheduleFailed,
  } = useAPI((projectUuid, costId, itemId) =>
    getProjectCostItemComponents(projectUuid, costId, itemId),
  );

  const {
    callAPI: editCostItemCallAPI,
    loading: editCostItemLoading,
    fieldErrors: editCostItemFormErrors,
    setFieldErrors: setEditCostItemFormErrors,
  } = useAPI<ICostItemResponse, ICostItemFormErrors>(
    (form, projectUuid, costId, itemId) =>
      editProjectCostItem(form, projectUuid, costId, itemId),
  );

  const { callAPI: deleteCostItemCallAPI, loading: loadingDeleteCostItem } =
    useAPI(
      (projectUuid, costId, itemId) =>
        deleteProjectCostItem(projectUuid, costId, itemId),
      { setConfirmModalLoading: true },
    );

  const { callAPI: getProjectCostDetailScheduleDownloadCallAPI } = useAPI(
    (projectUuid, costId, itemId) =>
      downloadProjectCostItemComponents(projectUuid, costId, itemId),
  );

  const handleCloseEditCostModal = () => {
    setOpenEditCostModal(false);
  };

  const handleEditCost = async () => {
    const projectTimingDateSchedule = await getProjectTimingDateScheduleCallAPI(
      projectIdToUse,
    ).catch(() => null);

    if (costItem?.data) {
      const {
        cost_input_type,
        cost_method,
        name,
        cost_percentage_curve,
        cost_start_point,
        cost_end_point,
        cost_type,
        cost,
        cost_multiplier,
        milestone_date_percentage,
      } = costItem?.data;
      setEditCostForm({
        cost: cost.toString(),
        cost_multiplier: cost_multiplier.toString(),
        cost_input_type,
        cost_method,
        cost_percentage_curve:
          cost_percentage_curve ||
          new Array(
            projectTimingDateSchedule?.data?.date_schedule.length ?? 0,
          ).fill(0),
        cost_start_point,
        cost_end_point,
        cost_type,
        name,
        milestone_date_percentage: milestone_date_percentage,
      });
      setMilestoneDatePercentageInputValues(milestone_date_percentage);
      setOpenEditCostModal(true);
    }
  };

  const handleDeleteCost = async (itemId: number) => {
    const res = await deleteCostItemCallAPI(projectIdToUse, costId, itemId);
    res &&
      navigate(
        `/project/${projectUuid}/case-project/${caseProjectUuid}/cost-inputs`,
      );
  };

  const handleOnDeleteClick = () => {
    if (costId) {
      dispatch(
        setDeleteModalPropsAction({
          open: true,
          title: "Delete Cost",
          description: "Are you sure you want to delete this cost?",
          onConfirm: () => handleDeleteCost(Number(costItem?.data.id)),
          loading: loadingDeleteCostItem,
        }),
      );
    }
  };

  const onEditConfirm = async (form: ICostItemForm) => {
    const res = await editCostItemCallAPI(
      form,
      projectIdToUse,
      costId,
      costItem?.data?.id,
    );
    res && setCostItem(res);
    getProjectCostDetailScheduleCallAPI(
      String(projectIdToUse),
      Number(costId),
      Number(costItemId),
    ).then((res) => {
      setProjectCostDetailSchedule(res);
    });
    return res;
  };

  const costInputDetailsLogConfiguration: ILogsConfiguration = {
    id: Number(costItemId),
    type: "projectcostitem",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      costInputDetailsLogConfiguration.type,
      costInputDetailsLogConfiguration.id,
    );
  };

  const getItemCost = (): string => {
    switch (costItem?.data.cost_input_type) {
      case "DL":
        return formatCurrency(costItem.data.cost, 6);
      case "DLPWD":
        return numberWithDecimalsAndCommas(costItem.data.cost, 6) + " $/Wdc";
      default:
        return (
          numberWithDecimalsAndCommas(costItem?.data.cost ?? 0, 6) ?? "N/A"
        );
    }
  };

  const handleDownloadComponents = async () => {
    await getProjectCostDetailScheduleDownloadCallAPI(
      projectIdToUse,
      costId,
      costItemId,
    );
  };

  return (
    <ViewWrapper
      loading={loadingGetProjectCostItem}
      error={getProjectCostItemFailed}
    >
      <Box>
        <Box className={cn("flex justify-between items-center mb-4")}>
          <IconButton
            classes={{ root: styles.classes.iconButton }}
            onClick={() => window.history.back()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box className={cn("flex gap-4")}>
            <LogsButton onClick={handleOnOpenLogs} />

            <ConditionalProtect type="project">
              <Button
                canOpenUpgrade
                startIcon={<EditIcon />}
                label="Edit"
                btnType="primary"
                onClick={handleEditCost}
              />
              <Button
                canOpenUpgrade
                label="Delete"
                btnType="danger"
                onClick={handleOnDeleteClick}
              />
            </ConditionalProtect>
          </Box>
        </Box>

        <Box className={cn("grid md:grid-cols-2 gap-4 mb-4")}>
          {costItem && (
            <DetailsCard
              heading="Project Cost Details"
              autoHeight
              sections={[
                {
                  fields: [
                    {
                      label: "Cost Type",
                      value: {
                        text: PROJECT_COST_TYPE[costItem.data.cost_type],
                      },
                    },
                    {
                      label: "Name",
                      value: {
                        text: trimString(costItem.data.name, 40),
                      },
                    },
                    {
                      label: "Cost Input Type",
                      value: {
                        text: PROJECT_COST_INPUT_TYPE[
                          costItem.data.cost_input_type
                        ],
                      },
                    },
                    {
                      label: "Cost",
                      value: {
                        text: getItemCost(),
                      },
                    },
                    {
                      label: "Spend Method",
                      value: {
                        text: COST_METHOD_TYPES[costItem.data.cost_method],
                      },
                    },
                    // When a schedule is chosen a "start date type and end date type" is not needed/set since we get a schedule from BOL > COD from the user
                    ...(costItem.data.cost_method === "LNR"
                      ? [
                          {
                            label: "Start Point",
                            value: {
                              text: COST_START_DATE_TYPES[
                                costItem.data.cost_start_point
                              ],
                            },
                          },
                          {
                            label: "End Point",
                            value: {
                              text: COST_START_DATE_TYPES[
                                costItem.data.cost_end_point
                              ],
                            },
                          },
                        ]
                      : []),
                    ...(costItem.data.cost_method === "MD"
                      ? [
                          {
                            label: "Milestone Dates",
                            value: {
                              text: Object.entries(
                                costItem.data.milestone_date_percentage || {},
                              )
                                .map(([key, value]) => `${key}: ${value}%`)
                                .join(", "),
                            },
                          },
                        ]
                      : []),
                    {
                      label: "Cost Multiplier",
                      value: {
                        text:
                          numberWithDecimalsAndCommas(
                            costItem.data.cost_multiplier,
                          ) + "%",
                      },
                    },
                    ...(costItem.data.cost_method === "SCD"
                      ? [
                          {
                            label: (
                              <CurveFieldLabel
                                label="Cost Schedule"
                                primaryAction={setCostPercentageCurveToggle}
                                value={costItem.data.cost_percentage_curve}
                                toggled={costPercentageCurveToggle}
                              />
                            ),
                            value: {
                              text: (
                                <DateSchedule
                                  curve={
                                    costItem.data.cost_percentage_curve.map(
                                      Number,
                                    ) || []
                                  }
                                  dateSchedule={timingDateSchedule || []}
                                  toggle={costPercentageCurveToggle}
                                  handleToggle={setCostPercentageCurveToggle}
                                />
                              ),
                            },
                          },
                        ]
                      : []),
                  ],
                },
              ]}
            />
          )}
        </Box>

        <ViewWrapper
          loading={loadingGetProjectCostDetailSchedule}
          error={getProjectCostDetailScheduleFailed}
        >
          {projectCostDetailSchedule && (
            <ReportTableV2
              data={projectCostDetailSchedule}
              groupExpandedDepth={-1}
              download={handleDownloadComponents}
            />
          )}
        </ViewWrapper>

        <CostItemFormModal
          dateSchedule={timingDateSchedule || []}
          form={editCostForm}
          headerLabel="Edit Project Cost"
          loading={editCostItemLoading}
          onClose={handleCloseEditCostModal}
          onConfirm={onEditConfirm}
          open={openEditCostModal}
          setForm={setEditCostForm}
          setFormErrors={setEditCostItemFormErrors}
          formErrors={editCostItemFormErrors}
          setMilestoneDatePercentageInputValues={
            setMilestoneDatePercentageInputValues
          }
          milestoneDatePercentageInputValues={
            milestoneDatePercentageInputValues as Record<
              keyof typeof COST_MILESTONE_DATE_TYPES,
              number
            >
          }
        />
      </Box>

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={costInputDetailsLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={costInputDetailsLogConfiguration.id}
        />
      </LogsWrapper>
    </ViewWrapper>
  );
}
