import SignInPage from "../../pages/sign-in"; // sign-in

import NotFound404 from "../../pages/not-fount-404"; // 404

import DashboardPage from "../../pages/home/dashboard";
import RecentPage from "../../pages/home/recent";
import ProjectListPage from "../../pages/project-list";
import DealListPage from "../../pages/deal-list";
import CozePage from "../../pages/co-ze";
import ProjectMapPage from "../../pages/home/project-map";
// configuration pages
import ConfigurationDataCurvesPage from "../../pages/configuration/data/curves";
import ConfigurationOrganizationProfilePage from "../../pages/configuration/organization/profile";
import ConfigurationOrganizationUsersPage from "../../pages/configuration/organization/users";
import ConfigurationOrganizationBillingAndUsagePage from "../../pages/configuration/organization/billing-and-usage";
import ConfigurationDataCounterpartiesPage from "../../pages/configuration/data/counterparties";

// collaboration pages
import CollaborationListPage from "../../pages/collaboration/list";
import CollaborationGeneralPage from "../../pages/collaboration/general";
import CollaborationCasesPage from "../../pages/collaboration/cases";
import CollaborationAccessPage from "../../pages/collaboration/access";

// deal cases pages
import DealCasesPage from "../../pages/deal/cases";
import DealCaseDetailsPage from "../../pages/deal/cases/details";

// deal output pages
import DealOutput from "../../pages/deal/output";

import DealOutputTransfer from "../../pages/deal/output/transfer";
import DealOutputTransferSummary from "../../pages/deal/output/transfer/summary";
import DealOutputTransferCash from "../../pages/deal/output/transfer/cash";

import DealOutputDebt from "../../pages/deal/output/debt";
import DealOutputDebtFacility from "../../pages/deal/output/debt/facility";
import DealOutputDebtCoverage from "../../pages/deal/output/debt/coverage";
import DealOutputDebtSummary from "../../pages/deal/output/debt/summary";
import DealOutputDebtCharts from "../../pages/deal/output/debt/charts";
import DealOutputDebtServiceCoverage from "../../pages/deal/output/debt/service-coverage";

import DealOutputPartnership from "../../pages/deal/output/partnership";
import DealOutputPartnershipCash from "../../pages/deal/output/partnership/cash";
import DealOutputPartnershipTax from "../../pages/deal/output/partnership/tax";
import DealOutputPartnershipSummary from "../../pages/deal/output/partnership/summary";
import DealOutputPartnershipSplits from "../../pages/deal/output/partnership/splits";

import DealOutputSponsorEquity from "../../pages/deal/output/sponsor-equity";
import DealOutputSponsorEquityCash from "../../pages/deal/output/sponsor-equity/cash";
import DealOutputSponsorEquitySummary from "../../pages/deal/output/sponsor-equity/summary";
import DealOutputSponsorEquityTax from "../../pages/deal/output/sponsor-equity/tax";
import DealOutputSponsorEquityAccounting from "../../pages/deal/output/sponsor-equity/accounting";
import DealOutputSponsorEquityCapitalAccounts from "../../pages/deal/output/sponsor-equity/capital-accounts";
import DealOutputSponsorEquityOutsideBasis from "../../pages/deal/output/sponsor-equity/outside-basis";
import DealOutputSponsorCharts from "../../pages/deal/output/sponsor-equity/charts";

import DealOutputSponsorEquityBOL_Page from "../../pages/deal/output/sponsor-equity-BOL";
import DealOutputSponsorEquityBOL_SummaryPage from "../../pages/deal/output/sponsor-equity-BOL/summary";
import DealOutputSponsorEquityBOL_CashPage from "../../pages/deal/output/sponsor-equity-BOL/cash";
import DealOutputSponsorEquityBOL_TaxPage from "../../pages/deal/output/sponsor-equity-BOL/tax";

import DealOutputDeveloperPage from "../../pages/deal/output/developer";
import DealOutputDeveloperSummaryPage from "../../pages/deal/output/developer/summary";
import DealOutputDeveloperCashPage from "../../pages/deal/output/developer/cash";
import DealOutputDeveloperTaxPage from "../../pages/deal/output/developer/tax";

import DealOutputTaxEquity from "../../pages/deal/output/tax-equity";
import DealOutputTaxEquityCash from "../../pages/deal/output/tax-equity/cash";
import DealOutputTaxEquitySummary from "../../pages/deal/output/tax-equity/summary";
import DealOutputTaxEquityTax from "../../pages/deal/output/tax-equity/tax";
import DealOutputTaxEquityCapitalAccounts from "../../pages/deal/output/tax-equity/capital-accounts";
import DealOutputTaxEquityOutsideBasis from "../../pages/deal/output/tax-equity/outside-basis";
import DealOutputTaxEquityAccounting from "../../pages/deal/output/tax-equity/accounting";
import DealOutputTaxCharts from "../../pages/deal/output/tax-equity/charts";

import DealOutputLeasePage from "../../pages/deal/output/lease";
import DealOutputLeaseSummaryPage from "../../pages/deal/output/lease/summary";
import DealOutputLessorPage from "../../pages/deal/output/lessor";
import DealOutputLessee from "../../pages/deal/output/lessee";
import DealOutputLessorSummary from "../../pages/deal/output/lessor/summary";
import DealOutputLessorCash from "../../pages/deal/output/lessor/cash";
import DealOutputLessorTax from "../../pages/deal/output/lessor/tax";
import DealOutputLesseeSummary from "../../pages/deal/output/lessee/summary";
import DealOutputLesseeCash from "../../pages/deal/output/lessee/cash";
import DealOutputLesseeTax from "../../pages/deal/output/lessee/tax";

import DealOutputConstructionDebtCashPage from "../../pages/deal/output/construction-debt/cash";
import DealOutputConstructionDebtChartPage from "../../pages/deal/output/construction-debt/chart";
import DealOutputConstructionDebtConstructionLoanPage from "../../pages/deal/output/construction-debt/construction-loan";
import DealOutputConstructionDebtEquityPage from "../../pages/deal/output/construction-debt/equity";
import DealOutputConstructionDebtInvestorBridgeLoanPage from "../../pages/deal/output/construction-debt/investor-bridge-loan";
import DealOutputConstructionDebtPage from "../../pages/deal/output/construction-debt";
import DealOutputConstructionDebtSummaryPage from "../../pages/deal/output/construction-debt/summary";
import DealOutputConstructionDebtTransferBridgeLoanPage from "../../pages/deal/output/construction-debt/transfer-bridge-loan";

import DealOutputPortfolioPage from "../../pages/deal/output/portfolio";
import DealOutputPortfolioSummaryPage from "../../pages/deal/output/portfolio/summary";
import DealOutputPortfolioSourcesAndUsesPage from "../../pages/deal/output/portfolio/sources-and-uses";

// deal analysis pages
import DealAnalysisPage from "../../pages/deal/analysis";
import DealCaseComparisonDetailsPage from "../../pages/deal/analysis/details";

// deal solvers pages
import SolversPage from "../../pages/solvers";
import SolversDetailPage from "../../pages/solvers/detail";

// deal general pages
import DealGeneralPage from "../../pages/deal/general";

// deal timing pages
import DealTimingPage from "../../pages/deal/timing";

// deal components pages
import DealComponentCostSummaryPage from "../../pages/deal/components/cost/summary";
import DealComponentCostSchedulePage from "../../pages/deal/components/cost/schedule";
import DealProformaSummaryPage from "../../pages/deal/components/pro-forma/summary";
import DealProformaSchedulePage from "../../pages/deal/components/pro-forma/schedule";
import DealTaxSummaryPage from "../../pages/deal/components/tax/summary";
import DealTaxSchedulePage from "../../pages/deal/components/tax/schedule";
import DealFMV_Page from "../../pages/deal/components/fmv";

//  deal sizing pages
import DealSizingPage from "../../pages/deal/sizing/index";
import DealSizingTermDebtPage from "../../pages/deal/sizing/term-debt";
import DealSizingConstructionDebtPage from "../../pages/deal/sizing/construction-debt";
import DealSizingEquityPage from "../../pages/deal/sizing/cash-equity";
import DealSizingDirectPayPage from "../../pages/deal/sizing/direct-pay";
import DealSizingSponsorEquityPage from "../../pages/deal/sizing/sponsor-equity";
import DealSizingTaxEquityPage from "../../pages/deal/sizing/tax-equity";
import DealSizingTransferPage from "../../pages/deal/sizing/transfer";
import DealSizingSaleLeasebackPage from "../../pages/deal/sizing/sale-leaseback";
import DealFeePage from "../../pages/deal/sizing/deal-fee/table";
import DealFeeDetails from "../../pages/deal/sizing/deal-fee/details";
import DealSizingOtherEbitdaPage from "../../pages/deal/sizing/other/ebitda";

// deal comparison pages
import DealComparisonListPage from "../../pages/analysis/deal-comparison-list";
import DealComparisonDetailPage from "../../pages/analysis/deal-comparison-detail";

// project pages
import ProjectCostPage from "../../pages/project/cost/dashboard";
import ProjectCostCreditSupportPage from "../../pages/project/cost/credit-support/list";
import ProjectCostCreditSupportDetailPage from "../../pages/project/cost/credit-support/details";
import ProjectCostInputsPage from "../../pages/project/cost/cost-inputs";
import ProjectCostInputDetailPage from "../../pages/project/cost/cost-inputs/detail";

import ProjectTaxDashboardPage from "../../pages/project/tax/dashboard";
import ProjectTaxCreditPage from "../../pages/project/tax/tax-credit";
import ProjectDepreciationListPage from "../../pages/project/tax/depreciation/list";
import ProjectDepreciationDetailsPage from "../../pages/project/tax/depreciation/details";
import ProjectExpenseTablePage from "../../pages/project/pro-forma/expenses/table";
import ProjectExpenseDetailsPage from "../../pages/project/pro-forma/expenses/details";
import ProjectGeneralPage from "../../pages/project/general";
import ProjectOtherFlowPage from "../../pages/project/pro-forma/other-flows/list";
import ProjectOtherFlowDetailPage from "../../pages/project/pro-forma/other-flows/details";
import ProjectProductionPage from "../../pages/project/pro-forma/production";
import ProjectRevenueListPage from "../../pages/project/pro-forma/revenue/revenue-contract/list";
import ProjectRevenueDetailsPage from "../../pages/project/pro-forma/revenue/revenue-contract/details";
import ProjectMerchantRevenueListPage from "../../pages/project/pro-forma/revenue/merchant-revenue/list";
import ProjectMerchantRevenueDetailsPage from "../../pages/project/pro-forma/revenue/merchant-revenue/merchant-revenue-details";
import ProjectFmvDashboardPage from "../../pages/project/fmv/dashboard";
import ProjectFmvStepUpPage from "../../pages/project/fmv/step-up";
import ProjectFmvIncomeApproachPage from "../../pages/project/fmv/income-approach";
import ProjectTimingPage from "../../pages/project/timing";
import ProjectProFormaDashboardPage from "../../pages/project/pro-forma/dashboard";
import ProjectCaseListPage from "../../pages/project/case/list";

// personal account pages
import PersonalAccountSharedDealsPage from "../../pages/personal-account/shared-deal-list";
import PersonalAccountSharedDealAnalysisPage from "../../pages/personal-account/shared-deal/analysis";

import { generatePagesRoutesForPersonalAccount } from "../helpers";
import DealSizingOtherViewPage from "../../pages/deal/sizing/other";
import DealDepreciationViewPage from "../../pages/deal/sizing/other/depreciation";

const projectPagesRoutes = [
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/general",
    element: ProjectGeneralPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/timing",
    element: ProjectTimingPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/cost",
    element: ProjectCostPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/cost-credit-support",
    element: ProjectCostCreditSupportPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/cost-credit-support/:creditSupportId",
    element: ProjectCostCreditSupportDetailPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/cost-inputs",
    element: ProjectCostInputsPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/cost-inputs/:costId/:costItemId",
    element: ProjectCostInputDetailPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma",
    element: ProjectProFormaDashboardPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/production",
    element: ProjectProductionPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/revenue",
    element: ProjectRevenueListPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/revenue/:revenueId",
    element: ProjectRevenueDetailsPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/revenue/merchant",
    element: ProjectMerchantRevenueListPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/revenue/merchant/:revenueId",
    element: ProjectMerchantRevenueDetailsPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/expenses",
    element: ProjectExpenseTablePage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/expenses/:expenseId",
    element: ProjectExpenseDetailsPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/other-flows",
    element: ProjectOtherFlowPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/pro-forma/other-flows/:otherFlowId",
    element: ProjectOtherFlowDetailPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/tax/dashboard",
    element: ProjectTaxDashboardPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/tax/depreciation",
    element: ProjectDepreciationListPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/tax/depreciation/:depreciationId/stream/:streamId",
    element: ProjectDepreciationDetailsPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/tax/tax-credit",
    element: ProjectTaxCreditPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/fmv/dashboard",
    element: ProjectFmvDashboardPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/fmv/step-up",
    element: ProjectFmvStepUpPage,
  },
  {
    path: "/project/:projectUuid/case-project/:caseProjectUuid/fmv/income-approach",
    element: ProjectFmvIncomeApproachPage,
  },
  {
    path: "/project/:projectUuid/cases",
    element: ProjectCaseListPage,
  },
];

const dealPagesRoutes = [
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/general",
    element: DealGeneralPage,
  },

  // deal timing pages
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/timing",
    element: DealTimingPage,
  },

  // deal components pages
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/cost/summary",
    element: DealComponentCostSummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/cost/schedule",
    element: DealComponentCostSchedulePage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/pro-forma/summary",
    element: DealProformaSummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/pro-forma/schedule",
    element: DealProformaSchedulePage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/tax/summary",
    element: DealTaxSummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/tax/schedule",
    element: DealTaxSchedulePage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/component/fmv",
    element: DealFMV_Page,
  },

  // deal sizing pages
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing",
    element: DealSizingPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/tax-equity",
    element: DealSizingTaxEquityPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/transfer",
    element: DealSizingTransferPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/term-debt",
    element: DealSizingTermDebtPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/construction-debt",
    element: DealSizingConstructionDebtPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/cash-equity",
    element: DealSizingEquityPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/sale-leaseback",
    element: DealSizingSaleLeasebackPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other",
    element: DealSizingOtherViewPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other/ebitda",
    element: DealSizingOtherEbitdaPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/other/depreciation",
    element: DealDepreciationViewPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/deal-fee",
    element: DealFeePage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/deal-fee/:dealFeeId",
    element: DealFeeDetails,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/direct-pay",
    element: DealSizingDirectPayPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/sizing/sponsor-equity",
    element: DealSizingSponsorEquityPage,
  },

  // deal output pages
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output",
    element: DealOutput,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/transfer",
    element: DealOutputTransfer,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/transfer/cash",
    element: DealOutputTransferCash,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/transfer/summary",
    element: DealOutputTransferSummary,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/term-debt",
    element: DealOutputDebt,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/term-debt/facility",
    element: DealOutputDebtFacility,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/term-debt/coverage",
    element: DealOutputDebtCoverage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/term-debt/summary",
    element: DealOutputDebtSummary,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/term-debt/charts",
    element: DealOutputDebtCharts,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/term-debt/service-coverage",
    element: DealOutputDebtServiceCoverage,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership",
    element: DealOutputPartnership,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership/summary",
    element: DealOutputPartnershipSummary,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership/cash",
    element: DealOutputPartnershipCash,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership/tax",
    element: DealOutputPartnershipTax,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/partnership/splits",
    element: DealOutputPartnershipSplits,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity",
    element: DealOutputSponsorEquity,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity/cash",
    element: DealOutputSponsorEquityCash,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity/summary",
    element: DealOutputSponsorEquitySummary,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity/tax",
    element: DealOutputSponsorEquityTax,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity/capital-accounts",
    element: DealOutputSponsorEquityCapitalAccounts,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity/outside-basis",
    element: DealOutputSponsorEquityOutsideBasis,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity/accounting",
    element: DealOutputSponsorEquityAccounting,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity/charts",
    element: DealOutputSponsorCharts,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity-bol",
    element: DealOutputSponsorEquityBOL_Page,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity-bol/summary",
    element: DealOutputSponsorEquityBOL_SummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity-bol/cash",
    element: DealOutputSponsorEquityBOL_CashPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/sponsor-equity-bol/tax",
    element: DealOutputSponsorEquityBOL_TaxPage,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/developer",
    element: DealOutputDeveloperPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/developer/summary",
    element: DealOutputDeveloperSummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/developer/cash",
    element: DealOutputDeveloperCashPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/developer/tax",
    element: DealOutputDeveloperTaxPage,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity",
    element: DealOutputTaxEquity,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity/cash",
    element: DealOutputTaxEquityCash,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity/summary",
    element: DealOutputTaxEquitySummary,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity/tax",
    element: DealOutputTaxEquityTax,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity/capital-accounts",
    element: DealOutputTaxEquityCapitalAccounts,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity/outside-basis",
    element: DealOutputTaxEquityOutsideBasis,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity/accounting",
    element: DealOutputTaxEquityAccounting,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/tax-equity/charts",
    element: DealOutputTaxCharts,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lease",
    element: DealOutputLeasePage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lease/summary",
    element: DealOutputLeaseSummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessor",
    element: DealOutputLessorPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessor/summary",
    element: DealOutputLessorSummary,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessor/cash",
    element: DealOutputLessorCash,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessor/tax",
    element: DealOutputLessorTax,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessee",
    element: DealOutputLessee,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessee/summary",
    element: DealOutputLesseeSummary,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessee/cash",
    element: DealOutputLesseeCash,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/lessee/tax",
    element: DealOutputLesseeTax,
  },

  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt",
    element: DealOutputConstructionDebtPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt/summary",
    element: DealOutputConstructionDebtSummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt/cash",
    element: DealOutputConstructionDebtCashPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt/equity",
    element: DealOutputConstructionDebtEquityPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt/construction-loan",
    element: DealOutputConstructionDebtConstructionLoanPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt/investor-bridge-loan",
    element: DealOutputConstructionDebtInvestorBridgeLoanPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt/transfer-bridge-loan",
    element: DealOutputConstructionDebtTransferBridgeLoanPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/construction-debt/charts",
    element: DealOutputConstructionDebtChartPage,
  },

  // portfolio pages
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/portfolio",
    element: DealOutputPortfolioPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/portfolio/summary",
    element: DealOutputPortfolioSummaryPage,
  },
  {
    path: "/deal/:dealUuid/case-deal/:caseDealUuid/output/portfolio/sources-and-uses",
    element: DealOutputPortfolioSourcesAndUsesPage,
  },

  // deal cases pages
  { path: "/deal/:dealUuid/cases", element: DealCasesPage },
  { path: "/deal/:dealUuid/case/:caseId", element: DealCaseDetailsPage },

  // deal analysis pages
  { path: "/deal/:dealUuid/analysis", element: DealAnalysisPage },
  {
    path: "/deal/:dealUuid/analysis/:uuid",
    element: DealCaseComparisonDetailsPage,
  },
];

export const privateRoutes = [
  { path: "/sign-in", element: SignInPage }, // sign-in
  { path: "*", element: NotFound404 }, // 404
  // deal comparison pages
  { path: "/analysis/deal-comparison-list", element: DealComparisonListPage },
  {
    path: "/analysis/deal-comparison/:uuid",
    element: DealComparisonDetailPage,
  },

  { path: "/", element: DashboardPage },
  { path: "/recent", element: RecentPage },
  { path: "/project-map", element: ProjectMapPage },
  { path: "/deal-list", element: DealListPage },
  { path: "/project-list", element: ProjectListPage },
  { path: "/coze", element: CozePage },

  // configuration pages
  {
    path: "/configuration/data/curves",
    element: ConfigurationDataCurvesPage,
  },
  {
    path: "/configuration/data/counterparties",
    element: ConfigurationDataCounterpartiesPage,
  },
  {
    path: "/configuration/data/curves/:curveGroupId/:curveUuid",
    element: ConfigurationDataCurvesPage,
  },
  {
    path: "/configuration/organization/profile",
    element: ConfigurationOrganizationProfilePage,
  },
  {
    path: "/configuration/organization/users",
    element: ConfigurationOrganizationUsersPage,
  },
  {
    path: "/configuration/organization/billing-and-usage",
    element: ConfigurationOrganizationBillingAndUsagePage,
  },

  // collaboration pages
  {
    path: "/collaboration",
    element: CollaborationListPage,
  },
  {
    path: "/collaboration/:collaborationUuid/general",
    element: CollaborationGeneralPage,
  },
  {
    path: "/collaboration/:collaborationUuid/cases",
    element: CollaborationCasesPage,
  },
  {
    path: "/collaboration/:collaborationUuid/access",
    element: CollaborationAccessPage,
  },

  // deal general pages
  ...dealPagesRoutes,

  // deal comparison pages
  { path: "/analysis/deal-comparison-list", element: DealComparisonListPage },
  { path: "/analysis/deal-comparison/:id", element: DealComparisonDetailPage },

  // project pages
  ...projectPagesRoutes,

  {
    path: "/personal/shared-deals",
    element: PersonalAccountSharedDealsPage,
  },
  {
    path: "/personal/shared-deal/:collaborationUuid/general",
    element: CollaborationGeneralPage,
  },
  {
    path: "/personal/shared-deal/:collaborationUuid/cases",
    element: CollaborationCasesPage,
  },
  {
    path: "/personal/shared-deal/:collaborationUuid/analysis",
    element: PersonalAccountSharedDealAnalysisPage,
  },

  // solvers pages
  { path: "/solvers", element: SolversPage },
  { path: "/solvers/:uuid", element: SolversDetailPage },

  // case pages for personal account
  ...generatePagesRoutesForPersonalAccount(dealPagesRoutes, "deal"),

  // project Pages for personal account
  ...generatePagesRoutesForPersonalAccount(projectPagesRoutes, "project"),
];
