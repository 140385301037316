import React from "react";
import AddIcon from "@mui/icons-material/Add";
import MuiButton from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { formatDistanceToNow } from "date-fns";

import useStyles from "./styles";
import IconButton from "../../../components/icon-button";
import ViewWrapper from "../../../components/view-wrapper";
import Button from "../../../components/button";
import Searchbar from "../../../components/search-bar";
import AddCaseFormModal from "../../../components/add-case-form-modal";
import DealCaseDuplicateFormModal from "../../../components/deal-case-duplicate-form-modal";
import UpdateCaseFormModal from "../../../components/update-case-form-modal";
import PopoverMenu from "../../../components/popover-menu";
import StickyTableCell from "../../../components/sticky-table-cell";
import ConditionalProtect from "../../../components/conditional-protect";
import { getBulkProjectCases } from "../../../apis/deal/case";
import { useAPI, useAppSelector, useSearchBar } from "../../../utils/hooks";
import {
  getTableColumnAccordingToStatus,
  sortArrayOfObjects,
  trimString,
} from "../../../utils/helpers";
import {
  setCasesOfDealAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  DEAL_CASE_TYPE,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_TYPE,
  DUPLICATE_CASE_FORM_DEFAULT_STATE,
  UPDATE_CASE_FORM_DEFAULT_STATE,
} from "../../../constants";
import {
  IAddDealCaseForm,
  IAddDealCaseFormErrors,
  IDealCase,
  IDuplicateDealCaseForm,
  IDuplicateDealCaseFormErrors,
  IUpdateDealCaseForm,
  IUpdateDealCaseFormErrors,
  ITableColumn,
  ITableSort,
  ServerPaginatedResponse,
  ISelectOption,
  IProjectCase,
} from "../../../interfaces";

interface IProps {
  getDealCases: (
    dealUuid: string,
  ) => Promise<ServerPaginatedResponse<IDealCase[]>>;
  addCase: (dealUuid: string, form: IAddDealCaseForm) => Promise<IDealCase>;
  duplicateDealCase: (
    dealUuid: string,
    caseId: string,
    form: IDuplicateDealCaseForm,
  ) => Promise<IDealCase>;
  deleteDealCase: (dealUuid: string, caseId: string) => Promise<boolean>;
  updateDealCase: (
    dealUuid: string,
    caseId: string,
    form: IUpdateDealCaseForm,
  ) => Promise<IDealCase>;
}

const columns: ITableColumn[] = [
  { align: "left", id: "name", label: "Case Name", minWidth: 200 },
  { align: "left", id: "type", label: "Type", minWidth: 200 },
  {
    align: "left",
    id: "child_deal.structure",
    label: "Structure",
    minWidth: 200,
  },
  {
    align: "left",
    id: "child_deal.tax_credit_structure",
    label: "Tax Credit Structure",
    minWidth: 200,
  },
  {
    id: "child_deal.tax_credit_type",
    label: "Tax Credit Type",
    minWidth: 200,
    align: "left",
  },
  { align: "left", id: "modified", label: "Modified", minWidth: 100 },
];

export default function DealCaseView({
  getDealCases,
  addCase,
  duplicateDealCase,
  deleteDealCase,
  updateDealCase,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealUuid } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentDeal, casesOfDeal } = useAppSelector((s) => s.deal);
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { searchString, onSearchStringChange, handleClearSearchString } =
    useSearchBar();

  const [expandedRows, setExpandedRows] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [addCaseModalOpen, setAddCaseModalOpen] =
    React.useState<boolean>(false);
  const [duplicateCaseModalOpen, setDuplicateCaseModalOpen] =
    React.useState<boolean>(false);
  const [editCaseModalOpen, setEditCaseModalOpen] =
    React.useState<boolean>(false);
  const [addCaseForm, setAddCaseForm] = React.useState<IAddDealCaseForm>({
    name: "",
    type: "",
    description: "",
    is_synced_with_base_case: false,
    project_tax_credit_type: "",
    deal_structure: "",
    deal_tax_credit_structure: "",
    project_cases: [],
    parent_scenario_uuid: null,
  });
  const [duplicateCaseForm, setDuplicateCaseForm] =
    React.useState<IDuplicateDealCaseForm>(DUPLICATE_CASE_FORM_DEFAULT_STATE);
  const [editDealCaseForm, setEditDealCaseForm] =
    React.useState<IUpdateDealCaseForm>(UPDATE_CASE_FORM_DEFAULT_STATE);
  const [dealCases, setDealCases] = React.useState<IDealCase[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const handleExpandRow = (uuid: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [uuid]: !prev[uuid],
    }));
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = React.useMemo(() => {
    return dealCases
      .filter((d) => d.type === "SCEN")
      .filter((d) => d.name.toLowerCase().includes(searchString.toLowerCase()));
  }, [dealCases, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleOpenAddCaseModal = async (parent_scenario_uuid?: string) => {
    const baseCaseUuids = Object.values(projectBulkCases?.data ?? {})
      ?.map((cases) => {
        const baseCase = cases.find((c) => c.is_base_case_scenario);
        return baseCase?.uuid;
      })
      .filter((uuid) => uuid !== undefined) as string[];

    setAddCaseForm({
      name: "",
      type: parent_scenario_uuid ? "SENS" : "SCEN",
      description: "",
      is_synced_with_base_case: Boolean(parent_scenario_uuid),
      project_tax_credit_type: "NC",
      deal_structure:
        currentDeal?.structure as keyof typeof DEAL_STRUCTURE_TYPE,
      deal_tax_credit_structure:
        currentDeal?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE,
      project_cases: baseCaseUuids,
      parent_scenario_uuid: parent_scenario_uuid || null,
    });
    setAddCaseModalOpen(true);
  };

  const handleCloseAddCaseModal = () => {
    setAddCaseModalOpen(false);
  };

  const handleOpenDuplicateModal = (caseId: string) => {
    setDuplicateCaseModalOpen(true);
    setDuplicateCaseForm((prev) => ({ ...prev, uuid: caseId }));
  };

  const handleCloseDuplicateModal = () => {
    setDuplicateCaseModalOpen(false);
  };

  const handleOnDelete = (uuid: string | undefined) => {
    if (uuid === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Case",
        description: "Are you sure you want to delete Case?",
        onConfirm: () => handleDeleteCase(uuid),
      }),
    );
  };

  const handleOpenEditModal = (uuid: string) => {
    setEditCaseModalOpen(true);
    const dealCase = dealCases.find((dc) => dc.uuid === uuid);
    if (dealCase) {
      setEditDealCaseForm({
        uuid: uuid,
        name: dealCase.name,
        description: dealCase.description,
      });
    }
  };

  const handleCloseEditModal = () => {
    setEditCaseModalOpen(false);
  };

  const {
    callAPI: getDealCasesCallAPI,
    loading: casesLoading,
    errored: casesLoadingFailed,
  } = useAPI((dealUuid) => getDealCases(String(dealUuid)), {
    initialLoading: true,
  });

  const { callAPI: getBulkProjectCasesCallAPI, response: projectBulkCases } =
    useAPI((dealUuid, form) => getBulkProjectCases(String(dealUuid), form));

  const {
    callAPI: addCaseCallAPI,
    fieldErrors: addCaseFormErrors,
    setFieldErrors: setAddCaseFormErrors,
    loading: addCaseLoading,
  } = useAPI<IDealCase, IAddDealCaseFormErrors>((form: IAddDealCaseForm) =>
    addCase(String(dealUuid), form),
  );

  const {
    callAPI: duplicateCaseCallAPI,
    fieldErrors: duplicateCaseFormErrors,
    setFieldErrors: setDuplicateCaseFormErrors,
    loading: duplicateCaseLoading,
  } = useAPI<IDealCase, IDuplicateDealCaseFormErrors>(
    (caseId: string, form: IDuplicateDealCaseForm) =>
      duplicateDealCase(String(dealUuid), caseId, form),
  );

  const { callAPI: deleteCaseCallAPI } = useAPI(
    (caseId: string) => deleteDealCase(String(dealUuid), caseId),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: editDealCaseCallAPI,
    fieldErrors: editDealCaseFormErrors,
    setFieldErrors: setEditDealCaseFormErrors,
    loading: editDealCaseLoading,
  } = useAPI<IDealCase, IUpdateDealCaseFormErrors>(
    (caseId: string, form: IUpdateDealCaseForm) =>
      updateDealCase(String(dealUuid), caseId, form),
  );

  const handleAddCase = async (form: IAddDealCaseForm) => {
    const dealCase = await addCaseCallAPI(form);
    if (dealCase) {
      setDealCases((prevCases) => [...prevCases, dealCase]);
      refetchCasesOfDeal();
      goToCase(dealCase.uuid);
    }
    return dealCase;
  };

  const handleDuplicateCase = async (form: IDuplicateDealCaseForm) => {
    const { uuid: caseId } = form;
    const dealCase = await duplicateCaseCallAPI(caseId, form);
    dealCase && setDealCases((prevCases) => [...prevCases, dealCase]);
    dealCase && refetchCasesOfDeal();
    dealCase && goToCase(dealCase?.uuid);
    return dealCase;
  };

  const handleDeleteCase = async (uuid: string) => {
    const response = await deleteCaseCallAPI(uuid);

    if (response) {
      setDealCases((dealCases) => dealCases.filter((d) => d.uuid !== uuid));
      setCasesOfDealAction(casesOfDeal.filter((d) => d.uuid !== uuid));
    }
  };

  const handleEditCase = async (form: IUpdateDealCaseForm) => {
    const { uuid: caseUuid } = form;
    const dealCase = await editDealCaseCallAPI(caseUuid, form);
    if (dealCase) {
      setDealCases((prev) => [
        ...prev.map((d) => {
          if (d.uuid === caseUuid) {
            return {
              ...d,
              name: dealCase?.name,
              description: dealCase?.description,
            };
          }
          return d;
        }),
      ]);
      refetchCasesOfDeal();
    }
    return dealCase;
  };

  const goToCase = (uuid: string) => {
    if (ctrlPressed) {
      window.open(`/deal/${dealUuid}/case/${uuid}`);
      return;
    }
    navigate(`/deal/${dealUuid}/case/${uuid}`);
  };

  const refetchCasesOfDeal = async () => {
    const dealCases = await getDealCasesCallAPI(String(dealUuid));
    dealCases && dispatch(setCasesOfDealAction(dealCases.results));
  };

  React.useEffect(() => {
    getDealCasesCallAPI(String(dealUuid)).then((res) => {
      res && setDealCases(res.results);
    });
    if (currentDeal?.projects) {
      getBulkProjectCasesCallAPI(dealUuid, {
        project_uuids: currentDeal?.projects.map((p) => p.uuid),
      });
    }
  }, [dealUuid]);

  const scenarioOptions: ISelectOption[][] = React.useMemo(() => {
    if (!projectBulkCases) return [];
    return Object.entries(projectBulkCases.data).map(([projectUuid, cases]) =>
      cases
        .filter((c) => c.type === "SCEN")
        .map((c) => ({
          label: c.name,
          value: c.uuid,
        })),
    );
  }, [projectBulkCases]);

  const projectCases: IProjectCase[][] = React.useMemo(() => {
    if (!projectBulkCases) return [];
    return Object.entries(projectBulkCases.data).map(
      ([projectUuid, cases]) => cases,
    );
  }, [projectBulkCases]);

  const renderItsSensitivities = (dCase: IDealCase) => {
    if (!expandedRows[dCase.uuid]) return null;

    return (
      <React.Fragment>
        {dealCases
          .filter((c) => c.parent_scenario_uuid === dCase.uuid)
          .map((c, idx) => (
            <TableRow
              hover
              key={idx}
              tabIndex={-1}
              className={styles.classes.dataRow}
              onClick={() => goToCase(c.uuid)}
            >
              <TableCell align="left" className="!pl-11">
                {trimString(c.name, 40)}
              </TableCell>
              <TableCell align="left">
                {DEAL_CASE_TYPE[c?.type as keyof typeof DEAL_CASE_TYPE]}
              </TableCell>
              <TableCell align="left">
                {
                  DEAL_STRUCTURE_TYPE[
                    c?.child_deal?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                  ]
                }
              </TableCell>
              <TableCell align="left">
                {
                  DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                    c?.child_deal
                      ?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                  ]
                }
              </TableCell>
              <TableCell align="left">
                {
                  DEAL_TAX_CREDIT_TYPE[
                    c?.child_deal
                      ?.tax_credit_type as keyof typeof DEAL_TAX_CREDIT_TYPE
                  ]
                }
              </TableCell>
              <TableCell>
                {formatDistanceToNow(new Date(c?.modified), {
                  addSuffix: true,
                })}
              </TableCell>
              <ConditionalProtect type="deal">
                <StickyTableCell
                  direction="right"
                  fixedColumnWidth={100}
                  align="center"
                >
                  <PopoverMenu
                    uniqueId={idx}
                    canOpenUpgrade
                    items={[
                      {
                        label: "Duplicate",
                        onClick: () => handleOpenDuplicateModal(c.uuid),
                      },
                      {
                        label: "Edit",
                        onClick: () => handleOpenEditModal(c.uuid),
                        disabled: c.is_base_case_scenario,
                      },
                      {
                        label: "Delete",
                        onClick: () => handleOnDelete(c.uuid),
                        disabled: c.is_base_case_scenario,
                      },
                    ]}
                  />
                </StickyTableCell>
              </ConditionalProtect>
            </TableRow>
          ))}
        <TableRow>
          <ConditionalProtect type="deal">
            <MuiButton
              className="!ml-11 !my-2 !text-secondary"
              size="small"
              variant="text"
              onClick={() => handleOpenAddCaseModal(dCase.uuid)}
            >
              <AddIcon fontSize="small" /> Add Sensitivity
            </MuiButton>
          </ConditionalProtect>
        </TableRow>
      </React.Fragment>
    );
  };

  const dealCasesOptions: ISelectOption[] = React.useMemo(() => {
    return (
      casesOfDeal
        ?.filter((c) => c.type === "SCEN")
        .map((c) => ({
          label: c.name,
          value: c.uuid,
        })) || []
    );
  }, []);

  return (
    <>
      <ViewWrapper loading={casesLoading} error={casesLoadingFailed}>
        {dealCases?.length > 0 ? (
          <Box>
            <Box className={styles.classes.topSection}>
              <Box className={styles.classes.searchAndFilterContainer}>
                <Searchbar
                  searchString={searchString}
                  onSearchStringChange={onSearchStringChange}
                  handleClearSearchString={handleClearSearchString}
                />
              </Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Add Case"
                  onClick={() => handleOpenAddCaseModal()}
                />
              </ConditionalProtect>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden", height: "auto" }}>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  data-pw="cases-table"
                >
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {getTableColumnAccordingToStatus(
                        columns,
                        currentDeal?.status as string,
                      ).map((column, idx) => {
                        if (column.id === "action") {
                          return (
                            <StickyTableCell
                              key={idx}
                              direction="right"
                              fixedColumnWidth={100}
                              align="center"
                              highZIndex
                            >
                              {column.label}
                            </StickyTableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={idx}
                            align={column.align as "left"}
                            style={{ maxWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={sortTable.orderBy === column.id}
                              direction={
                                sortTable.orderBy === column.id
                                  ? sortTable.order
                                  : "asc"
                              }
                              onClick={() => sortRows(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={
                            getTableColumnAccordingToStatus(
                              columns,
                              currentDeal?.status as string,
                            ).length
                          }
                          align="center"
                        >
                          No Cases.
                        </TableCell>
                      </TableRow>
                    )}
                    {visibleRows.map((c, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <TableRow
                            hover
                            tabIndex={-1}
                            className={styles.classes.dataRow}
                            onClick={() => goToCase(c.uuid)}
                          >
                            <TableCell align="left">
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleExpandRow(c.uuid);
                                }}
                                classes={{ root: "!mr-2" }}
                              >
                                {expandedRows[c.uuid] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                              <span>{trimString(c?.name, 40)}</span>
                            </TableCell>
                            <TableCell align="left">
                              {
                                DEAL_CASE_TYPE[
                                  c?.type as keyof typeof DEAL_CASE_TYPE
                                ]
                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                DEAL_STRUCTURE_TYPE[
                                  c?.child_deal
                                    ?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                                ]
                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                                  c?.child_deal
                                    ?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                                ]
                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                DEAL_TAX_CREDIT_TYPE[
                                  c?.child_deal
                                    ?.tax_credit_type as keyof typeof DEAL_TAX_CREDIT_TYPE
                                ]
                              }
                            </TableCell>
                            <TableCell>
                              {formatDistanceToNow(new Date(c?.modified), {
                                addSuffix: true,
                              })}
                            </TableCell>
                            <ConditionalProtect type="deal">
                              <StickyTableCell
                                direction="right"
                                fixedColumnWidth={100}
                                align="center"
                              >
                                <PopoverMenu
                                  uniqueId={idx}
                                  canOpenUpgrade
                                  items={[
                                    {
                                      label: "Duplicate",
                                      onClick: () =>
                                        handleOpenDuplicateModal(c.uuid),
                                    },
                                    {
                                      label: "Edit",
                                      onClick: () =>
                                        handleOpenEditModal(c.uuid),
                                      disabled: c.is_base_case_scenario,
                                    },
                                    {
                                      label: "Delete",
                                      onClick: () => handleOnDelete(c.uuid),
                                      disabled: c.is_base_case_scenario,
                                    },
                                  ]}
                                />
                              </StickyTableCell>
                            </ConditionalProtect>
                          </TableRow>
                          {renderItsSensitivities(c)}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredRows.length}
                className={styles.classes.paginationRow}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <Box className={styles.classes.emptyContainer}>
            <Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add Case"
                  onClick={() => handleOpenAddCaseModal()}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>

              <Typography variant="body1" className={styles.classes.createInfo}>
                Cases allow you to run scenarios and test assumptions against
                the base case deal. It looks like you do not have any cases yet.
              </Typography>
            </Box>
          </Box>
        )}

        <AddCaseFormModal
          headerLabel="Add Case"
          open={addCaseModalOpen}
          loading={addCaseLoading}
          form={addCaseForm}
          setForm={setAddCaseForm}
          formErrors={addCaseFormErrors}
          setFormErrors={setAddCaseFormErrors}
          onClose={handleCloseAddCaseModal}
          onConfirm={handleAddCase}
          projects={currentDeal?.projects.map((p) => p.name) || []}
          scenarioOptions={scenarioOptions}
          projectCases={projectCases}
          dealCasesOptions={dealCasesOptions}
        />

        <DealCaseDuplicateFormModal
          headerLabel="Duplicate Case"
          open={duplicateCaseModalOpen}
          loading={duplicateCaseLoading}
          form={duplicateCaseForm}
          setForm={setDuplicateCaseForm}
          formErrors={duplicateCaseFormErrors}
          setFormErrors={setDuplicateCaseFormErrors}
          onClose={handleCloseDuplicateModal}
          onConfirm={handleDuplicateCase}
        />

        <UpdateCaseFormModal
          headerLabel="Edit Case"
          open={editCaseModalOpen}
          loading={editDealCaseLoading}
          form={editDealCaseForm}
          setForm={setEditDealCaseForm}
          formErrors={editDealCaseFormErrors}
          setFormErrors={setEditDealCaseFormErrors}
          onClose={handleCloseEditModal}
          onConfirm={handleEditCase}
        />
      </ViewWrapper>
    </>
  );
}
