import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import LogsWrapper from "../../../../components/logs-wrapper";
import LogsButton from "../../../../components/logs-button";
import ViewWrapper from "../../../../components/view-wrapper";
import ReportTableV2 from "../../../../components/report-table-v2";
import ProjectTaxCreditFormModal from "../../../../components/project-tax-credit-form-modal";
import ConditionalProtect from "../../../../components/conditional-protect";
import DetailsCard, {
  IDetailsCardSection,
} from "../../../../components/details-card";
import { setCurrentProjectAction } from "../../../../utils/redux/slices";
import { cn, numberToUSD } from "../../../../utils/helpers";
import {
  useAPI,
  useLogs,
  useDrawer,
  useAppSelector,
} from "../../../../utils/hooks";
import {
  PROJECT_TAX_CREDIT_TYPE,
  UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IProjectTaxCredit,
  IUpdateProjectTaxCreditForm,
  IUpdateProjectTaxCreditFormErrors,
  ILogsConfiguration,
  IReportTable,
} from "../../../../interfaces";
import { useOrganization } from "@clerk/clerk-react";

interface IProps {
  getProjectTaxCredit: (projectUuid: string) => Promise<IProjectTaxCredit[]>;
  getProjectTaxCreditPTC_Report: (projectUuid: string) => Promise<IReportTable>;
  downloadProjectTaxCreditPTC_Report: (projectUuid: string) => Promise<void>;
  updateProjectTaxCredit: (
    projectUuid: string,
    taxCreditId: number,
    form: IUpdateProjectTaxCreditForm,
  ) => Promise<IProjectTaxCredit>;
}

export default function ProjectTaxCreditView({
  getProjectTaxCredit,
  getProjectTaxCreditPTC_Report,
  downloadProjectTaxCreditPTC_Report,
  updateProjectTaxCredit,
}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);
  const { projectUuid, caseProjectUuid } = useParams();
  const { organization } = useOrganization();
  const projectIdToUse = organization ? caseProjectUuid : projectUuid;

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();
  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [updateTaxCreditModalOpen, setUpdateTaxCreditModalOpen] =
    React.useState<boolean>(false);
  const [taxCredit, setTaxCredit] = React.useState<IProjectTaxCredit>();
  const [taxCreditPTC_Report, setTaxCreditPTC_Report] =
    React.useState<IReportTable>();
  const [form, setForm] = React.useState<IUpdateProjectTaxCreditForm>(
    UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE,
  );

  const {
    callAPI: getProjectTaxCreditCallAPI,
    errored: getTaxCreditFailed,
    loading: loadingTaxCredit,
  } = useAPI((projectUuid: string) => getProjectTaxCredit(projectUuid), {
    initialLoading: true,
  });

  const { callAPI: downloadProjectTaxCreditCallAPI } = useAPI(
    (projectUuid: string) => downloadProjectTaxCreditPTC_Report(projectUuid),
  );

  React.useEffect(() => {
    getProjectTaxCreditCallAPI(projectIdToUse).then((response) => {
      response && setTaxCredit(response[0]);
    });
  }, [projectIdToUse]);

  const {
    callAPI: getProjectTaxCreditPTC_ReportCallAPI,
    errored: getProjectTaxCreditPTC_ReportFailed,
    loading: loadingProjectTaxCreditPTC,
  } = useAPI((projectUuid: string) =>
    getProjectTaxCreditPTC_Report(projectUuid),
  );

  React.useEffect(() => {
    if (taxCredit && taxCredit.credit_type === "PTC") {
      getProjectTaxCreditPTC_ReportCallAPI(String(projectIdToUse)).then(
        (res) => {
          res && setTaxCreditPTC_Report(res);
        },
      );
    }
  }, [taxCredit, projectIdToUse]);

  const {
    callAPI: updateProjectTaxCreditCallAPI,
    fieldErrors: updateProjectTaxCreditFormErrors,
    setFieldErrors: setUpdateProjectTaxCreditFormErrors,
    loading: updateProjectTaxCreditLoading,
  } = useAPI<IProjectTaxCredit, IUpdateProjectTaxCreditFormErrors>(
    (
      projectUuid: string,
      taxCreditId: number,
      form: IUpdateProjectTaxCreditForm,
    ) => updateProjectTaxCredit(projectUuid, taxCreditId, form),
  );

  const handleOnUpdateProjectTaxCredit = async (
    form: IUpdateProjectTaxCreditForm,
  ) => {
    const updatedTaxCredit = await updateProjectTaxCreditCallAPI(
      String(projectIdToUse),
      Number(taxCredit?.id),
      form,
    );
    if (updatedTaxCredit && currentProject) {
      setTaxCredit(updatedTaxCredit);
      dispatch(
        setCurrentProjectAction({
          ...currentProject,
          tax_credit_type: updatedTaxCredit.credit_type,
        }),
      );
    }
    return updatedTaxCredit;
  };

  const visibleData: IDetailsCardSection[] = React.useMemo(() => {
    if (taxCredit) {
      const defaultData: IDetailsCardSection[] = [
        {
          heading: "Key Selections",
          fields: [
            {
              label: "Type",
              value: { text: PROJECT_TAX_CREDIT_TYPE[taxCredit.credit_type] },
            },
            {
              label: "Placed in Service Date",
              value: { text: taxCredit.placed_in_service_date },
            },
            ...(taxCredit.credit_type !== "NONE"
              ? [
                  {
                    label: "PWA Requirements Met",
                    value: {
                      text: taxCredit.pwa_requirements_met ? "Yes" : "No",
                    },
                  },
                  {
                    label: "PTC Inflation", // logic to hide it for None/ITC is below
                    value: {
                      text: `${taxCredit.ptc_inflation?.toString() || ""}%`,
                    },
                  },
                ]
              : []),
          ],
        },

        ...(taxCredit.credit_type !== "NONE"
          ? [
              {
                heading: "Adders",
                fields: [
                  {
                    label: "Domestic Content",
                    value: {
                      text: taxCredit.is_ira_domestic_content_adder
                        ? "Yes"
                        : "No",
                    },
                  },
                  {
                    label: "Energy Community",
                    value: {
                      text: taxCredit.is_ira_energy_community_adder
                        ? "Yes"
                        : "No",
                    },
                  },
                  ...(taxCredit.credit_type === "ITC" &&
                  currentProject?.energy_type !== "BESS"
                    ? [
                        {
                          label: "Environmental Justice (EJ)",
                          value: {
                            text: taxCredit.is_ira_environmental_justice_adder
                              ? "Yes"
                              : "No",
                          },
                        },
                        {
                          label: "Special Qualified EJ",
                          value: {
                            text: taxCredit.is_ira_special_qualfied_ej_adder
                              ? "Yes"
                              : "No",
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(taxCredit.credit_type === "ITC"
          ? [
              {
                heading: "Percentage",
                fields: [
                  {
                    label: "ITC Percentage",
                    value: {
                      text: taxCredit.itc_percentage + "%",
                    },
                  },
                  {
                    label: "ITC Adder Percentage",
                    value: {
                      text: taxCredit.itc_adder_percentage + "%",
                    },
                  },
                  {
                    label: "ITC Total Percentage",
                    value: {
                      text: taxCredit.itc_total_percentage + "%",
                    },
                  },
                ],
              },
            ]
          : []),

        ...(taxCredit.credit_type === "PTC"
          ? [
              {
                heading: "Initial Rate",
                fields: [
                  {
                    label: "PTC Rate",
                    value: {
                      text:
                        numberToUSD.format(taxCredit.initial_ptc_rate) + "/MWh",
                    },
                  },
                  {
                    label: "PTC Adder Rate",
                    value: {
                      text:
                        numberToUSD.format(taxCredit.initial_ptc_adder_rate) +
                        "/MWh",
                    },
                  },
                  {
                    label: "PTC Total Rate",
                    value: {
                      text:
                        numberToUSD.format(taxCredit.initial_ptc_total_rate) +
                        "/MWh",
                    },
                  },
                ],
              },
            ]
          : []),

        ...(taxCredit.credit_type !== "NONE"
          ? [
              {
                heading: "Amounts",
                fields: [
                  ...(taxCredit.credit_type === "ITC"
                    ? [
                        {
                          label: "ITC",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_base) ||
                              " ",
                          },
                        },
                        {
                          label: "ITC Adder",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_bonus) ||
                              " ",
                          },
                        },
                        {
                          label: "ITC Total",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_total) ||
                              " ",
                          },
                        },
                      ]
                    : [
                        {
                          label: "PTC",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_base) ||
                              " ",
                          },
                        },
                        {
                          label: "PTC Adder",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_bonus) ||
                              " ",
                          },
                        },
                        {
                          label: "PTC Total",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_total) ||
                              " ",
                          },
                        },
                      ]),
                ],
              },
            ]
          : []),
      ];
      switch (taxCredit.credit_type) {
        case "PTC": {
          return defaultData;
        }
        case "ITC": {
          return defaultData.map((d) => {
            const { fields } = d;
            const updatedFields = fields.filter(
              (f) => f.label !== "PTC Inflation",
            );
            return { ...d, fields: updatedFields };
          });
        }
        case "NONE": {
          return defaultData.filter((d) => {
            const { fields } = d;
            const updatedFields = fields.filter((f) => f.label !== "Type");
            return { ...d, fields: updatedFields };
          });
        }
        default: {
        }
      }
    }
    return [];
  }, [taxCredit]);

  const handleOpenEditProjectTaxCreditModal = async () => {
    if (taxCredit) {
      const { created, created_by, id, modified, project, ...formDetails } =
        taxCredit;
      setForm({ ...formDetails });
      setUpdateTaxCreditModalOpen(true);
    }
  };

  const handleCloseUpdateTaxCreditModal = () => {
    setUpdateTaxCreditModalOpen(false);
  };

  const taxCreditLogConfiguration: ILogsConfiguration = {
    id: Number(taxCredit?.id),
    type: "projecttaxcredit",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      taxCreditLogConfiguration.type,
      taxCreditLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingTaxCredit} error={getTaxCreditFailed}>
        <Box>
          {taxCredit && (
            <Box>
              <Box className={cn("flex justify-end gap-2 mb-4")}>
                <LogsButton onClick={handleOnOpenLogs} />

                <ConditionalProtect type="project">
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleOpenEditProjectTaxCreditModal}
                  />
                </ConditionalProtect>
              </Box>

              <Box className={cn("grid md:grid-cols-2 gap-4")}>
                <DetailsCard
                  autoHeight
                  heading="Tax Credits"
                  sections={visibleData}
                />
              </Box>
            </Box>
          )}
        </Box>

        <ViewWrapper
          loading={loadingProjectTaxCreditPTC}
          error={getProjectTaxCreditPTC_ReportFailed}
        >
          {taxCreditPTC_Report ? (
            <Box className={cn("mt-4")}>
              <ReportTableV2
                toggleDatesVisibility
                data={taxCreditPTC_Report}
                download={() => downloadProjectTaxCreditCallAPI(projectIdToUse)}
              />
            </Box>
          ) : null}
        </ViewWrapper>
      </ViewWrapper>

      <ProjectTaxCreditFormModal
        open={updateTaxCreditModalOpen}
        headerLabel="Edit Tax Credit"
        loading={updateProjectTaxCreditLoading}
        formErrors={updateProjectTaxCreditFormErrors}
        setFormErrors={setUpdateProjectTaxCreditFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateTaxCreditModal}
        onConfirm={handleOnUpdateProjectTaxCredit}
        projectEnergyType={currentProject?.energy_type}
      />
      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={taxCreditLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={taxCreditLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
