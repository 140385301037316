import React from "react";
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import { useAPI } from "../../../../utils/hooks";
import {
  DEAL_CASE_TYPE,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_EQUITY_FORM_DEFAULT_STATE,
  PROJECT_TAX_CREDIT_TYPE,
} from "../../../../constants";
import { IDealCase, IDealTaxEquityForm } from "../../../../interfaces";

interface IProps {
  getDealCaseDetails: (dealUuid: string, caseId: string) => Promise<IDealCase>;
}

export default function DealCaseDetailsView({
  getDealCaseDetails,
}: IProps): JSX.Element {
  const { dealUuid, caseId } = useParams();

  const dispatch = useDispatch();

  const [editDealTaxEquityModalOpen, setEditDealTaxEquityModalOpen] =
    React.useState<boolean>(false);
  const [dealTaxEquityForm, setDealTaxEquityForm] =
    React.useState<IDealTaxEquityForm>(DEAL_TAX_EQUITY_FORM_DEFAULT_STATE);

  const {
    callAPI: getDealCaseDetailsCallAPI,
    errored: getDealCaseDetailsErrored,
    loading: getDealCaseDetailsLoading,
    response: caseDetails,
  } = useAPI(
    (dealUuid: string, caseId: string) => getDealCaseDetails(dealUuid, caseId),
    { initialLoading: true },
  );

  React.useEffect(() => {
    getDealCaseDetailsCallAPI(dealUuid, caseId);
  }, [dealUuid, caseId]);

  return (
    <>
      <ViewWrapper
        error={getDealCaseDetailsErrored}
        loading={getDealCaseDetailsLoading}
      >
        <Box className="grid md:grid-cols-2 gap-4">
          <DetailsCard
            heading="Case Details"
            sections={[
              {
                fields: [
                  {
                    label: "Case Name",
                    value: {
                      text: caseDetails?.name,
                    },
                  },
                  {
                    label: "Case Type",
                    value: {
                      text: DEAL_CASE_TYPE[
                        caseDetails?.type as keyof typeof DEAL_CASE_TYPE
                      ],
                    },
                  },
                  {
                    label: "Deal Structure",
                    value: {
                      text: DEAL_STRUCTURE_TYPE[
                        caseDetails?.child_deal
                          .structure as keyof typeof DEAL_STRUCTURE_TYPE
                      ],
                    },
                  },
                  {
                    label: "Deal Tax Credit Structure",
                    value: {
                      text: DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                        caseDetails?.child_deal
                          .tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                      ],
                    },
                  },
                  {
                    label: "Tax Credit Type for Project",
                    value: {
                      text: PROJECT_TAX_CREDIT_TYPE[
                        caseDetails?.child_deal
                          .tax_credit_type as keyof typeof PROJECT_TAX_CREDIT_TYPE
                      ],
                    },
                  },
                ],
              },
            ]}
          />
          {caseDetails?.project_cases.length ? (
            <DetailsCard
              heading="Project Cases"
              sections={[
                {
                  fields: [
                    {
                      label: <span className="font-bold">Project Name</span>,
                      value: {
                        text: <span className="font-bold">Case Name</span>,
                      },
                    },
                  ],
                },
                {
                  fields:
                    caseDetails?.project_cases.map((c) => ({
                      label: c.base_case_project.name,
                      value: {
                        text: (
                          <Link
                            to={`/project/${c.base_case_project.uuid}/case-project/${c.case.child_project_uuid}/general`}
                            className="underline"
                          >
                            {c.case.name}
                          </Link>
                        ),
                      },
                    })) || [],
                },
              ]}
            />
          ) : null}
        </Box>
      </ViewWrapper>
    </>
  );
}
