import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => {
  return {
    tableInput: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
      fontSize: 15,
    },
    tableInputField: {
      "& input": {
        fontSize: 14,
        padding: theme.spacing(1.5),
      },
    },
  };
});
