import React from "react";
import Box from "@mui/material/Box";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import ReportTableV2 from "../../../../components/report-table-v2";
import Chart from "./chart";
import Menu from "../../../../components/menu";
import ViewWrapper from "../../../../components/view-wrapper";
import { cn } from "../../../../utils/helpers";
import { downloadProjectProforma } from "../../../../apis/project/proforma";
import {
  useAPI,
  useAppSelector,
  useSessionStorage,
} from "../../../../utils/hooks";
import {
  PERIODICITY_OPTIONS,
  PERIODICITY,
  PROFORMA_BASIS,
  PROFORMA_BASIS_OPTIONS,
  PROFORMA_CHART_DETAIL_CONFIG,
} from "../../../../constants";
import {
  IProjectTiming,
  ProformaReportType,
  IReportTable,
  IProjectProformaResponse,
  IProjectDetails,
} from "../../../../interfaces";
import { useOrganization } from "@clerk/clerk-react";

interface IProps {
  getProjectTiming: (projectUuid: string) => Promise<IProjectTiming[] | null>;
  getProjectProforma: (
    projectUuid: string,
    periodicity: ProformaReportType,
    cash_basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
}

interface IProformaContentProps {
  projectProforma: IReportTable;
  selectedBasis: "cash" | "accrual";
  currentProject: IProjectDetails | undefined;
  downloadReport: () => Promise<void>;
}

const ProformaContent = React.memo(
  ({
    projectProforma,
    selectedBasis,
    currentProject,
    downloadReport,
  }: IProformaContentProps) => (
    <Box>
      <Box className={cn("mt-4")}>
        <ReportTableV2
          toggleDatesVisibility
          data={projectProforma}
          groupExpandedDepth={-1}
          download={downloadReport}
        />
      </Box>
      <Chart
        chartData={projectProforma}
        chartElementConfig={PROFORMA_CHART_DETAIL_CONFIG}
        selectedBasis={selectedBasis}
        hideMwAxis={currentProject?.energy_type === "BESS"}
      />
    </Box>
  ),
);

export default function ProjectProFormaView({
  getProjectTiming,
  getProjectProforma,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { projectUuid, caseProjectUuid } = useParams();
  const { organization } = useOrganization();
  const projectIdToUse = organization ? caseProjectUuid : projectUuid;

  const { currentProject } = useAppSelector((s) => s.project);

  const [selectedPeriodicity, setSelectedPeriodicity] = useSessionStorage<
    ProformaReportType | ""
  >(`project-periodicity-${String(caseProjectUuid)}`, "");
  const [selectedBasis, setSelectedBasis] = useSessionStorage<
    "cash" | "accrual"
  >(`project-selected-basis-${String(caseProjectUuid)}`, "cash");

  const [projectProforma, setProjectProforma] =
    React.useState<IReportTable | null>(null);

  const {
    callAPI: getProjectProformaCallAPI,
    loading: loadingProjectProforma,
    errored: getProjectProformaFailed,
  } = useAPI(
    (projectUuid, periodicity, cash_basis) =>
      getProjectProforma(projectUuid, periodicity, cash_basis),
    { initialLoading: true },
  );

  React.useEffect(() => {
    if (searchParams.has("periodicity")) {
      const periodicity = searchParams.get("periodicity") as ProformaReportType;
      setSelectedPeriodicity(periodicity);
    }
  }, []);

  React.useEffect(() => {
    handleGetProjectTiming(String(projectIdToUse));
  }, [projectIdToUse]);

  React.useEffect(() => {
    if (selectedPeriodicity) {
      getProjectProformaCallAPI(
        String(projectIdToUse),
        selectedPeriodicity,
        selectedBasis,
      ).then((response) => {
        response && setProjectProforma(response.data);
      });
    }
  }, [projectIdToUse, selectedPeriodicity, selectedBasis]);

  const handleGetProjectTiming = async (projectUuid: string) => {
    const response = await getProjectTiming(projectUuid).catch(() => null);
    if (!selectedPeriodicity && response) {
      setSelectedPeriodicity(response[0].periodicity);
      navigate(`${location.pathname}?periodicity=${response[0].periodicity}`, {
        replace: true,
      });
    }
  };

  const handleDownloadProjectProforma = async () => {
    if (selectedPeriodicity && caseProjectUuid) {
      await downloadProjectProforma(
        String(projectIdToUse),
        selectedPeriodicity,
        selectedBasis,
      ).catch(() => null);
    }
  };

  const handleReportTypeChange = (v: ProformaReportType) => {
    setSelectedPeriodicity(v);
    navigate(`${location.pathname}?periodicity=${v}`, { replace: true });
  };

  const handleBasisChange = (basis: "cash" | "accrual") => {
    setSelectedBasis(basis);
  };

  return (
    <>
      <Box className={cn("flex justify-between items-center mt-4")}>
        <Box className={cn("flex gap-4")}>
          <Menu
            selectedItem={
              selectedPeriodicity
                ? PERIODICITY[selectedPeriodicity]
                : selectedPeriodicity
            }
            placeholder="Periodicity"
            menuItems={PERIODICITY_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleReportTypeChange(o.value as ProformaReportType),
            }))}
          />
          <Menu
            selectedItem={
              selectedBasis ? PROFORMA_BASIS[selectedBasis] : selectedBasis
            }
            placeholder="Basis"
            menuItems={PROFORMA_BASIS_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleBasisChange(o.value as keyof typeof PROFORMA_BASIS),
            }))}
          />
        </Box>
      </Box>

      <ViewWrapper
        loading={loadingProjectProforma}
        error={getProjectProformaFailed}
      >
        {projectProforma ? (
          <ProformaContent
            projectProforma={projectProforma}
            selectedBasis={selectedBasis}
            currentProject={currentProject}
            downloadReport={handleDownloadProjectProforma}
          />
        ) : null}
      </ViewWrapper>
    </>
  );
}
