import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);
  return {
    input: {
      "& textarea": {
        padding: 0,
        paddingBottom: theme.spacing(1),
      },
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
      fontWeight: 600,
      "&::before, &::after": {
        borderTopWidth: "2px",
        borderColor: theme.palette.primary.main,
      },
    },
    toggleButton: {
      background: selectedTheme.color.aboveSurface,
      " .Mui-selected": {
        color: `${selectedTheme.color.secondary} !important`,
        background: selectedTheme.color.surface,
        fontWeight: "bold",
      },
    },
  };
});
