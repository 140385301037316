import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import {
  SetStateAction,
  IProjectCase,
  IProjectCaseForm,
  IProjectCaseFormErrors,
  ISelectOption,
} from "../../interfaces";
import { externalLightTheme } from "../../utils/theme";
import CheckboxInput from "../checkbox-input";
import { PROEJECT_CASE_TYPE } from "../../constants";

interface IProps {
  type: "add" | "edit";
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IProjectCaseForm;
  setForm: SetStateAction<IProjectCaseForm>;
  formErrors?: IProjectCaseFormErrors;
  setFormErrors: SetStateAction<IProjectCaseFormErrors | undefined>;
  onClose: () => void;
  onConfirm: () => Promise<IProjectCase | undefined>;
  scenarioOptions: ISelectOption[];
}

export default function ProjectCaseFormModal({
  type,
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  scenarioOptions,
}: IProps): JSX.Element {
  const styles = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm({
      name: "",
      description: "",
      type: "",
      is_synced_with_base_case: false,
      parent_scenario_uuid: null,
      sensitivities: {
        merchant_energy_price_multiplier: null,
        merchant_rec_price_multiplier: null,
        merchant_capacity_price_multiplier: null,
        cost_multiplier: null,
        expense_multiplier: null,
      },
    });
    setFormErrors({});
  };

  const onCaseTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    value: keyof typeof PROEJECT_CASE_TYPE,
  ) => {
    setFormErrors((prev) => ({
      ...prev,
      type: "",
    }));
    if (value !== null) {
      setForm((prev) => {
        return {
          ...prev,
          type: value,
        };
      });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      is_synced_with_base_case: event.target.checked,
    }));
  };

  const handleOnConfirm = async () => {
    if (form.type === "SENS") {
      form.is_synced_with_base_case = true;
    }
    const projectCase = await onConfirm();
    projectCase && handleOnClose();
    return projectCase;
  };

  const isTwoColLayout = form.type === "SENS" && type !== "edit";

  const handleSensitivityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(
      (prevState) =>
        ({
          ...prevState,
          sensitivities: {
            ...prevState.sensitivities,
            [e.target.name]: e.target.value,
          },
        }) as IProjectCaseForm,
    );
  };

  const sensitivityError = formErrors?.sensitivities as unknown as {
    [index in keyof NonNullable<(typeof form)["sensitivities"]>]: string;
  };

  return (
    <Modal
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{
        paper: isTwoColLayout ? "!min-w-[70%]" : "",
      }}
    >
      <>
        {form.type === "SENS" && type === "add" && (
          <Alert severity="info" classes={{ root: "mb-4" }}>
            The sensitivity will be synchronized with whatever scenario case it
            is associated with
          </Alert>
        )}

        {form.type === "SENS" && type === "edit" && (
          <Alert severity="info" classes={{ root: "mb-4" }}>
            {`To edit "Project Sensitivities", please go to Project > General`}
          </Alert>
        )}
        <Box className={isTwoColLayout ? "grid md:grid-cols-2 gap-4" : ""}>
          <Box>
            <TextInput
              required
              label="Case Name"
              name="name"
              value={form.name}
              onFocus={clearErrorOnFocus}
              onChange={handleChange}
              error={Boolean(formErrors?.name)}
              helperText={formErrors?.name}
              disabled={loading}
            />
            {type === "add" && (
              <div className="mb-4">
                <span className="font-bold mr-2">Type</span>
                <ToggleButtonGroup
                  exclusive
                  value={form.type}
                  onChange={onCaseTypeChange}
                  size="small"
                  classes={{ root: styles.classes.toggleButton }}
                >
                  <ToggleButton
                    value="SCEN"
                    size="small"
                    sx={{ textTransform: "none" }}
                  >
                    Scenario
                  </ToggleButton>
                  <ToggleButton
                    value="SENS"
                    size="small"
                    sx={{ textTransform: "none" }}
                  >
                    Sensitivity
                  </ToggleButton>
                </ToggleButtonGroup>
                <FormHelperText error={Boolean(formErrors?.type)}>
                  {formErrors?.type}
                </FormHelperText>
              </div>
            )}
            {form.type === "SENS" && type === "add" && (
              <SelectInput
                label="Scenario"
                name="parent_scenario_uuid"
                selected={form.parent_scenario_uuid ?? ""}
                items={scenarioOptions}
                onFocus={() => clearSelectErrorOnFocus("parent_scenario_uuid")}
                onChange={(e) =>
                  handleSelectInputChange(e, "parent_scenario_uuid")
                }
                error={Boolean(formErrors?.parent_scenario_uuid)}
                helperText={formErrors?.parent_scenario_uuid}
                disabled={loading}
              />
            )}
            <TextInput
              classes={{ root: styles.classes.input }}
              multiline
              rows={4}
              label="Description"
              name="description"
              value={form.description}
              onFocus={clearErrorOnFocus}
              onChange={handleChange}
              error={Boolean(formErrors?.description)}
              helperText={formErrors?.description}
              disabled={loading}
            />
            {form.type !== "SENS" && (
              <CheckboxInput
                label="Checking this box will keep any unaltered case data in sync with the Project."
                checked={form.is_synced_with_base_case}
                onChange={handleCheckboxChange}
                disabled={loading}
              />
            )}
          </Box>

          {form.type === "SENS" && type === "add" && (
            <Box className="border border-gray-300 rounded p-4">
              <Typography fontWeight="bold" marginBottom={2}>
                Project Sensitivities
              </Typography>
              <Box>
                <div className={styles.classes.tableInput}>
                  <span>Merchant Energy Price Multiplier</span>
                  <TextField
                    type="number"
                    classes={{ root: styles.classes.tableInputField }}
                    name="merchant_energy_price_multiplier"
                    value={form.sensitivities?.merchant_energy_price_multiplier}
                    onChange={handleSensitivityChange}
                    disabled={loading}
                    InputProps={{
                      endAdornment: <>X</>,
                    }}
                    error={!!sensitivityError?.merchant_energy_price_multiplier}
                    helperText={
                      sensitivityError?.merchant_energy_price_multiplier
                    }
                  />
                </div>
                <div className={styles.classes.tableInput}>
                  <span>Merchant Capacity Price Multiplier</span>
                  <TextField
                    type="number"
                    classes={{ root: styles.classes.tableInputField }}
                    name="merchant_capacity_price_multiplier"
                    value={
                      form.sensitivities?.merchant_capacity_price_multiplier
                    }
                    onChange={handleSensitivityChange}
                    disabled={loading}
                    InputProps={{
                      endAdornment: <>X</>,
                    }}
                    error={
                      !!sensitivityError?.merchant_capacity_price_multiplier
                    }
                    helperText={
                      sensitivityError?.merchant_capacity_price_multiplier
                    }
                  />
                </div>
                <div className={styles.classes.tableInput}>
                  <span>Merchant REC Price Multiplier</span>
                  <TextField
                    type="number"
                    classes={{ root: styles.classes.tableInputField }}
                    name="merchant_rec_price_multiplier"
                    value={form.sensitivities?.merchant_rec_price_multiplier}
                    onChange={handleSensitivityChange}
                    disabled={loading}
                    InputProps={{
                      endAdornment: <>X</>,
                    }}
                    error={!!sensitivityError?.merchant_rec_price_multiplier}
                    helperText={sensitivityError?.merchant_rec_price_multiplier}
                  />
                </div>
                <div className={styles.classes.tableInput}>
                  <span>Cost Multiplier</span>
                  <TextField
                    type="number"
                    classes={{ root: styles.classes.tableInputField }}
                    name="cost_multiplier"
                    value={form.sensitivities?.cost_multiplier}
                    onChange={handleSensitivityChange}
                    disabled={loading}
                    InputProps={{
                      endAdornment: <>X</>,
                    }}
                    error={!!sensitivityError?.cost_multiplier}
                    helperText={sensitivityError?.cost_multiplier}
                  />
                </div>
                <div className={styles.classes.tableInput}>
                  <span>Expense Multiplier</span>
                  <TextField
                    type="number"
                    classes={{ root: styles.classes.tableInputField }}
                    name="expense_multiplier"
                    value={form.sensitivities?.expense_multiplier}
                    onChange={handleSensitivityChange}
                    disabled={loading}
                    InputProps={{
                      endAdornment: <>X</>,
                    }}
                    error={!!sensitivityError?.expense_multiplier}
                    helperText={sensitivityError?.expense_multiplier}
                  />
                </div>
              </Box>
            </Box>
          )}
        </Box>
      </>
    </Modal>
  );
}
