import React from "react";
import { Protect } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";

import { USER_PERMISSIONS } from "../../constants";
import { useAppSelector, useLocalStorage } from "../../utils/hooks";
import { TUserPermission, UserPermissionType } from "../../interfaces";

interface IProps {
  type: UserPermissionType;
  children: React.ReactNode;
}

export default function ConditionalProtect({
  children,
  type,
}: IProps): JSX.Element | null {
  const { projectUuid, dealUuid, caseDealUuid, caseProjectUuid } = useParams();
  const { currentProject } = useAppSelector((state) => state.project);
  const { currentDeal } = useAppSelector((state) => state.deal);

  const [projectCaseData] = useLocalStorage(String(projectUuid), "");
  const [dealCaseData] = useLocalStorage(String(dealUuid), "");

  const isSensitivityProjectCase = React.useMemo(() => {
    if (!projectUuid || !caseProjectUuid) return false;

    const parsedData = JSON.parse(projectCaseData);
    return parsedData[caseProjectUuid] === "SENS";
  }, [projectCaseData, projectUuid, caseProjectUuid]);

  const isSensitivityDealCase = React.useMemo(() => {
    if (!dealUuid || !caseDealUuid) return false;

    const parsedData = JSON.parse(dealCaseData);
    return parsedData[caseDealUuid] === "SENS";
  }, [dealCaseData, dealUuid, caseDealUuid]);

  const getPermissions = (): TUserPermission | "" => {
    switch (type) {
      case "deal":
        return USER_PERMISSIONS.DEALS_CRUD;
      case "project":
        return USER_PERMISSIONS.PROJECTS_CRUD;
      case "counterparty":
        return USER_PERMISSIONS.ORG_COUNTERPARTY_CRUD;
      case "externalshare":
        return USER_PERMISSIONS.ORG_EXTERNAL_SHARE_CRUD;
      case "data_delete":
        return USER_PERMISSIONS.DATA_DELETE;
      default:
        return "";
    }
  };

  if (
    type === "project" &&
    (currentProject?.status === "ARCH" || isSensitivityProjectCase)
  )
    return null;

  if (
    type === "deal" &&
    (currentDeal?.status === "ARCH" || isSensitivityDealCase)
  )
    return null;

  return <Protect permission={getPermissions()}>{children}</Protect>;
}
