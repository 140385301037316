import React from "react";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import { useOrganization } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";

import useCostSummaryStyles from "./cost-summary-styles";
import Button from "../../../../components/button";
import ConditionalProtect from "../../../../components/conditional-protect";
import ViewWrapper from "../../../../components/view-wrapper";
import ProjectCosts from "./project-costs";
import ChartWrapper from "../../../../components/chart-wrapper";
import ProjectCostChart from "../dashboard/chart";
import CostItemFormModal from "../../../../components/cost-item-form-modal";
import CollapsibleWrapper from "../../../../components/collapsible-wrapper";
import { useAPI } from "../../../../utils/hooks";
import { getProjectTimingDateSchedule } from "../../../../apis/project/timing";
import {
  COST_ITEM_FORM_DEFAULT_STATE,
  COST_MILESTONE_DATE_TYPES,
  PROJECT_COST_TYPE,
} from "../../../../constants";
import {
  ICostItemForm,
  ICostItemFormErrors,
  ICostItemResponse,
  IProjectCostDataResponse,
  IProjectCostSummaryResponse,
  IProjectTimingDateScheduleResults,
} from "../../../../interfaces";
import { PROJECT_COST_TABLE_COLUMNS } from "../dashboard";
import {
  cn,
  formatCurrency,
  formatNumberWithDollarAndDecimals,
  navigateAndScroll,
  replacePagePathForPersonalAccount,
} from "../../../../utils/helpers";

interface IProps {
  getProjectCostSummary: (
    projectUuid: string,
  ) => Promise<IProjectCostSummaryResponse>;
  createProjectCostItem: (
    form: ICostItemForm,
    projectUuid: string,
    projectCostId: number,
  ) => Promise<ICostItemResponse>;
  getProjectCostList: (
    projectUuid: string,
  ) => Promise<IProjectCostDataResponse>;
  editProjectCostItem: (
    form: ICostItemForm,
    projectUuid: string,
    projectCostId: number,
    itemId: number,
  ) => Promise<ICostItemResponse>;
}

export default function ProjectCostInputsView({
  getProjectCostSummary,
  getProjectCostList,
  createProjectCostItem,
}: IProps): JSX.Element {
  const costSummaryStyles = useCostSummaryStyles();
  const navigate = useNavigate();
  const { projectUuid, caseProjectUuid } = useParams();
  const { organization } = useOrganization();
  const projectIdToUse = organization ? caseProjectUuid : projectUuid;

  const [projectCostSummary, setProjectCostSummary] =
    React.useState<IProjectCostSummaryResponse>();
  const [projecCostData, setProjectCostData] =
    React.useState<IProjectCostDataResponse>();
  const [dateSchedule, setDateSchedule] = React.useState<string[]>([]);
  const [openAddCostModal, setOpenAddCostModal] = React.useState(false);
  const [
    milestoneDatePercentageInputValues,
    setMilestoneDatePercentageInputValues,
  ] = React.useState<Record<keyof typeof COST_MILESTONE_DATE_TYPES, number>>({
    BOL: 0,
    NTP: 0,
    COD: 0,
    MC: 0,
    SC: 0,
  });
  const [addCostForm, setAddCostForm] = React.useState<ICostItemForm>(
    COST_ITEM_FORM_DEFAULT_STATE,
  );

  const {
    callAPI: getProjectCostListCallAPI,
    loading: loadingProjectCostList,
    errored: projectCostListFailed,
  } = useAPI((projectUuid) => getProjectCostList(projectUuid), {
    initialLoading: true,
  });

  React.useEffect(() => {
    if (projectIdToUse) {
      getProjectCosts(projectIdToUse);
      getCostDateSchedule(projectIdToUse);
      getProjectCostSummaryData(projectIdToUse);
    }
  }, [projectIdToUse]);

  const getProjectCosts = async (projectUuid: string) => {
    getProjectCostListCallAPI(projectUuid).then((res) => {
      res && setProjectCostData(res);
    });
  };

  const projectCostId: string | undefined = React.useMemo(() => {
    return projecCostData?.data.project_cost.id.toString();
  }, [projecCostData]);

  const updateCosts = () => {
    getProjectCosts(String(projectIdToUse));
  };

  const handleOpenAddCostModal = async () => {
    setAddCostForm((prev) => ({
      ...prev,
      cost_percentage_curve: new Array(dateSchedule.length ?? 0).fill(0),
    }));
    setOpenAddCostModal(true);
  };

  const handleCloseAddCostModal = () => {
    setOpenAddCostModal(false);
  };

  const getCostDateSchedule = async (projectUuid: string) => {
    getProjectTimingDateScheduleCallAPI(projectUuid).then((res) => {
      res && setDateSchedule(res.data.date_schedule);
    });
  };

  const { callAPI: getProjectTimingDateScheduleCallAPI } = useAPI<
    IProjectTimingDateScheduleResults,
    ICostItemFormErrors
  >((projectId) =>
    getProjectTimingDateSchedule({ period_type: "PRE_COD" }, projectId),
  );

  const {
    callAPI: getProjectCostSummaryCallAPI,
    errored: getCostSummaryFailed,
    loading: loadingCostSummary,
  } = useAPI((projectUuid: string) => getProjectCostSummary(projectUuid), {
    initialLoading: true,
  });

  const getProjectCostSummaryData = (projectUuid: string) => {
    getProjectCostSummaryCallAPI(projectUuid).then((response) => {
      response && setProjectCostSummary(response);
    });
  };

  const {
    callAPI: createCostItemCallAPI,
    loading: createCostItemLoading,
    fieldErrors: createCostItemFormErrors,
    setFieldErrors: setCreateCostItemFormErrors,
  } = useAPI<ICostItemResponse, ICostItemFormErrors>(
    (form, projectId, costId) => createProjectCostItem(form, projectId, costId),
  );

  const onAddConfirm = async (form: ICostItemForm) => {
    const res = await createCostItemCallAPI(
      form,
      projectIdToUse,
      projecCostData?.data.project_cost.id,
    );
    res && getProjectCosts(String(projectIdToUse));
    return res;
  };

  const handleCostClick = (costType: keyof typeof PROJECT_COST_TYPE) => {
    let pathToUse = "";
    if (costType === "LC") {
      pathToUse = `/project/${projectUuid}/case-project/${projectUuid}/cost-credit-support`;
    } else {
      pathToUse = `/project/${projectUuid}/case-project/${projectUuid}/cost-inputs`;
    }

    if (!organization) {
      pathToUse = replacePagePathForPersonalAccount(
        pathToUse,
        "project",
      ) as string;
    }
    if (costType === "LC") {
      navigate(pathToUse);
    } else {
      handleNavigate(pathToUse, costType);
    }
  };

  const handleNavigate = async (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId, 220);
  };

  return (
    <ViewWrapper loading={loadingProjectCostList} error={projectCostListFailed}>
      <Box>
        <Box className="flex justify-end mb-2">
          <ConditionalProtect type="project">
            <Button
              btnType="primary"
              label="Add Cost"
              startIcon={<Add />}
              onClick={handleOpenAddCostModal}
            />
          </ConditionalProtect>
        </Box>
        <ViewWrapper loading={loadingCostSummary} error={getCostSummaryFailed}>
          {projectCostSummary && (
            <TableContainer component={Paper} className="mb-4">
              <Table classes={{ root: costSummaryStyles.classes.table }}>
                <TableHead>
                  <TableRow>
                    {PROJECT_COST_TABLE_COLUMNS.map((col, idx) => (
                      <TableCell key={idx} align={col.align}>
                        {col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectCostSummary.data.cost_items.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <span
                          onClick={() => handleCostClick(row.cost_type)}
                          className={costSummaryStyles.classes.link}
                        >
                          {
                            PROJECT_COST_TYPE[
                              row.cost_type as keyof typeof PROJECT_COST_TYPE
                            ]
                          }
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(row.cost_in_dollars, 0)}
                      </TableCell>
                      <TableCell align="right">
                        {formatNumberWithDollarAndDecimals(
                          row.cost_in_dollars_per_watt,
                          2,
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.cost_percentage.toFixed(2) + "%"}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className={costSummaryStyles.classes.boldRow}>
                    <TableCell>Total Project Cost</TableCell>
                    <TableCell align="right">
                      {formatCurrency(projectCostSummary.data.total_cost, 0)}
                    </TableCell>
                    <TableCell align="right">
                      {formatNumberWithDollarAndDecimals(
                        projectCostSummary.data.total_cost_per_watt,
                        2,
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {Number(100).toFixed(2) + "%"}
                    </TableCell>
                    {/* assuming total project cost to be 100% */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box className={costSummaryStyles.classes.chartContainer}>
            {projectCostSummary && (
              <CollapsibleWrapper
                title="Cost Components"
                collapseButtonLabel="Chart"
              >
                <ChartWrapper className={cn("!shadow-none")}>
                  <ProjectCostChart data={projectCostSummary?.data} />
                </ChartWrapper>
              </CollapsibleWrapper>
            )}
          </Box>
        </ViewWrapper>

        <ProjectCosts
          projectCostId={projectCostId!}
          costItems={projecCostData?.data.cost_items || []}
          viewType="single"
          handleUpdate={updateCosts}
          handleOpenAddCostModal={handleOpenAddCostModal}
        />

        <CostItemFormModal
          dateSchedule={dateSchedule}
          form={addCostForm}
          headerLabel="Add Project Cost"
          loading={createCostItemLoading}
          onClose={handleCloseAddCostModal}
          onConfirm={onAddConfirm}
          open={openAddCostModal}
          setForm={setAddCostForm}
          setFormErrors={setCreateCostItemFormErrors}
          formErrors={createCostItemFormErrors}
          setMilestoneDatePercentageInputValues={
            setMilestoneDatePercentageInputValues
          }
          milestoneDatePercentageInputValues={
            milestoneDatePercentageInputValues
          }
        />
      </Box>
    </ViewWrapper>
  );
}
