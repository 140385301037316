import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import LogsWrapper from "../../../../components/logs-wrapper";
import ProjectValuationFormModal from "../../../../components/project-valuation-form-modal";
import DetailsCard from "../../../../components/details-card";
import ValuationDisclaimer from "./disclaimer";
import LogsButton from "../../../../components/logs-button";
import ConditionalProtect from "../../../../components/conditional-protect";
import { setValuationExistAction } from "../../../../utils/redux/slices";
import { useAPI, useDrawer, useLogs } from "../../../../utils/hooks";
import { cn, formatCurrency } from "../../../../utils/helpers";
import {
  IProjectValuation,
  IProjectValuationForm,
  IProjectValuationFormErrors,
  ILogsConfiguration,
  IProjectCostSummaryResponse,
  IProjectCostForm,
  IProjectCostFormErrors,
} from "../../../../interfaces";
import {
  PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS,
  PROJECT_VALUATION_FMV_METHODS,
  PROJECT_VALUATION_FORM_DEFAULT_STATE,
} from "../../../../constants";
import ProjectCostFormModal from "../../../../components/project-cost-form-modal";

interface IProps {
  getProjectCostSummary: (
    projectUuid: string,
  ) => Promise<IProjectCostSummaryResponse>;
  getProjectValuation: (projectUuid: string) => Promise<IProjectValuation[]>;
  addProjectValuation: (
    projectUuid: string,
    form: IProjectValuationForm,
  ) => Promise<IProjectValuation>;
  editProjectValuation: (
    projectUuid: string,
    valuationId: number,
    form: IProjectValuationForm,
  ) => Promise<IProjectValuation>;
  updateProjectCosts: (
    form: { profit_percent: number },
    projectUuid: string,
    costId: number,
  ) => Promise<unknown>;
}

export default function ProjectFmvStepUpView({
  getProjectCostSummary,
  getProjectValuation,
  editProjectValuation,
  addProjectValuation,
  updateProjectCosts,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const { projectUuid, caseProjectUuid } = useParams();
  const { organization } = useOrganization();
  const projectIdToUse = organization ? caseProjectUuid : projectUuid;

  const [projectValuation, setProjectValuation] =
    React.useState<IProjectValuation>();
  const [projectValuationForm, setProjectValuationForm] =
    React.useState<IProjectValuationForm>(PROJECT_VALUATION_FORM_DEFAULT_STATE);
  const [openProjectValuationForm, setOpenProjectValuationForm] =
    React.useState<boolean>(false);
  const [openEditProjectValuationForm, setOpenEditProjectValuationForm] =
    React.useState<boolean>(false);
  const [updateProjectCostModalOpen, setUpdateProjectCostModalOpen] =
    React.useState<boolean>(false);
  const [projectCostForm, setProjectCostForm] =
    React.useState<IProjectCostForm>({
      fmv_step_up_input_type: "",
      profit_percent: "",
      fmv_step_up: "",
    });

  const handleOpenAddProjectValuationForm = () => {
    setOpenProjectValuationForm(true);
  };
  const handleCloseAddProjectValuationForm = () => {
    setOpenProjectValuationForm(false);
  };

  const handleOpenEditProjectValuationForm = () => {
    const {
      contracted_discount_rate,
      developer_profit,
      fmv_method,
      itc_adder_domestic_content,
      itc_adder_energy_community,
      itc_adder_environmental_justice,
      itc_adder_special_qualified_ej,
      itc_eligible_basis_method,
      merchant_discount_rate,
    } = projectValuation!;
    setProjectValuationForm({
      contracted_discount_rate,
      developer_profit,
      fmv_method,
      itc_adder_domestic_content: itc_adder_domestic_content ?? false,
      itc_adder_energy_community: itc_adder_energy_community ?? false,
      itc_adder_environmental_justice: itc_adder_environmental_justice ?? false,
      itc_adder_special_qualified_ej: itc_adder_special_qualified_ej ?? false,
      itc_eligible_basis_method,
      merchant_discount_rate,
    });
    setOpenEditProjectValuationForm(true);
  };

  const handleCloseEditProjectValuationForm = () => {
    setOpenEditProjectValuationForm(false);
  };

  const { callAPI: getProjectCostSummaryCallAPI, response: costSummary } =
    useAPI((projectUuid: string) => getProjectCostSummary(projectUuid), {
      initialLoading: true,
    });

  const {
    callAPI: getProjectValuationCallAPI,
    loading: getProjectValuationLoading,
    errored: getProjectValuationFailed,
  } = useAPI((projectUuid) => getProjectValuation(projectUuid), {
    initialLoading: true,
  });

  const {
    callAPI: editProjectValuationCallAPI,
    fieldErrors: editProjectValuationFormErrors,
    setFieldErrors: setEditProjectValuationFormErrors,
    loading: editProjectValuationLoading,
  } = useAPI<IProjectValuation, IProjectValuationFormErrors>(
    (projectUuid: string, valuationId: number, form: IProjectValuationForm) =>
      editProjectValuation(projectUuid, valuationId, form),
  );

  const {
    callAPI: addProjectValuationCallAPI,
    fieldErrors: addProjectValuationFormErrors,
    setFieldErrors: setAddProjectValuationFormErrors,
    loading: addProjectValuationLoading,
  } = useAPI<IProjectValuation, IProjectValuationFormErrors>(
    (projectUuid: string, form: IProjectValuationForm) =>
      addProjectValuation(projectUuid, form),
  );

  const {
    callAPI: updateProjecCostCallAPI,
    loading: updateProjectCostLoading,
    fieldErrors: updateProjectCostFormErrors,
    setFieldErrors: setUpdateProjectCostFormErrors,
  } = useAPI<unknown, IProjectCostFormErrors>(
    (form, projectUuid: string, costId: number) =>
      updateProjectCosts(form, projectUuid, costId),
  );

  const handleUpdateProjectCost = async (form: IProjectCostForm) => {
    const res = await updateProjecCostCallAPI(
      form,
      projectIdToUse,
      costSummary?.data.project_cost_id,
    );
    res && getProjectCostSummaryCallAPI(caseProjectUuid);
    return res;
  };

  const onAddProjectValuation = async (form: IProjectValuationForm) => {
    const pValuation = await addProjectValuationCallAPI(projectIdToUse, form);
    if (pValuation) {
      setProjectValuation(pValuation);
      dispatch(setValuationExistAction(true));
    }
    return pValuation;
  };

  const onEditProjectValuation = async (form: IProjectValuationForm) => {
    const pValuation = await editProjectValuationCallAPI(
      projectIdToUse,
      projectValuation?.id,
      form,
    );
    pValuation && setProjectValuation(pValuation);
    return pValuation;
  };

  React.useEffect(() => {
    getProjectValuationCallAPI(projectIdToUse).then((res) => {
      res && setProjectValuation(res[0]);
      getProjectCostSummaryCallAPI(projectIdToUse);
    });
  }, [projectIdToUse]);

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const projectValuationLogConfiguration: ILogsConfiguration = {
    id: Number(projectValuation?.id),
    type: "projectvaluationconfiguration",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectValuationLogConfiguration.type,
      projectValuationLogConfiguration.id,
    );
  };

  const handleOpenUpdateCostModalOpen = () => {
    setUpdateProjectCostModalOpen(true);
    setProjectCostForm({
      fmv_step_up_input_type: costSummary?.data.fmv_step_up_input_type || "",
      profit_percent: costSummary?.data.fmv_step_up_percentage.toString() || "",
      fmv_step_up: costSummary?.data.fmv_step_up_amount.toString() || "",
    });
  };

  const handleCloseUpdateProjectCostModal = () => {
    setUpdateProjectCostModalOpen(false);
  };

  return (
    <ViewWrapper
      loading={getProjectValuationLoading}
      error={getProjectValuationFailed}
    >
      <React.Fragment>
        <Box className={cn("flex justify-between gap-4 mb-4 items-center")}>
          <ValuationDisclaimer classes={{ root: cn("max-w-fit") }} />
          <LogsButton onClick={handleOnOpenLogs} />
        </Box>

        <Box className={cn("grid md:grid-cols-2 gap-4")}>
          <DetailsCard
            heading="FMV Step Up"
            dataPw="project-fmv-stepup"
            onEditClick={handleOpenUpdateCostModalOpen}
            sections={[
              {
                fields: [
                  {
                    label: "Total Project Cost",
                    value: {
                      text: formatCurrency(
                        costSummary?.data.total_cost || 0,
                        2,
                      ),
                    },
                  },
                  {
                    label: "FMV Step Up Percent",
                    value: {
                      text:
                        costSummary?.data.fmv_step_up_percentage.toFixed(2) +
                        "%",
                    },
                  },
                  {
                    label: "FMV Step Up Amount",
                    value: {
                      text: formatCurrency(
                        costSummary?.data.fmv_step_up_amount || 0,
                        2,
                      ),
                    },
                  },
                  {
                    label: "FMV",
                    value: {
                      text: formatCurrency(costSummary?.data.fmv || 0, 2),
                    },
                  },
                ],
              },
            ]}
          />

          {!projectValuation ? (
            <Box className={styles.classes.emptyContainer}>
              <Box>
                <ConditionalProtect type="project">
                  <Button
                    canOpenUpgrade
                    startIcon={<AddIcon />}
                    label="Add Project Valuation"
                    onClick={handleOpenAddProjectValuationForm}
                    btnType="primary"
                    className={styles.classes.createBtn}
                  />
                </ConditionalProtect>
                <Typography
                  variant="body1"
                  className={styles.classes.createInfo}
                  data-pw="empty-project-valuation-summary-message"
                >
                  Project Valuation does not exist, click on Add Project
                  Valuation to add one.
                </Typography>
              </Box>
            </Box>
          ) : (
            <DetailsCard
              heading="Project Valuation Inputs"
              dataPw="project-valuation-inputs-card"
              height="max-content"
              onEditClick={handleOpenEditProjectValuationForm}
              autoHeight
              sections={[
                {
                  fields: [
                    {
                      label: "Contracted Discount Rate",
                      value: {
                        text:
                          (projectValuation.contracted_discount_rate ?? 0) +
                          "%",
                      },
                    },
                    {
                      label: "Merchant Discount Rate",
                      value: {
                        text:
                          (projectValuation.merchant_discount_rate ?? 0) + "%",
                      },
                    },
                    {
                      label: "Developer Profit",
                      value: {
                        text: (projectValuation.developer_profit ?? 0) + "%",
                      },
                    },
                    {
                      label: "ITC Eligible Basis Method",
                      value: {
                        text: PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS[
                          projectValuation.itc_eligible_basis_method
                        ],
                      },
                    },
                    {
                      label: "FMV Method",
                      value: {
                        text: PROJECT_VALUATION_FMV_METHODS[
                          projectValuation.fmv_method
                        ],
                      },
                    },
                  ],
                },
                {
                  heading: "Tax Credit Adders",
                  fields: [
                    {
                      label: "Domestic Content",
                      value: {
                        text: projectValuation.itc_adder_domestic_content
                          ? "Yes"
                          : "No",
                      },
                    },
                    {
                      label: "Energy Community",
                      value: {
                        text: projectValuation.itc_adder_energy_community
                          ? "Yes"
                          : "No",
                      },
                    },
                    {
                      label: "Environmental Justice (EJ)",
                      value: {
                        text: projectValuation.itc_adder_environmental_justice
                          ? "Yes"
                          : "No",
                      },
                    },
                    {
                      label: "Special Qualified EJ",
                      value: {
                        text: projectValuation.itc_adder_special_qualified_ej
                          ? "Yes"
                          : "No",
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </Box>
      </React.Fragment>

      <ProjectValuationFormModal
        form={projectValuationForm}
        setForm={setProjectValuationForm}
        headerLabel="Add Project Valuation"
        loading={addProjectValuationLoading}
        onClose={handleCloseAddProjectValuationForm}
        open={openProjectValuationForm}
        onConfirm={onAddProjectValuation}
        setFormErrors={setAddProjectValuationFormErrors}
        formErrors={addProjectValuationFormErrors}
      />
      <ProjectValuationFormModal
        form={projectValuationForm}
        setForm={setProjectValuationForm}
        headerLabel="Edit Project Valuation"
        loading={editProjectValuationLoading}
        onClose={handleCloseEditProjectValuationForm}
        open={openEditProjectValuationForm}
        onConfirm={onEditProjectValuation}
        setFormErrors={setEditProjectValuationFormErrors}
        formErrors={editProjectValuationFormErrors}
      />

      <ProjectCostFormModal
        headerLabel="Edit FMV Step Up"
        open={updateProjectCostModalOpen}
        loading={updateProjectCostLoading}
        formErrors={updateProjectCostFormErrors}
        setFormErrors={setUpdateProjectCostFormErrors}
        form={projectCostForm}
        setForm={setProjectCostForm}
        onClose={handleCloseUpdateProjectCostModal}
        onConfirm={handleUpdateProjectCost}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectValuationLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectValuationLogConfiguration.id}
        />
      </LogsWrapper>
    </ViewWrapper>
  );
}
