import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CustomChip from "../general/Chip";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import Layout from "../layout";
import ActionButton from "../action-button";
import DownloadModelFormModal from "../download-model-form-modal";
import ScenarioSensitivitySelector from "../scenario-sensitivity-selector";
import { getProjectValuation } from "../../apis/project/valuation";
import { getProjectCaseList } from "../../apis/project/case";
import { useAPI, useAppSelector, useLocalStorage } from "../../utils/hooks";
import {
  DEAL_STATUS,
  DEAL_STATUS_OPTIONS,
  PROEJECT_CASE_TYPE,
  PROJECT_PATHS_TO_HIDE_CASE_DROPDOWN_ON,
  PROJECT_TAX_CREDIT_TYPE,
  PERIODICITY,
} from "../../constants";
import {
  downloadProjectModel,
  getProjectDetails,
  updateProject,
} from "../../apis/project/base";
import {
  getStatusChipFillColor,
  navigateAndScroll,
  trimString,
} from "../../utils/helpers";
import {
  resetConfirmModalPropsAction,
  setConfirmModalPropsAction,
  setCurrentProjectAction,
  setCurrentProjectStatusAction,
  setProjectCasesAction,
  setValuationExistAction,
} from "../../utils/redux/slices";
import {
  IAddUpdateProjectResponse,
  IProjectForm,
  IProjectFormErrors,
} from "../../interfaces";

interface IProps {
  children: JSX.Element;
}

export default function PrimaryProjectPagesLayout({
  children,
}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { organization } = useOrganization();

  const { currentProject, projectCases } = useAppSelector((s) => s.project);
  const { contentLoaded } = useAppSelector((s) => s.common);

  const styles = useStyles({ contentLoaded });

  const navigate = useNavigate();
  const {
    projectUuid,
    caseProjectUuid,
    costItemId,
    creditSupportId,
    revenueId,
    expenseId,
    otherFlowId,
    depreciationId,
  } = useParams();

  const [downloadModelPeriodicity, setDownloadModelPeriodicity] =
    React.useState<keyof typeof PERIODICITY | "">("");
  const [downloadModelModalOpen, setDownloadModelModalOpen] =
    React.useState(false);
  const [selectedScenario, setSelectedScenario] = React.useState<string>("");
  const [selectedSensitivity, setSelectedSensitivity] =
    React.useState<string>();
  const [projectCaseData, setProjectCaseData] = useLocalStorage(
    String(projectUuid),
    "",
  );

  React.useEffect(() => {
    if (caseProjectUuid && !hideCaseSelector) {
      const projectCaseObj = projectCaseData ? JSON.parse(projectCaseData) : {};
      const typeOfCase = projectCaseObj[caseProjectUuid];

      if (typeOfCase === "SCEN") {
        setSelectedScenario(caseProjectUuid);
        setSelectedSensitivity(undefined);
      } else if (typeOfCase === "SENS") {
        const sensitivityCase = projectCases.find(
          (c) => c.child_project_uuid === caseProjectUuid,
        );
        const parentScenario = projectCases.find(
          (c) => c.uuid === sensitivityCase?.parent_scenario_uuid,
        );

        setSelectedScenario(parentScenario?.child_project_uuid ?? "");
        setSelectedSensitivity(caseProjectUuid);
      }
    }
  }, [caseProjectUuid, projectCases, projectCaseData]);

  const {
    callAPI: downloadProjectModelCallAPI,
    loading: downloadProjectModelLoading,
  } = useAPI((projectUuid: string, periodicity: keyof typeof PERIODICITY) =>
    downloadProjectModel(projectUuid, periodicity),
  );

  const { callAPI: getProjectCaseListCallAPI } = useAPI(getProjectCaseList);

  const {
    callAPI: getProjectDetailsCallAPI,
    loading: loadingProjectDetails,
    errored: getProjectDetailsFiled,
    response: project,
  } = useAPI((projectUuid) => getProjectDetails(projectUuid));

  const { callAPI: updateProjectCallAPI } = useAPI<
    IAddUpdateProjectResponse,
    IProjectFormErrors
  >((projectUuid: string, form: IProjectForm) =>
    updateProject(projectUuid, form),
  );

  const { callAPI: getProjectValuationCallAPI } = useAPI((projectUuid) =>
    getProjectValuation(projectUuid),
  );

  const hideCaseSelector = PROJECT_PATHS_TO_HIDE_CASE_DROPDOWN_ON.some((path) =>
    pathname.includes(path),
  );

  const projectIdToUse = React.useMemo(
    () => (hideCaseSelector ? projectUuid : caseProjectUuid),
    [hideCaseSelector, projectUuid, caseProjectUuid],
  );

  React.useEffect(() => {
    if (currentProject?.uuid !== projectIdToUse) {
      getProjectDetailsCallAPI(projectIdToUse).then((res) => {
        dispatch(setCurrentProjectAction(res));
      });
      getProjectValuationCallAPI(projectIdToUse).then((res) => {
        res && dispatch(setValuationExistAction(Boolean(res[0])));
      });
    }
    getProjectCaseListCallAPI(projectUuid).then((res) => {
      if (res?.data) {
        dispatch(setProjectCasesAction(res?.data));
        const obj = res.data.reduce(
          (acc, c) => {
            acc[c.child_project_uuid] = c.type;
            return acc;
          },
          {} as Record<string, string>,
        );
        setProjectCaseData(JSON.stringify(obj));
      }
    });
  }, [projectUuid, projectIdToUse]);

  React.useEffect(() => {
    dispatch(
      setCurrentProjectStatusAction({
        loading: loadingProjectDetails,
        errored: getProjectDetailsFiled,
      }),
    );
  }, [loadingProjectDetails, getProjectDetailsFiled]);

  React.useEffect(() => {
    // * Redirecting because the project belongs to a case of base-deal.
    if (
      (project?.deals || []).length > 0 &&
      project?.deals[0]?.parent_deal_uuid
    ) {
      const deal = project.deals[0];
      navigate(`/deal/${deal.parent_deal_uuid}/case/${deal.case_uuid}`);
    }
  }, [project]);

  const onDealChipClick = (uuid: string) => {
    window.open(`/deal/${uuid}/case-deal/${uuid}/general`, "_blank");
  };

  const handleNavigate = (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId);
  };

  const updateProjectStatus = async (status: string) => {
    const updatedProject = await updateProjectCallAPI(caseProjectUuid, {
      status,
    });

    if (currentProject && updatedProject) {
      dispatch(
        setCurrentProjectAction({
          ...currentProject,
          ...updatedProject,
        }),
      );
      dispatch(resetConfirmModalPropsAction());
    }
  };

  const handleConfirmProjectStatusUpdate = async (status: string) => {
    dispatch(
      setConfirmModalPropsAction({
        open: true,
        title: "Confirm Project Status Update",
        description: `Are you sure you want to update the status of this Project and make it 'Draft'?`,
        onConfirm: () => updateProjectStatus(status),
      }),
    );
  };

  const handleDownloadModelClick = () => {
    setDownloadModelPeriodicity("MO");
    setDownloadModelModalOpen(true);
  };

  const handleDownloadModelClose = () => {
    setDownloadModelModalOpen(false);
  };

  const handleDownloadModelConfirm = async (
    periodicity: keyof typeof PERIODICITY | "",
  ) => {
    await downloadProjectModelCallAPI(caseProjectUuid, periodicity);
  };

  const groupedOptions = projectCases
    .filter((c) => c.type === "SCEN")
    .map((scenario) => {
      const children = projectCases
        .filter(
          (c) => c.type === "SENS" && c.parent_scenario_uuid === scenario.uuid,
        )
        .map((sens) => ({
          label: sens.name,
          value: sens.child_project_uuid,
        }));

      return {
        label: scenario.name,
        value: scenario.child_project_uuid,
        children,
      };
    });

  const handleChangeCase = React.useCallback(
    (scenarioUuid: string, sensitivityUuid?: string) => {
      setSelectedScenario(scenarioUuid);
      setSelectedSensitivity(sensitivityUuid);
      const newCaseProjectUuid = sensitivityUuid || scenarioUuid;
      const parts = pathname.split("/");
      parts[4] = newCaseProjectUuid;
      const newPath = parts.join("/");
      navigate(newPath);
    },
    [pathname, navigate],
  );

  const currentProjectCaseType = React.useMemo(() => {
    const parsedData = projectCaseData ? JSON.parse(projectCaseData) : {};
    if (!caseProjectUuid) return "";
    return parsedData[caseProjectUuid] || "";
  }, [projectCaseData, caseProjectUuid]);

  const disableCaseSwitch = React.useMemo(() => {
    return [
      costItemId,
      creditSupportId,
      revenueId,
      expenseId,
      otherFlowId,
      depreciationId,
    ].some(Boolean);
  }, [
    costItemId,
    creditSupportId,
    revenueId,
    expenseId,
    otherFlowId,
    depreciationId,
  ]);

  const isOnCase = React.useMemo(() => {
    return projectUuid !== caseProjectUuid && !pathname.includes("/cases");
  }, [projectUuid, caseProjectUuid]);

  return (
    <>
      <Layout title={currentProject?.name}>
        <>
          {currentProject?.status ===
            DEAL_STATUS_OPTIONS.find((o) => o.value === "ARCH")?.value && (
            <Alert severity="warning">
              {" "}
              This Project has been archived.{" "}
              <span
                className="cursor-pointer font-semibold"
                onClick={() => handleConfirmProjectStatusUpdate("DRFT")}
              >
                Click here
              </span>{" "}
              to unarchive.{" "}
            </Alert>
          )}
          {currentProject && (
            <Box className={styles.classes.stickyHeader}>
              <Box className={styles.classes.infoSection}>
                {organization ? (
                  <Link
                    to="/project-list"
                    className={styles.classes.backButton}
                  >
                    <ArrowBackIcon />
                    <Typography>Back to Projects List</Typography>
                  </Link>
                ) : null}
                <Box className="flex items-center justify-start gap-2">
                  <Typography variant="h5" component="h5" marginRight={3}>
                    {trimString(currentProject?.name, 45)}
                  </Typography>
                  <CustomChip
                    className="!mb-1"
                    label={DEAL_STATUS[currentProject.status]}
                    color={getStatusChipFillColor(currentProject.status).color}
                    filledBackgroundColor={
                      getStatusChipFillColor(currentProject.status)
                        .backgroundColor
                    }
                    variant="filled"
                  />
                  {isOnCase && (
                    <Chip
                      label={
                        PROEJECT_CASE_TYPE[
                          currentProjectCaseType as keyof typeof PROEJECT_CASE_TYPE
                        ]
                      }
                    />
                  )}
                </Box>
                <Box className="flex space-between items-start mt-2">
                  <Box className="flex flex-wrap gap-2 max-w-[80%]">
                    {!hideCaseSelector && organization && (
                      <ScenarioSensitivitySelector
                        className="max-h-[32px] min-w-[256px]"
                        groupedOptions={groupedOptions}
                        selectedScenario={selectedScenario}
                        selectedSensitivity={selectedSensitivity}
                        onChange={handleChangeCase}
                        disabled={disableCaseSwitch}
                      />
                    )}
                    <Chip
                      label={trimString(currentProject?.sponsor_name, 40)}
                    />
                    <Chip label={currentProject?.capacity_ac + " MW (AC)"} />
                    <Chip label={currentProject?.capacity_dc + " MW (DC)"} />
                    <Chip label={"BOL: " + currentProject?.bol} />
                    <Chip label={"NTP: " + currentProject?.ntp} />
                    <Chip label={"COD: " + currentProject?.cod} />
                    <Chip
                      label={
                        "Tax Credit Type: " +
                        PROJECT_TAX_CREDIT_TYPE[currentProject?.tax_credit_type]
                      }
                    />
                    {organization ? (
                      (currentProject.deals || []).length > 4 ? (
                        <Chip
                          label="Deals"
                          variant="outlined"
                          onClick={() =>
                            handleNavigate(
                              `/project/${currentProject?.uuid}/case-project/${currentProject?.uuid}/general`,
                              "project-deals",
                            )
                          }
                        />
                      ) : (
                        (currentProject?.deals || []).map((d, idx) => (
                          <Chip
                            key={idx}
                            label={trimString(d.name, 40)}
                            variant="outlined"
                            icon={<OpenInNewIcon fontSize="small" />}
                            onClick={() => onDealChipClick(d.uuid)}
                          />
                        ))
                      )
                    ) : null}
                  </Box>
                  <Box className="ml-auto">
                    <ActionButton
                      size="medium"
                      onClick={handleDownloadModelClick}
                      actionType="edit"
                      classes={{
                        root: "whitespace-nowrap flex items-center gap-1 ml-auto",
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                      Download Model
                    </ActionButton>
                  </Box>
                </Box>
                <Divider className={styles.classes.divider} />
              </Box>
            </Box>
          )}
          <Box className={styles.classes.children}>{children}</Box>
        </>
      </Layout>

      <DownloadModelFormModal
        headerLabel="Download Model"
        open={downloadModelModalOpen}
        loading={downloadProjectModelLoading}
        periodicity={downloadModelPeriodicity}
        setPeriodicity={setDownloadModelPeriodicity}
        onClose={handleDownloadModelClose}
        onConfirm={handleDownloadModelConfirm}
      />
    </>
  );
}
