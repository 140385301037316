import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import MuiButton from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { isEmpty, isString, max } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridCsvExportMenuItem,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";

import useStyles from "./styles";
import Modal from "../../../components/modal";
import Tooltip from "../../../components/tooltip";
import UpdateDealComparisonFormModal from "../../../components/update-deal-comparison-form-modal";
import UpdateDealCaseComparisonFormModal from "../../../components/update-deal-case-comparison-form-modal";
import CheckboxInput from "../../../components/checkbox-input";
import ComparisonDetailPageLayout from "./layout";
import { setDeleteModalPropsAction } from "../../../utils/redux/slices";
import { getDealCasesLite } from "../../../apis/deal/case";
import { updateDealCaseComparison } from "../../../apis/deal/analysis";
import {
  useAPI,
  useLocalStorage,
  useCopyToClipboard,
} from "../../../utils/hooks";
import {
  cn,
  trimString,
  getDealSourcesAndUsedAtCodComparisonRows,
  getDealCashEquityComparisonRows,
  getDealComparisonRows,
  getDealCostComparisonRows,
  getDealCostsPerWattDcComparisonRows,
  getDealDebtComparisonRows,
  getDealDirectPayComparisonRows,
  getDealGeneralComparisonRows,
  getDealPartnershipSingleOwnerComparisonRows,
  getDealProformaComparisonRows,
  getDealSponsorEquityComparisonRows,
  getDealTaxCreditComparisonRows,
  getDealTaxEquityComparisonRows,
  getDealTimingComparisonRows,
  getDealTransferComparisonRows,
  getDealCreditSupportComparisonRows,
  getDealDepreciationComparisonRows,
  getDealSponsorEquityBOLComparisonRows,
  getDealConstructionDebtComparisonRows,
  getDealSourcesAndUsesAtConstructionComparisonRows,
} from "../../../utils/helpers";
import {
  IDeal,
  IGetDealsParams,
  ISelectOption,
  IUpdateDealCaseComparisonForm,
  IUpdateDealCaseComparisonFormErrors,
  ServerPaginatedResponse,
  IAddDealComparisonFormErrors,
  IDealComparison,
  IDealComparisonInputs,
  IDealComparisonOutputs,
  IUpdateDealComparisonForm,
  IDealCaseLite,
} from "../../../interfaces";
import {
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE,
  DEAL_STATUS,
} from "../../../constants";

interface IProps {
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]>>;
  getDealComparison: (uuid: string) => Promise<IDealComparison>;
  updateDealComparison: (
    uuid: string,
    form: IUpdateDealComparisonForm,
  ) => Promise<IDealComparison>;
  deleteDealComparison: (uuid: string) => Promise<boolean>;
  getDealAnalysisInputs: (
    dealComparisonUuid: string,
    dealUuid: string,
  ) => Promise<{ data: IDealComparisonInputs }>;
  getDealAnalysisOutputs: (
    dealComparisonUuid: string,
    dealUuid: string,
  ) => Promise<{ data: IDealComparisonOutputs }>;
  inPersonalAccount?: boolean;
  comparisonId?: string;
  type: "deal" | "case";
}

const DealComparisonDetail = ({
  getDeals,
  getDealComparison,
  updateDealComparison,
  deleteDealComparison,
  getDealAnalysisInputs,
  getDealAnalysisOutputs,
  comparisonId,
  inPersonalAccount,
  type,
}: IProps): JSX.Element => {
  const styles = useStyles({ reduceHeight: type === "deal" ? 205 : 295 });

  const { dealUuid, uuid } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showDifferences, setShowDifferences] = useLocalStorage<boolean>(
    `show-differences-${type}-${comparisonId}`,
    false,
  );
  const [highlightDifferences, setHighlightDifferences] =
    useLocalStorage<boolean>(
      `highlight-differences-${type}-${comparisonId}`,
      false,
    );

  const [collapseAll, setCollapseAll] = React.useState<boolean>(false);
  const [fullScreen, setFullScreen] = React.useState<boolean>(false);
  const [reportsLoaded, setReportsLoaded] = React.useState(false);
  const [dealAnalysisInput, setDealAnalysisInput] = React.useState<
    IDealComparisonInputs[]
  >([]);
  const [dealAnalysisOutput, setDealAnalysisOutput] = React.useState<
    IDealComparisonOutputs[]
  >([]);

  const [dealComparisonData, setDealComparisonData] =
    React.useState<IDealComparison>();
  const [form, setForm] = React.useState<IUpdateDealComparisonForm>(
    UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  );
  const [updateDealCaseComparisonForm, setUpdateDealCaseComparisonForm] =
    React.useState<IUpdateDealCaseComparisonForm>(
      UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE,
    );
  const [openEditFormModal, setOpenEditFormModal] =
    React.useState<boolean>(false);
  const [dealOptions, setDealOptions] = React.useState<{
    [key in DEAL_STATUS]: ISelectOption[];
  }>({
    Active: [],
    Draft: [],
    Archived: [],
  });
  const [dealCasesLite, setDealCasesLite] = React.useState<IDealCaseLite[]>([]);

  const {
    callAPI: getDealComparisonCallAPI,
    errored: getDealComparisonFailed,
    loading: loadingDealComparison,
  } = useAPI((uuid: string) => getDealComparison(uuid), {
    initialLoading: true,
  });

  const { callAPI: getDealsCallAPI } = useAPI(() => getDeals({}));

  const { callAPI: deleteDealComparisonCallAPI } = useAPI(
    (uuid: string) => deleteDealComparison(uuid),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateDealComparisonCallAPI,
    loading: loadingUpdateDealComparison,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealComparison, IAddDealComparisonFormErrors>(
    (uuid: string, form: IUpdateDealComparisonForm) => {
      const updatedForm = {
        name: form.name,
        deal_uuids: [form.benchmark_deal, ...form.deal_uuids],
      };
      return updateDealComparison(
        uuid,
        updatedForm as IUpdateDealComparisonForm,
      );
    },
    { initialLoading: false },
  );

  const { callAPI: getDealAnalysisInputsCallAPI } = useAPI(
    (dealComparisonUuid: string, dealUuid: string) =>
      getDealAnalysisInputs(dealComparisonUuid, dealUuid)
        .then((res) => res.data)
        .catch(() => null),
    { initialLoading: true },
  );

  const { callAPI: getDealAnalysisOutputsCallAPI } = useAPI(
    (dealComparisonUuid: string, dealUuid: string) =>
      getDealAnalysisOutputs(dealComparisonUuid, dealUuid)
        .then((res) => res.data)
        .catch(() => null),
    { initialLoading: true },
  );

  React.useEffect(() => {
    if (comparisonId) {
      getDealComparisonCallAPI(comparisonId).then((response) => {
        if (response) {
          setDealComparisonData(response);
          // don't allow deal analysis to open through case analysis ui
          if (type === "case" && response?.type !== "DC") {
            navigate(`/deal/${dealUuid}/analysis`);
          }
          // don't allow case analysis to open through deal analysis ui
          if (type === "deal") {
            if (inPersonalAccount && response?.type !== "ESC") {
              navigate("/personal/shared-deals");
            }
            if (!inPersonalAccount && response.type !== "DG") {
              navigate(`/analysis/deal-comparison-list`);
            }
          }
        }
      });
    }
    if (type === "case") {
      getDealCasesLiteCallAPI(String(dealUuid)).then((res) => {
        res && setDealCasesLite(res);
      });
    }
  }, [comparisonId]);

  React.useEffect(() => {
    if (
      comparisonId &&
      (dealComparisonData?.deals_to_compare?.length || 0) > 0
    ) {
      getDealAnalysisInputOutput();
    }
  }, [comparisonId, dealComparisonData]);

  const getDealAnalysisInputOutput = async () => {
    await Promise.all([
      getDealAnalysisInput(),
      getDealAnalysisOutput(),
    ]).finally(() => setReportsLoaded(true));
  };

  const getDealAnalysisInput = async () => {
    const promises = (dealComparisonData?.deals_to_compare || []).map(
      async (deal) => {
        try {
          const res = await getDealAnalysisInputsCallAPI(
            comparisonId,
            deal?.uuid,
          );

          const index =
            dealComparisonData?.deals_to_compare?.findIndex(
              (d) => d.uuid === deal?.uuid,
            ) || 0;

          setDealAnalysisInput((prev: IDealComparisonInputs[]) => {
            prev[index] = res as IDealComparisonInputs;
            return prev;
          });
        } catch (error) {
          console.error(`Error fetching input for deal ${deal?.uuid}:`, error);
        }
      },
    );

    // Wait for all promises to resolve
    await Promise.all(promises);
  };

  const getDealAnalysisOutput = async () => {
    const promises = (dealComparisonData?.deals_to_compare || []).map(
      async (deal) => {
        try {
          const res = await getDealAnalysisOutputsCallAPI(
            comparisonId,
            deal?.uuid,
          );

          const index =
            dealComparisonData?.deals_to_compare?.findIndex(
              (d) => d.uuid === deal?.uuid,
            ) || 0;

          setDealAnalysisOutput((prev: IDealComparisonOutputs[]) => {
            prev[index] = res as IDealComparisonOutputs;
            return prev;
          });
        } catch (error) {
          console.error(`Error fetching output for deal ${deal?.uuid}:`, error);
        }
      },
    );

    // Wait for all promises to resolve
    await Promise.all(promises);
  };

  const { callAPI: getDealCasesLiteCallAPI } = useAPI((dealUuid) =>
    getDealCasesLite(String(dealUuid)),
  );

  const {
    callAPI: updateDealCaseComparisonCallAPI,
    fieldErrors: updateDealCaseFormErrors,
    setFieldErrors: setUpdateDealCaseFormErrors,
    loading: loadingUpdateDealCaseComparison,
  } = useAPI<IDealComparison, IUpdateDealCaseComparisonFormErrors>(
    (uuid, form: IUpdateDealCaseComparisonForm) => {
      const updatedForm = {
        base_case_deal: form.base_case_deal,
        deal_uuids: [form.base_case_deal, ...form.deal_uuids],
        name: form.name,
        uuid: form.uuid,
      };
      return updateDealCaseComparison(uuid, updatedForm);
    },
  );

  const handleEditDealComparison = async () => {
    if (dealComparisonData) {
      const { name, benchmark_deal, deals_to_compare } = dealComparisonData;
      setForm({
        name,
        benchmark_deal: String(benchmark_deal?.uuid),
        deal_uuids: deals_to_compare
          .map((d) => d?.uuid)
          .filter((d) => d !== benchmark_deal?.uuid),
        uuid: dealComparisonData.uuid,
      });
      if (type === "case") {
        await getDealCasesLiteCallAPI(String(dealUuid)).then((res) => {
          res && setDealCasesLite(res);
        });
        setUpdateDealCaseComparisonForm({
          deal_uuids: deals_to_compare
            .map((d) => String(d?.uuid))
            .filter((d) => d !== String(benchmark_deal?.uuid)),
          base_case_deal: String(dealComparisonData?.benchmark_deal?.uuid),
          name: dealComparisonData?.name,
          uuid: dealComparisonData?.uuid,
        });
      }

      if (
        !dealOptions.Active.length ||
        !dealOptions.Draft.length ||
        !dealOptions.Archived.length
      ) {
        const deals = await getDealsCallAPI();

        if (deals && deals.results) {
          const categorizedDeals: {
            [key in DEAL_STATUS]: ISelectOption[];
          } = {
            Active: [],
            Draft: [],
            Archived: [],
          };

          deals?.results?.forEach((d: IDeal) => {
            categorizedDeals[DEAL_STATUS[d?.status]]?.push({
              label: d.name,
              value: d.uuid,
            });
          });

          setDealOptions(categorizedDeals);
        }
      }

      setOpenEditFormModal(true);
    }
  };

  const handleUpdate = async (form: IUpdateDealComparisonForm) => {
    const deal = await updateDealComparisonCallAPI(comparisonId, form);

    if (deal && dealComparisonData) {
      const { name, benchmark_deal, deals_to_compare } = deal;
      setDealComparisonData(() => ({
        ...dealComparisonData,
        name,
        benchmark_deal,
        deals_to_compare,
      }));
      window.location.reload(); // TODO refreshing page isn't ideal long term
    }

    return deal;
  };

  const handleUpdateDealCase = async (form: IUpdateDealCaseComparisonForm) => {
    const deal = await updateDealCaseComparisonCallAPI(comparisonId, form);
    if (deal && dealComparisonData) {
      const { name, benchmark_deal, deals_to_compare } = deal;
      setDealComparisonData(() => ({
        ...dealComparisonData,
        name,
        benchmark_deal,
        deals_to_compare,
      }));
      window.location.reload(); // TODO refreshing page isn't ideal long term
    }
    return deal;
  };

  const handleDeleteDealComparison = async (uuid: string) => {
    const deleted = await deleteDealComparisonCallAPI(uuid);

    if (deleted) {
      const redirectUrl =
        type === "deal"
          ? `/analysis/deal-comparison-list/`
          : `/deal/${dealUuid}/analysis/`;
      navigate(redirectUrl);
    }
  };

  const handleOnDelete = (uuid: string | undefined) => {
    if (uuid === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Deal Comparison",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteDealComparison(uuid),
      }),
    );
  };

  const dealIds: string[] = React.useMemo(() => {
    return (dealComparisonData?.deals_to_compare || []).map((d) => d.uuid);
  }, [dealComparisonData]);

  const columns: GridColDef[] = [
    ...dealIds.map((dId) => ({
      field: String(dId),
      headerName: dealComparisonData?.deals_to_compare?.find((d) => {
        return d?.uuid === dId;
      })?.name,
      width: 250,
      headerClassName: () => {
        if (dId === dealComparisonData?.benchmark_deal?.uuid) {
          return "dealHeading bgGray";
        }
        return "dealHeading";
      },
      cellClassName: () => {
        if (dId === dealComparisonData?.benchmark_deal?.uuid) {
          return "bgGray";
        }
        return "";
      },
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        let headerName = params?.colDef?.headerName;
        if (params?.colDef?.field == String(dealUuid)) {
          headerName = "Base Case";
        }
        return (
          <Tooltip title={headerName} placement="top-end">
            <div
              className={cn(
                "w-full flex items-center justify-between whitespace-normal break-words",
              )}
              onClick={() => onHeaderClick(params)}
            >
              <p></p>
              <strong>{trimString(headerName, 45)}</strong>
              <OpenInNewIcon />
            </div>
          </Tooltip>
        );
      },
      renderCell: (params: GridCellParams) => {
        if (params.row.hierarchy?.[0] === "General" && isString(params.value)) {
          const fullValue = params.value;
          if (fullValue.split(",").length > 5) {
            params.value = fullValue.split(",")[0] + ", >5";
          }

          return fullValue.length > 30 ? (
            <Tooltip title={fullValue} placement="top-end">
              <span>{params.value as string}</span>
            </Tooltip>
          ) : (
            <>{params.value}</>
          );
        }

        if (params?.id === "case_description") {
          return (
            <Tooltip title={params?.value as string} placement="top-end">
              <span>{trimString(params?.value as string, 30)}</span>
            </Tooltip>
          );
        }
        if (params?.id == "macrs_election" && isString(params?.value)) {
          const macrsElections = params?.value?.split(", ");
          return (
            <div>
              {macrsElections.map((macrsElection: string, idx: number) => (
                <div key={idx}>{macrsElection}</div>
              ))}
            </div>
          );
        }
        return <>{params.value}</>;
      },
    })),
  ];

  const hasDealStructureType = (
    type: keyof typeof DEAL_STRUCTURE_TYPE,
  ): boolean => {
    return dealAnalysisInput?.some((input) => {
      return input?.deal?.structure === type;
    });
  };

  const hasDealTaxCreditStructureType = (
    type: keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  ): boolean => {
    return dealAnalysisInput?.some((input) => {
      return input?.deal?.tax_credit_structure === type;
    });
  };

  const hasDealDebt = () => {
    return dealAnalysisInput?.some((input) => {
      return !isEmpty(input?.debt);
    });
  };

  const hasConstructionDebt = () => {
    return dealAnalysisOutput?.some((output) => {
      return !isEmpty(output?.construction_debt);
    });
  };

  const rows = [
    ...getDealComparisonRows(dealIds, dealAnalysisInput, showDifferences, type),
    ...getDealGeneralComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
      type,
    ),
    ...getDealTimingComparisonRows(dealIds, dealAnalysisInput, showDifferences),
    ...getDealCostComparisonRows(
      dealIds,
      dealAnalysisInput,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealCostsPerWattDcComparisonRows(
      dealIds,
      dealAnalysisInput,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealCreditSupportComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    // ...getDealFeesComparisonRows(dealIds, dealAnalysisInput, showDifferences),
    ...getDealProformaComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealDepreciationComparisonRows(
      dealIds,
      dealAnalysisInput,
      showDifferences,
    ),
    ...getDealTaxCreditComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealSourcesAndUsesAtConstructionComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealSourcesAndUsedAtCodComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealPartnershipSingleOwnerComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealSponsorEquityComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...getDealSponsorEquityBOLComparisonRows(
      dealIds,
      dealAnalysisOutput,
      showDifferences,
    ),
    ...(hasConstructionDebt()
      ? [
          ...getDealConstructionDebtComparisonRows(
            dealIds,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealDebt()
      ? [
          ...getDealDebtComparisonRows(
            dealIds,
            dealAnalysisInput,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealStructureType("TEP")
      ? [
          ...getDealTaxEquityComparisonRows(
            dealIds,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealStructureType("CEP")
      ? [
          ...getDealCashEquityComparisonRows(
            dealIds,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealTaxCreditStructureType("TR")
      ? [
          ...getDealTransferComparisonRows(
            dealIds,
            dealAnalysisInput,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
    ...(hasDealTaxCreditStructureType("DP")
      ? [
          ...getDealDirectPayComparisonRows(
            dealIds,
            dealAnalysisInput,
            dealAnalysisOutput,
            showDifferences,
          ),
        ]
      : []),
  ];

  const onHeaderClick = (params: { field: string }) => {
    const deal = dealComparisonData?.deals_to_compare?.find(
      (deal) => deal?.uuid === String(params?.field),
    );

    if (!deal) return;

    let url = "";

    if (type === "deal") {
      url = inPersonalAccount
        ? `/personal/deal/${deal.uuid}/output`
        : `/deal/${deal.uuid}/case-deal/${deal.uuid}/output`;
    }

    if (type === "case") {
      // if it is a case, navigate to the case details page, else redirect to sizing page
      if (
        typeof deal.parent_deal_uuid === "string" &&
        typeof deal.case_uuid === "string"
      ) {
        url = `/deal/${deal.parent_deal_uuid}/case/${deal.case_uuid}`;
      } else {
        url = `/deal/${deal.uuid}/case-deal/${deal.uuid}/sizing`;
      }
    }

    window.open(url);
  };

  const getRowClassName = (params: GridRowClassNameParams) => {
    return Object.keys(params?.row).length > 1 ? "" : "underlinedSection";
  };

  const dealCasesOptions: ISelectOption[] = dealCasesLite.map((d) => {
    return {
      label: d.name,
      value: d.child_deal_uuid,
    };
  });

  const pinnedColumns = React.useMemo(() => {
    return {
      left: [
        "__tree_data_group__",
        "property",
        String(dealComparisonData?.benchmark_deal?.uuid),
      ],
    };
  }, [dealComparisonData]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === "show") {
      setShowDifferences(checked);
      setHighlightDifferences(false);
      return;
    }
    setHighlightDifferences(checked);
    setShowDifferences(false);
  };

  const checkIfDealIsLoaded = (index: number): boolean => {
    return Boolean(dealAnalysisInput[index] && dealAnalysisOutput[index]);
  };

  const getIndexOfDealById = (id: string): number => {
    return (
      dealComparisonData?.deals_to_compare.findIndex((d) => d.uuid === id) ?? -1
    );
  };

  const getDataGridCellClassName = (params: GridCellParams) => {
    if (!dealComparisonData?.benchmark_deal?.uuid) return "";

    const baseDealValue = params.row[dealComparisonData.benchmark_deal.uuid];
    const indexOfCurrentColumn = getIndexOfDealById(String(params.field));
    const isHighlightConditionMet =
      highlightDifferences &&
      params.field !== "__tree_data_group__" &&
      params.formattedValue !== baseDealValue &&
      checkIfDealIsLoaded(0) &&
      checkIfDealIsLoaded(indexOfCurrentColumn);

    return isHighlightConditionMet ? "datagridCell-highlight" : "";
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
    row.hierarchy;

  const renderCustomToolbar = (options?: { withName: boolean }) => {
    return (
      <GridToolbarContainer>
        <div className={cn("flex justify-between w-full align-middle")}>
          {options?.withName && (
            <p className={cn("text-2xl text-nowrap content-center ml-2")}>
              {trimString(dealComparisonData?.name, 45)}
            </p>
          )}
          <div className={cn("flex w-full justify-between items-center gap-2")}>
            <div>
              <CheckboxInput
                label="Collapse All"
                checked={collapseAll}
                onChange={(e) => setCollapseAll(e.target.checked)}
                formControlLabelClasses={{ root: cn("!m-0") }}
              />
              <CheckboxInput
                label="Show Differences"
                name="show"
                checked={showDifferences}
                onChange={handleCheckboxChange}
                formControlLabelClasses={{ root: cn("!m-0") }}
              />
              <CheckboxInput
                label="Highlight Differences"
                name="highlight"
                checked={highlightDifferences}
                onChange={handleCheckboxChange}
                formControlLabelClasses={{ root: cn("!m-0") }}
              />
            </div>
            <div>
              <IconButton onClick={() => setFullScreen((f) => !f)}>
                {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              <IconButton onClick={handleCopy} disabled={!reportsLoaded}>
                <CopyIcon fontSize="small" />
              </IconButton>
              <MuiButton
                classes={{
                  root: styles.classes.downloadBtn,
                  disabled: styles.classes.disabledDownloadBtn,
                }}
                startIcon={<DownloadIcon />}
                disabled={!reportsLoaded}
              >
                <GridCsvExportMenuItem />
              </MuiButton>
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

  const { copyToClipboard } = useCopyToClipboard();

  const handleCopy = () => {
    if (!dealComparisonData || !rows || !columns) return;

    // Create header row with deal names
    const headers = [
      "Name",
      ...dealComparisonData.deals_to_compare.map((deal) => deal.name),
    ];

    // Create data rows
    const dataRows = rows
      .map((row) => {
        const values = [
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          (row as Record<string, any>).property || row.hierarchy?.[1], // Name column
          ...dealComparisonData.deals_to_compare.map((deal) => {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const value = (row as Record<string, any>)[deal.uuid];
            return value !== undefined ? String(value) : "";
          }),
        ];
        return values;
      })
      .filter(Boolean); // Remove null rows

    // Combine headers and data into CSV string
    const csvContent = [
      headers.join(`\t`),
      ...dataRows.map((row) => row?.join(`\t`)),
    ].join("\n");

    copyToClipboard(csvContent);
  };

  const disableGridVirtualization = React.useMemo(() => {
    return (
      process.env.REACT_APP_NODE_ENV === "dev" ||
      process.env.REACT_APP_NODE_ENV === "uat"
    );
  }, [process.env.REACT_APP_NODE_ENV]);

  const gridRowHeight = (params: GridRowHeightParams) => {
    if (params.model.id === "macrs_election") {
      const macrsLength = dealAnalysisInput.map(
        (d) => Object.keys(d?.depreciation?.macrs_election ?? {})?.length,
      );
      const maxLine = max(macrsLength) || 1;
      return maxLine === 1 ? 36 : maxLine * 25;
    }
    return undefined;
  };

  const renderDataGrid = (options?: { withName: boolean }) => {
    return (
      <DataGridPro
        disableVirtualization={disableGridVirtualization}
        classes={{
          columnHeaderTitle: cn("font-bold"),
          cell: cn("!justify-end"),
        }}
        density="compact"
        treeData
        rows={rows}
        columns={columns}
        rowBuffer={100} // Prevent flickering
        loading={
          dealAnalysisInput?.length === 0 && dealAnalysisOutput?.length === 0
        }
        getRowClassName={getRowClassName}
        getRowHeight={gridRowHeight}
        pinnedRows={{
          top: type === "deal" ? undefined : rows?.slice(0, 3),
        }}
        initialState={{
          pinnedColumns: pinnedColumns,
          pagination: {
            paginationModel: {
              pageSize: 200,
            },
          },
        }}
        groupingColDef={{
          headerName: "Name",
          minWidth: 350,
          hideDescendantCount: true,
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          valueGetter: (params: any) => {
            if (params?.rowNode?.type === "pinnedRow") {
              return params?.row?.property;
            }
            return params?.rowNode?.groupingKey;
          },
        }}
        getTreeDataPath={getTreeDataPath}
        isGroupExpandedByDefault={() => !collapseAll}
        slots={{ toolbar: () => renderCustomToolbar(options) }}
        hideFooter
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        getCellClassName={getDataGridCellClassName}
      />
    );
  };

  return (
    <>
      <ComparisonDetailPageLayout
        inPersonalAccount={inPersonalAccount}
        type={type}
        loading={loadingDealComparison}
        errored={getDealComparisonFailed}
        comparison={dealComparisonData}
        onDeleteClick={handleOnDelete}
        onEditClick={handleEditDealComparison}
      >
        <div className={styles.classes.datagrid}>{renderDataGrid()}</div>
      </ComparisonDetailPageLayout>

      <Modal
        fullScreen
        hideActionButtons
        noContentPadding
        open={fullScreen}
        onClose={() => setFullScreen(false)}
      >
        <div
          className={[styles.classes.datagrid, cn("!w-full !h-full")].join(" ")}
        >
          {renderDataGrid({ withName: true })}
        </div>
      </Modal>

      <UpdateDealComparisonFormModal
        headerLabel="Edit Deal Comparison"
        open={openEditFormModal && type === "deal"}
        loading={loadingUpdateDealComparison}
        form={form}
        setForm={setForm}
        onClose={() => setOpenEditFormModal(false)}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        existingDeals={dealOptions}
        onConfirm={handleUpdate}
      />

      <UpdateDealCaseComparisonFormModal
        headerLabel="Edit Deal Case Comparison"
        open={openEditFormModal && type === "case"}
        loading={loadingUpdateDealCaseComparison}
        form={updateDealCaseComparisonForm}
        formErrors={updateDealCaseFormErrors}
        setFormErrors={setUpdateDealCaseFormErrors}
        setForm={setUpdateDealCaseComparisonForm}
        onClose={() => setOpenEditFormModal(false)}
        onConfirm={handleUpdateDealCase}
        cases={dealCasesOptions}
      />
    </>
  );
};

export default React.memo(DealComparisonDetail);
