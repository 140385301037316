import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DataGridPro,
  GridTreeNode,
  GridCellParams,
  DataGridProProps,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";

import IconButton from "../icon-button";
import ConditionalProtect from "../conditional-protect";
import EditOutlinedIcon from "../logos-and-icons/edit-icon";
import ThemeContext from "../../utils/context/theme-context";
import { ISummaryTable } from "../../interfaces";
import { cn, transformToSummaryTable } from "../../utils/helpers";
import { externalDarkTheme, externalLightTheme } from "../../utils/theme";

interface IProps {
  summaryTable?: ISummaryTable;
  groupExpandedDepth?: number;
  usedIn: "project" | "deal";
  onEditClick?: () => void;
  onDownloadClick?: () => Promise<void>;
  rootClasses?: string;
}

const SummaryTable = ({
  summaryTable,
  groupExpandedDepth = 0,
  usedIn,
  onEditClick,
  onDownloadClick,
  rootClasses,
}: IProps): JSX.Element | null => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!summaryTable) {
    return null;
  }

  const { darkMode } = React.useContext(ThemeContext);

  const { columns, rows, hideHeader } = transformToSummaryTable(summaryTable);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => {
    return row.hierarchy;
  };

  const selectedTheme = React.useMemo(
    () => (darkMode ? externalDarkTheme : externalLightTheme),
    [darkMode],
  );

  const renderCustomToolbar = () => {
    if (!summaryTable.title && !onEditClick && !onDownloadClick) {
      return null;
    }

    return (
      <GridToolbarContainer>
        <Box className={cn("flex p-2 size-full justify-between")}>
          <Typography fontSize="14" fontWeight="bold">
            {summaryTable.title}
          </Typography>
          <Box>
            <ConditionalProtect type={usedIn}>
              {onEditClick && (
                <IconButton onClick={onEditClick}>
                  <EditOutlinedIcon fillColor="#5F51F4" />
                </IconButton>
              )}
              {onDownloadClick && (
                <IconButton
                  className={cn("!ml-3 !mb-[-2px]")}
                  onClick={onDownloadClick}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              )}
            </ConditionalProtect>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  };

  const onCellClick = (
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    cell: GridCellParams<any, unknown, unknown, GridTreeNode>,
  ) => {
    const linkKey = cell.field + cell.formattedValue + "hyperLink";

    if (cell.row[linkKey]) {
      let linkToUse = "";
      const pathArraySplitValue = 5;

      const splitURL = location.pathname
        .split("/")
        .slice(0, pathArraySplitValue);
      splitURL.push(cell.row[linkKey]);
      linkToUse = splitURL.join("/");

      navigate(linkToUse);
    }
  };

  const tableHasNestedRows = React.useMemo(
    () => rows.some((r) => r.hierarchy.length > 1),
    [rows],
  );

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      density="compact"
      getTreeDataPath={getTreeDataPath}
      classes={{
        root: cn(`bg-${selectedTheme.color.background} ${rootClasses}`),
        columnHeaderTitle: cn(
          `!font-bold text-[15px] ${tableHasNestedRows ? "!ml-9" : ""}`,
        ),
        treeDataGroupingCellToggle: !tableHasNestedRows
          ? cn("!mr-1 !flex-none")
          : cn("!mr-2"),
      }}
      slots={{ toolbar: renderCustomToolbar }}
      columnHeaderHeight={hideHeader ? 0 : 70}
      rowHeight={60}
      getCellClassName={(params) => {
        const boldKey = params.field + params.value + "bold";
        const firstColKey =
          summaryTable.headers[0]?.value + params.value + "bold";
        const linkKey = params.field + params.value + "hyperLink";

        let classes = "";
        if (params.row[linkKey]) {
          classes += "cursor-pointer hover:underline";
        }
        if (params.row[boldKey] || params.row[firstColKey]) {
          classes += "font-bold";
        }
        return classes;
      }}
      groupingColDef={{
        headerName: summaryTable.headers[0]?.value,
        minWidth: 350,
        hideDescendantCount: true,
      }}
      isGroupExpandedByDefault={(group) => {
        return group.depth === groupExpandedDepth;
      }}
      onCellClick={onCellClick}
      treeData
      hideFooter
      disableAutosize
      disableColumnMenu
      disableColumnResize
      disableColumnReorder
    />
  );
};

export default React.memo(SummaryTable);
