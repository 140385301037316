import DealPagesLayout from "../../../components/deal-pages-layout";
import DealCaseDetailsView from "../../../views/deal/cases/details/index";
import { getDealCaseDetails } from "../../../apis/deal/case";

export default function CaseDetailsPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealCaseDetailsView getDealCaseDetails={getDealCaseDetails} />
    </DealPagesLayout>
  );
}
